import React, { useState } from 'react';
import TextEditor from '../../../components/common/TextEditor'; // Adjust the import path as necessary

const TermAndConditions = () => {
  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.example.com/submit-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: editorContent }),
      });
      if (response.ok) {
        console.log('Data submitted successfully');
        setEditorContent('');
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container mx-auto mt-4">
      <div className="border  border-slate-900  p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Term And Conditions</h2>
        <form onSubmit={handleSubmit}>
          <TextEditor value={editorContent} onChange={handleEditorChange} />
          <button
            type="submit"
            className="mt-6 bg-indigo-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default TermAndConditions;
