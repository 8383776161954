import React, { useState } from 'react'
import DetailSummary from '../../../../../../common/DetailSummary';

const PurchasepoDetails = ({maindata}) => {
    const data=maindata;    
    const poDetails=[
        {head:"PO Code", result:data.code}, 
        {head:"Mother DocNo", result:data.poDetail.poDetails.motherDOCNo},
        {head:"PO Revision No", result:data.poDetail.poDetails.poRevNo},
        {head:"PO Date", result:data.poDetail.poDetails.poDate},
        {head:"EA Qty", result:data.poDetail.poDetails.eaQty},
        {head:"PO Amount", result:data.poDetail.poDetails.poAmount},
        {head:"PO Tax", result:data.poDetail.poDetails.poTax},
        {head:"PO Discount", result:data.poDetail.poDetails.poDiscount},
        {head:"Landed Cost", result:data.poDetail.poDetails.landedCost},
        {head:"Base Amount", result:data.poDetail.poDetails.baseAmount},
        {head:"Base Tax Amount", result:data.poDetail.poDetails.baseTaxAmount},
        {head:"Additional Charge", result:data.poDetail.poDetails.addCharge},
        {head:"PO Currency", result:data.poDetail.poDetails.poCurrency},
        {head:"Base Currency", result:data.poDetail.poDetails.baseCurrency},
        {head:"Conversion Ratio", result:data.poDetail.poDetails.conversionRatio},
      ];
      const venDetails=[
        {head:"Vendor Name", result:data.poDetail.venDetails.vendorName}, 
        {head:"Vendor Currency", result:data.poDetail.venDetails.venCurrency},
        {head:"Credit Days", result:data.poDetail.venDetails.creditDays},
      ];
      const othDetails=[
        {head:"Other Details", result:data.poDetail.otherDetails.otherDetail}, 
      ];
    
      const detailTab=[{"TabName":"PO Details","state":true,"data":poDetails},
        {"TabName":"Vendor Details","state":true,"data":venDetails},
        {"TabName":"Other Details","state":false,"data":othDetails}
      ];

  return (
    <>    
    <DetailSummary detailTab={detailTab} />
    </>
  )
}

export default PurchasepoDetails