import React, { useState } from 'react';

const PricingDetails = () => {
  const [formData, setFormData] = useState({
    msrp: '',
    salePrice: '',
    cost: '',
    stdMarginPercent: '',
    taxCategory: '',
    skuLevelTax: '',
    additionalCess: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Pricing Detail Data:', formData);
    // Handle form submission here
  };

  return (
    <form onSubmit={handleSubmit} className="p-6">
      <div className="grid grid-cols-1 font-medium text-xs md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* MSRP/MRP */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-1">MSRP/MRP</label>
          <input
            type="text"
            name="msrp"
            value={formData.msrp}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Sale Price */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-1">Sale Price</label>
          <input
            type="text"
            name="salePrice"
            value={formData.salePrice}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Cost */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-1">Cost</label>
          <input
            type="text"
            name="cost"
            value={formData.cost}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Std Margin % */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-1">Std Margin %</label>
          <input
            type="text"
            name="stdMarginPercent"
            value={formData.stdMarginPercent}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Tax Category */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-1">Tax Category (HSN/SAC)</label>
          <input
            type="text"
            name="taxCategory"
            value={formData.taxCategory}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* SKU Level Tax */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-1">SKU Level Tax</label>
          <select
            name="skuLevelTax"
            value={formData.skuLevelTax}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options for SKU Level Tax */}
          </select>
        </div>

        {/* Additional Cess */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-1">Additional Cess</label>
          <input
            type="text"
            name="additionalCess"
            value={formData.additionalCess}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>
      </div>

      <div className="flex justify-center mt-6">
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default PricingDetails;
