import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineUpload } from 'react-icons/ai';

const Dropzone = ({ index, onDrop, image }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        alert("You can upload only one image at a time.");
        return;
      }
      onDrop(index, acceptedFiles[0]);
    },
    accept: 'image/*',
    multiple: false,
  });

  return (
    <div
      {...getRootProps()}
      className="flex flex-col items-center justify-center p-4 border border-dashed rounded-lg cursor-pointer bg-white hover:bg-gray-100 transition duration-150 ease-in-out shadow-md"
      style={{ height: '200px', width: '200px' }}
    >
      <input {...getInputProps()} />
      {image ? (
        <img
          src={image}
          alt={`Uploaded ${index}`}
          className="object-cover w-full h-full rounded-lg"
        />
      ) : (
        <>
          <AiOutlineUpload className="text-blue-500 text-3xl mb-2" />
          <span className="text-gray-700 text-sm text-center">
            Drag & Drop an image here or click to upload
          </span>
        </>
      )}
    </div>
  );
};

const ImageCarousel = () => {
  const [images, setImages] = useState(Array(6).fill(null));

  const handleImageUpload = (index, file) => {
    const imageUrl = URL.createObjectURL(file);
    const newImages = [...images];
    newImages[index] = imageUrl;
    setImages(newImages);
  };

  return (
    <div className="p-6 min-h-screen flex flex-col items-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full max-w-screen-lg">
        {images.map((image, index) => (
          <Dropzone key={index} index={index} onDrop={handleImageUpload} image={image} />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
