import React from 'react';

const OtherSettings = () => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`${name}: ${value}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted!");
  };

  return (
    <div className="container mx-auto mt-6 p-6 border border-gray-800 rounded-lg">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-1 text-xs">
          <div>
            <label className="block text-gray-700 font-medium mb-2">Invoice Report</label>
            <select
              name="invoiceReport"
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">--- Select ---</option>
              {/* Add options here */}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Shelf Life on Picking Type</label>
            <select
              name="shelfLifeOnPickingType"
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">--- Select ---</option>
              {/* Add options here */}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Total Shelf Life</label>
            <input
              type="text"
              name="totalShelfLife"
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Shelf Life on Picking</label>
            <input
              type="text"
              name="shelfLifeOnPicking"
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default OtherSettings;
