import VendorMaster from '../components/Tabs/Vendor Master/Home';
import Address from '../components/Tabs/Vendor Master/Address';
import UserDefinedFields from '../components/Tabs/Vendor Master/UserDefinedFields';
import AttachedDocument from '../components/Tabs/Vendor Master/AttachedDocument';
import TermsAndConditions from '../components/Tabs/Vendor Master/TermAndConditions';
import SellerDetails from '../components/Tabs/Vendor Master/SellerDetails';
import CustomerDetails from '../components/Tabs/Customer Master/CustomerDetails';
import Addresses from '../components/Tabs/Customer Master/Addresses';
import OtherShippingAddress from '../components/Tabs/Customer Master/OtherShippingAddress';
import CustomerUserDefinedFields from '../components/Tabs/Customer Master/UserDefinedFields';
import TransporterDetails from '../components/Tabs/Transporter Master/TransporterDetails';
import ClientMaster from '../components/Tabs/Client Master/ClientMaster';
import TransporterUserDefinedFields from '../components/Tabs/Transporter Master/UserDefinedFields';
import ClientUserDefinedFields from '../components/Tabs/Client Master/UserDefinedFields';
import OtherSettings from '../components/Tabs/Customer Master/OtherSettings';
import BundleArticle from '../components/Tabs/Bundle Article/BundleArticle';
// import MainOutbondTab from '../components/core/Dashboard/Master/Order Enquiery/OutBondTabs/MainOutbondTab';
import LinkSearchTab from '../components/core/Dashboard/Master/SKUL Chanel Listing/Link Search Channel/LinkSearchTab';
import ChannelLinkImpTab from '../components/core/Dashboard/Master/SKUL Chanel Listing/Channel Link Import/ChannelLinkImpTab';
import UnmappedSKUTab from '../components/core/Dashboard/Master/SKUL Chanel Listing/Unmapped SKU/UnmappedSKUTab';
import OrderAllTable from '../components/core/Dashboard/Master/Order Enquiery/AllOrderEnquirey/MainTab/OrderAllTable';
import CreateOrderEdit from '../components/core/Dashboard/Master/Order Enquiery/AllOrderEnquirey/SubOrderTabs/OrderDetailTab/AllSubTab';
import AddressSubTab from '../components/core/Dashboard/Master/Order Enquiery/AllOrderEnquirey/SubOrderTabs/Address/AddressSubTab';
import FailedOrderTab from '../components/core/Dashboard/Master/Order Enquiery/FailedOrder/FailedOrderTab';
import CancelledPickOrderTab from '../components/core/Dashboard/Master/Order Enquiery/CancelledPickedOrder/CancelledPickOrderTab';
import FailedShipmentTab from '../components/core/Dashboard/Master/Order Enquiery/FailedShipmentorder/FailedShipmentTab';
import OutBoundDelivery from '../components/core/Dashboard/Master/Order Enquiery/AllOrderEnquirey/SubOrderTabs/OutBoundDelivery/OutBoundDelivery';
import ActivityAndRemark from '../components/core/Dashboard/Master/Order Enquiery/AllOrderEnquirey/SubOrderTabs/ActivityAndRemark/ActivityAndRemark';
import PaymentDetails from '../components/core/Dashboard/Master/Order Enquiery/AllOrderEnquirey/SubOrderTabs/PaymentDetails/PaymentDetails';
import PurchaseOrderTab from '../components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Purchase Order Tab/PurchaseOrderTab';
import AdditionalChargeTab from '../components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Additional Charge Tab/AdditionalChargeTab';
import CommentHistoryTab from '../components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Comment History Tab/CommentHistoryTab';
import PurchaseOrderTags from '../components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Purchase Order Tags/PurchaseOrderTags';
import PurchaseOrderInbound from '../components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Purchase Order Inbound/PurchaseOrderIndound';
import purchaseOrdImp from '../components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Purchase Order Import Tab/PuchaseOrdImp';
import PurchaseOrdImp from '../components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Purchase Order Import Tab/PuchaseOrdImp';
import LocationCreate from '../components/core/Dashboard/Master/Location Enquiry/Add Edit/LocationCreate';
import LocationContact from '../components/core/Dashboard/Master/Location Enquiry/Add Edit/LocationContact';


export const VendortabsConfig = [
  {
    id: 1, // Unique numeric ID
    name: 'Vendor Master',
    component: VendorMaster,
  },
  {
    id: 2,
    name: 'Address',
    component: Address,
  },
  {
    id: 3,
    name: 'User Defined Fields',
    component: UserDefinedFields,
  },
  {
    id: 4,
    name: 'Attached Document',
    component: AttachedDocument,
  },
  {
    id: 5,
    name: 'Terms and Conditions',
    component: TermsAndConditions,
  },
  {
    id: 6,
    name: 'Seller Details',
    component: SellerDetails,
  },
];


export const CustomertabsConfig = [
    {
      id: 1, // Unique numeric ID
      name: 'Customer Details',
      component: CustomerDetails,
    },
    {
      id: 2,
      name: 'Addresses',
      component: Addresses,
    },
    {
      id: 3,
      name: 'User Defined Fields',
      component: CustomerUserDefinedFields,
    },
    {
      id: 4,
      name: 'Other Shipping Addresses',
      component: OtherShippingAddress,
    },
    {
      id: 5,
      name: 'Other Settings',
      component: OtherSettings,
    },
  ];

  export const TransportertabsConfig = [
    {
      id: 1, // Unique numeric ID
      name: 'Transporter Details',
      component: TransporterDetails,
    },
    {
      id: 2,
      name: 'User Defined Fields',
      component: TransporterUserDefinedFields,
    },
  ];

  export const ClienttabsConfig = [
    {
      id: 1, // Unique numeric ID
      name: 'Client Master',
      component: ClientMaster,
    },
    {
      id: 2,
      name: 'User Defined Fields',
      component: ClientUserDefinedFields,
    },
  ];  

  export const BomtabsConfig = [
    {
      id: 1, // Unique numeric ID
      name: 'SKU Details',
    },
    {
      id: 2,
      name: 'Other Fields',
    },
    {
      id: 3,
      name: 'Bundle Article',
      component: BundleArticle,
    },
  ];  

  export const NormaltabsConfig = [
    {
      id: 1, // Unique numeric ID
      name: 'SKU Details',
    },
    {
      id: 2,
      name: 'Other Fields',
    },
  ];  
  export const OrdertabsConfig = [
    {
      id: 1, // Unique numeric ID
      name: "All",
      component: OrderAllTable,
    },
    {
      id: 2, // Unique numeric ID
      name: "Failed Orders",
      component: FailedOrderTab,
    },
    {
      id: 3, // Unique numeric ID
      name: "Cancelled Picked Orders",
      component: CancelledPickOrderTab,
    },
    {
      id: 4, // Unique numeric ID
      name: "Failed Shipments",
      component: FailedShipmentTab,
    }
  ];

  export const SubOrderEdit = [
      {
        id: 1, // Unique numeric ID
        name: "Order Detail",
        component: CreateOrderEdit,
      },
      {
        id: 2, // Unique numeric ID
        name: "Address",
        component: AddressSubTab,
      },
      {
        id: 3, // Unique numeric ID
        name: "Outbound Delivery",
        component: OutBoundDelivery,
      },
      {
        id: 5, // Unique numeric ID
        name: "Activity & Remarks",
        component: ActivityAndRemark,
      },
      {
        id: 6, // Unique numeric ID
        name: "Payment Details",
        component: PaymentDetails,
      },
      {
        id: 7, // Unique numeric ID
        name: "Order Tags",
        component: FailedShipmentTab,
      }
    ];

    export const ChannelListingtabsConfig = [
      {
        id: 1, // Unique numeric ID
        name: "SKU/Link Search Channel",
        component: LinkSearchTab,
      },
      {
        id: 2, // Unique numeric ID
        name: "SKU/Channel Link Import",
        component: ChannelLinkImpTab,
      },
      {
        id: 3, // Unique numeric ID
        name: "Unmapped SKU",
        component: UnmappedSKUTab,
      }
    ];


    export const PurchaseOrderTabsConfig = [
      {
        id: 1, // Unique numeric ID
        name: "Purchase Order",
        component: PurchaseOrderTab,
      },
      {
        id: 2, // Unique numeric ID
        name: "Additional Charges",
        component: AdditionalChargeTab,
      },
      {
        id: 3, // Unique numeric ID
        name: "Comment History",
        component: CommentHistoryTab,
      },
      {
        id: 4, // Unique numeric ID
        name: "PO Tags",
        component: PurchaseOrderTags,
      },
      {
        id: 5, // Unique numeric ID
        name: "PO Inbound",
        component: PurchaseOrderInbound,
      },
      {
        id: 6, // Unique numeric ID
        name: "Import",
        component: PurchaseOrdImp,
      },
      {
        id: 7, // Unique numeric ID
        name: "Attached Document",
        component: AttachedDocument,
      }

    ]
  export const LocationtabsConfig = [
    {
      id: 1, // Unique numeric ID
      name: 'Location Create',
      component: LocationCreate,
    },
    {
      id: 2,
      name: 'Location Contact',
      component: LocationContact,
    },
  ];
