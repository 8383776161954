import { createSlice } from "@reduxjs/toolkit";

const mainChannelsData={
    "channels":[
    {"id":1,"chanelCode":"M08","link":"","ChName":"Tata 1 MG","status":"Active","RegDate":"18/07/2022","ChConfig":"Yes"},
    {"id":2,"chanelCode":"M08","link":"","ChName":"Tata 1 MG","status":"Active","RegDate":"18/07/2022","ChConfig":"Yes"},
    {"id":3,"chanelCode":"M08","link":"","ChName":"Tata 1 MG","status":"Active","RegDate":"18/07/2022","ChConfig":"Yes"},
    {"id":4,"chanelCode":"M08","link":"","ChName":"Tata 2 MG","status":"Active","RegDate":"18/07/2022","ChConfig":"Yes"},
    {"id":5,"chanelCode":"T08","link":"","ChName":"Tata 1 MG","status":"Active","RegDate":"18/07/2022","ChConfig":"Yes"},    
],
    "failedOrderData":[
        {"orderNo":123,"channelOrdId":"yuh","channel":"No","createDate":"hggyww","remarks":"ok","issueDes":"no"},
        {"orderNo":345,"channelOrdId":"ooiui","channel":"Yes","createDate":"iuyr","remarks":"too","issueDes":"yes"}
    ],
    "cancelOrderData":[
        {"channel":"w433we","OrderNo":"","extOrdNo":"","pickList":"","skuCode":"","unallocationD":"","bigEnquiry":"","previousSatus":"","sourceWH":"","frmLPN":"","toLPN":"","pigeonholeID":""},
        {"channel":"","OrderNo":"","extOrdNo":"","pickList":"","skuCode":"","unallocationD":"","bigEnquiry":"","previousSatus":"","sourceWH":"","frmLPN":"","toLPN":"","pigeonholeID":""},
    ],
    "FailedShipmentData":[
        {"type":"gouh","channel":"","status":"","extOrdNo":"","orderNo":"","deliveryNo":"","packDate":"","updatedDate":"","errorDesc":""},
        {"type":"","channel":"","status":"","extOrdNo":"","orderNo":"","deliveryNo":"","packDate":"","updatedDate":"","errorDesc":""}
    ]
}

const initialState = {
  data: mainChannelsData,  
  loading: false,
};

const mainChannelsSlice = createSlice({
   name: "mainChannels",
   initialState: initialState,
   reducers: {
     setmainChannels(state, value) {
       state.user = value.payload;
     },
     setmainChannelsLoading(state, value) {
       state.loading = value.payload;
     },
   },
});
const mainChannelsSliceReducer=mainChannelsSlice.reducer;
export const { setmainChannels, setmainChannelsLoading} = mainChannelsSlice.actions;
export default mainChannelsSliceReducer;
