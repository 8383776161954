import React from 'react'

const UserDefinedFields = () => {
  return (
    <div className="mx-auto my-8">
      <div className="border border-slate-900 p-6 rounded-lg">
        <form className="grid grid-cols-1 gap-1 lg:grid-cols-3 text-xs">
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF1">UDF 1</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none  focus:ring-blue-600" type="text" id="UDF1" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF2">UDF 2</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF2" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF3">UDF 3</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF3" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF4">UDF 4</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF4" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF5">UDF 5</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600" type="text" id="UDF5" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF6">UDF 6</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF6" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF7">UDF 7</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF7"/>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF8">UDF 8</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF8"/>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF9">UDF 9</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF9"/>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="UDF10">UDF 10</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-blue-600" type="text" id="UDF10"/>
          </div>
        </form>
      </div>
      
    </div>
  )
}

export default UserDefinedFields