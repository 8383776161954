import React, { useState } from 'react';
import { FaTrash, FaSave } from 'react-icons/fa';

const LocationContact = () => {
  const [formData, setFormData] = useState({
    locationCode: '',
    locationName: '',
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    fax: '',
    phone: '',
    alternatePhone: '',
    designation: '',
    isPrimaryContact: false,
  });

  const [contactData, setContactData] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setContactData([...contactData, { ...formData, id: contactData.length }]);
    setFormData({
      locationCode: '',
      locationName: '',
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      fax: '',
      phone: '',
      alternatePhone: '',
      designation: '',
      isPrimaryContact: false,
    });
  };

  const handleRemove = (id) => {
    setContactData(contactData.filter((item) => item.id !== id));
  };

  const handleFinalSubmit = () => {
    console.log("Final Contact Data:", contactData);
  };

  return (
    <div className="p-2 mx-auto">
      <div className='flex items-end justify-end mb-2'>
        <button
          onClick={handleFinalSubmit}
          className="py-1 px-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center"
        >
          <FaSave className="mr-1 h-4 w-4" />
          Submit
        </button>
      </div>
      <form onSubmit={handleSubmit} className="mb-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div className="mb-2">
            <label htmlFor="locationCode" className="block text-xs font-medium text-gray-700">
              Location Code
            </label>
            <input
              type="text"
              id="locationCode"
              name="locationCode"
              value={formData.locationCode}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="locationName" className="block text-xs font-medium text-gray-700">
              Location Name
            </label>
            <input
              type="text"
              id="locationName"
              name="locationName"
              value={formData.locationName}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="title" className="block text-xs font-medium text-gray-700">
              Title
            </label>
            <select
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            >
              <option value="">--- Select ---</option>
              <option value="Mr">Mr</option>
              <option value="Ms">Ms</option>
              <option value="Mrs">Mrs</option>
              <option value="Dr">Dr</option>
              <option value="Prof">Prof</option>
            </select>
          </div>

          <div className="mb-2">
            <label htmlFor="firstName" className="block text-xs font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="middleName" className="block text-xs font-medium text-gray-700">
              Middle Name
            </label>
            <input
              type="text"
              id="middleName"
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="lastName" className="block text-xs font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="email" className="block text-xs font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="fax" className="block text-xs font-medium text-gray-700">
              Fax
            </label>
            <input
              type="text"
              id="fax"
              name="fax"
              value={formData.fax}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="phone" className="block text-xs font-medium text-gray-700">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="alternatePhone" className="block text-xs font-medium text-gray-700">
              Alternate Phone
            </label>
            <input
              type="text"
              id="alternatePhone"
              name="alternatePhone"
              value={formData.alternatePhone}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="mb-2">
            <label htmlFor="designation" className="block text-xs font-medium text-gray-700">
              Designation
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              value={formData.designation}
              onChange={handleChange}
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
            />
          </div>

          <div className="flex items-center mb-2 col-span-3">
            <input
              type="checkbox"
              id="isPrimaryContact"
              name="isPrimaryContact"
              checked={formData.isPrimaryContact}
              onChange={handleChange}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="isPrimaryContact" className="ml-1 text-xs font-medium text-gray-700">
              Is Primary Contact
            </label>
          </div>
        </div>
        <div className='flex items-end justify-end mb-2'>
          <button
            type="submit"
            className="py-1 px-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add
          </button>
          <button
            type="button"
            onClick={() => setContactData([])}
            className="ml-2 py-1 px-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-red-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset
          </button>
        </div>
      </form>

      <table className="min-w-full divide-y divide-gray-200 border mb-4">
        <thead className="bg-slate-600 text-white">
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">First Name</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Last Name</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Designation</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Phone</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Alternate Phone</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Fax</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Email</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Primary Contact</th>
            <th className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {contactData.map((item) => (
            <tr key={item.id}>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{item.firstName}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{item.lastName}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{item.designation}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{item.phone}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{item.alternatePhone}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{item.fax}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">{item.email}</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                {item.isPrimaryContact ? 'Yes' : 'No'}
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                <button
                  type="button"
                  onClick={() => handleRemove(item.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LocationContact;
