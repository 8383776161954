import React, { useState } from 'react';

const Tabs = ({ data, maindata,handleInputChange }) => {
  {
    console.log("inside tabs", data);
  }
  const [activeTab, setActiveTab] = useState(data.length > 0 ? data[0].name : '');

  return (
    <div className="container mx-auto p-4">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {data.map((tab) => (
            <button
              key={tab.id} // Use unique id for key
              onClick={() => setActiveTab(tab.name)}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${activeTab === tab.name ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-4">
        {data.map((tab) => (
          activeTab === tab.name && (
            <div key={tab.id} className="p-4">
               <tab.component maindata={maindata} handleInputChange={handleInputChange}/>{/*Render the component for the active tab */}
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Tabs;
