import React, { useState } from 'react'
import { SubOrderEdit } from '../../../../../../../../data/tabsData';
import Tabs from '../../../../../../../common/Tabs';
import { useSelector, useDispatch } from "react-redux";


const OrderDetailPage = () => {

  const orderEnquiry = useSelector((state) => state.orderEnquiry.data);
  const loading = useSelector((state) => state.orderEnquiry.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState(orderEnquiry);


  const handleInputChange = (name, value, category) => {
    console.log(name,value,category)
    const isNumberField = ["orderNo", "orderAmount", "discountAmount"].includes(name);
    const parsedValue = isNumberField ? parseFloat(value) || "" : value;
  
    const categoryParts = category.split('.');
  
    if (category===name){
        setData((prevData) => ({
          ...prevData,
          [name]: parsedValue, // Update directly without category nesting
        }));
    } else if (categoryParts.length===1){
      setData((prevData) => ({
        ...prevData,
        [category]: {
          ...prevData[category],
          [name]: parsedValue,
        },
      }));
    } else {
      const updatedData = { ...data };
      let targetObj = categoryParts.reduce((obj, key) => {
        // Ensure we are working with a copy at each level
        obj[key] = { ...obj[key] };
        return obj[key];
    }, updatedData);
    // Update the target value
    targetObj[name] = value;

    // Update the state with the new data object
    setData(updatedData);


  }

  };
  
  


  return (
    <>
    <div className='bg-white shadow-lg'>
      <Tabs data={SubOrderEdit}  maindata={data} handleInputChange={handleInputChange}/>
    </div>
    
    </>
  )
}

export default OrderDetailPage