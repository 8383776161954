import React, { useState } from 'react'
import ShipToTab from './ShipToTab';
import BillToTab from './BillToTab';
import { AddressSubOrderTabButton } from '../../../OrderButton';
import OrderDetailTab from '../OrderDetailTab/OrderDetailTab';
import { useSelector, useDispatch } from "react-redux";


const AddressSubTab = ({maindata,handleInputChange}) => {
  const [text, setText] = useState('This is the default comment...');
    const status=["Default","Active","Inactive"]
    const data=maindata;
    console.log("Address Tab",data)
    const header=[{inputType:"option",head:"Address Verified",name:"addVerified", opt:["Yes","No"],category:"address"},
        {inputType:"option",head:"Address",name:"addressdetail",category:"address",opt:["...","Gurugram","No"]},
        {inputType:"textarea",head:"Remark",name:"addressRemark",category:"address"},
    ];

return (
<>
<AddressSubOrderTabButton/>
<div className='flex justify-center'>
<div className='flex flex-wrap w-full'>
{/* First Column */}
<div className=' xl:w-3/4 lg:w-3/4 md:w-2/3 sm:w-full xs:w-1/2 w-full p-2'>
<div className="flex flex-wrap">
{header.map((item, index)=>
    (item.inputType==="text"?
    <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
    <div className="flex flex-wrap">
    <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
    <div className="sm:w-1/2 w-full">
    <input type="text" className='border rounded border-gray-200 w-full' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}/> 
    </div>  
    </div>
  </div>
    :item.inputType==="textarea"?
    <div className="xl:w-full lg:w-full sm:w-full w-full p-1" key={index}>
    <div className="flex flex-wrap">
    <div className='px-2'>{item.head}:</div>     
    <textarea rows="2" cols="50" className='border border-slate-400 rounded' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
        This is the default comment...  
      </textarea>
    </div>
  </div>
    :
    <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
    <div className="flex flex-wrap">
    <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
    <div className="sm:w-1/2 w-full">
    <select className="w-full max-w-52 border rounded border-gray-200" name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
            {item.opt.map((stat, idx)=>(<option value={stat} key={idx}>{stat}</option>))}
    </select>
    </div>   
    </div>
  </div>
)
)}
</div>
<div>
    <ShipToTab data={data} handleInputChange={handleInputChange}/>
    <BillToTab data={data} handleInputChange={handleInputChange}/>
</div>

</div>
{/* Second Column */}
<div className='xl:w-1/4 lg:w-1/4 md:w-1/3 sm:w-full xs:w-1/2 w-full'>
  <OrderDetailTab  />
  
</div>

</div>
</div>
</>
)
}

export default AddressSubTab