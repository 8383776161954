
import React, { useEffect, useState } from 'react'
import SearchPannel from './SearchPannel';
import TableView from './tableView';
import { useDispatch, useSelector } from 'react-redux';
import { setmainChannelsLoading } from '../../../../../slice/mainChannelsSlice';



export const CustomBox = ({ label, children }) => {
  return (
   <>
   <div className="relative inline-block border rounded border-black p-4 bg-white w-full border-stone-300">
      <div className="absolute top-0 left-0 -mt-3 ml-2 bg-white px-1">
        <span className='text-stone-300'>{label}</span>
      </div>
      {children}
    </div>
   </>
  );
};

const MasterChanel = () => {
  const [search, setSearch] = useState({});
  const mainChannels = useSelector((state) => state.mainChannels.data);
  const loading = useSelector((state) => state.mainChannels.loading);
  const dispatch = useDispatch();
  console.log("channels data from slice",mainChannels.channels)
  const intialarry=mainChannels.channels
  const [tablearray,settablearray]=useState(intialarry);




    const chanefilterfield=(e)=>{
      if (e.target.value==="Default" && e.target.name==="status"){
        setSearch({...search,[e.target.name]: ""});
      }else{
      setSearch({
        ...search,
        [e.target.name]: e.target.value
      });}
    };

    useEffect(()=>{
      dispatch(setmainChannelsLoading(true));

      setTimeout(() => {
        dispatch(setmainChannelsLoading(false));
      }, 1000);


      settablearray([...filteredData])
      },[search]);

    const filteredData = intialarry.filter(item =>
      Object.keys(search).every(key =>
        search[key]
          ? String(item[key]).toLowerCase().includes(search[key].toLowerCase())
          : true
      )
    );

    if (loading) {
      return <div>Loading...</div>;
    }

  return (
    <>
<div className='bg-white p-2 shadow-sm'>
      <div className="flex pt-2 w-full"> 
        <CustomBox label="Search">
          <div className=" pt-2 pr-1 pl-1">
          <SearchPannel chanefilterfield={chanefilterfield} search={search}/>
          </div>
        </CustomBox>
      </div>
      <TableView tablearray={tablearray}></TableView>
      </div>

    
    </>
  );
};

export default MasterChanel;
