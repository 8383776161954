import React, { useState } from 'react'
import PurchOrdTable from './PurchOrdTable'
import { POEnquireyHeader } from '../../../../../../../data/Headers'
import { POMainButton } from '../../ProcurementButtons'


const initialarray=[{
  code :"WH1337",
  poCode:{
    "poQty":4169,
    "pending":0,
    "rcvd":4169,
    "closed":0.00,
    "amt":1031819.44,
    "asnQty":0
  },
  poDate:"06/09/2024",
  expDate:"",
  vendor:"BM02-Baby & Mom Retail Pvt. Ltd (DL)",
  poType:"outright",
  status:"closed",
  delivLocation:"Baby & Mom Retail Pvt.I",
  masterPOCode:"",
},
{
  code :"WH1337",
  poCode:{
    "poQty":4169,
    "pending":0,
    "rcvd":4169,
    "closed":0.00,
    "amt":1031819.44,
    "asnQty":0
  },
  poDate:"06/08/2024",
  expDate:"",
  vendor:"BM02-Baby & Mom Retail Pvt. Ltd (DL)",
  poType:"outright",
  status:"closed",
  delivLocation:"Baby & Mom Retail Pvt.I",
  masterPOCode:"",
}
]

function MainPurchaseOrder() {
  const [key, setKey] = useState(0);
  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };
  return (
    <>
    <div className="container mx-auto p-4 bg-white">
      <POMainButton resetHandler={resetTable}/>
      <div key={key}>
      <PurchOrdTable headers={POEnquireyHeader} data={initialarray} link={"/master/procurement/podetail"} />
      </div>
    </div>
    </>
  )
}

export default MainPurchaseOrder