import React from "react";
import { FiSearch, FiX, FiPlusCircle } from "react-icons/fi";
import { useState } from "react";

const SKUPickList = ({ title,headers, data,setModal }) => {
  const [search, setSearch] = useState({});
  const handleSearchChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });
  };

  const resetSearchChange = () => {
    setSearch({});
  };
  const filteredData = data.filter(item =>
    Object.keys(search).every(key =>
      search[key]
        ? String(item[key]).toLowerCase().includes(search[key].toLowerCase())
        : true
    )
  );


  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl">
        <div className="bg-blue-600 text-white p-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button className="text-white text-xl" onClick={()=>{setModal(false)}}>
            <FiX />
          </button>
          <div className='flex justify-end mb-4 space-x-2'>
          <button className="bg-gray-200 text-black  px-3 py-2 rounded" onClick={resetSearchChange}>Reset</button>
        </div>
        </div>
        <div className="p-6">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label htmlFor="skuCode" className="text-sm font-medium mb-1">
                SKU Code
              </label>
              <input
                type="text"
                id="skuCode"
                name="skuCode"
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={handleSearchChange}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="skuName" className="text-sm font-medium mb-1">
                SKU Name
              </label>
              <input
                type="text"
                id="skuName"
                name="skuName"
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mb-4">
            <button className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 focus:outline-none">
              Reset
            </button>
          </div>
          <table className="min-w-full border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                {headers.map((header, index) => (
                  <th key={index} className="px-4 py-2 border-b text-left text-sm font-medium text-gray-600">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <td key={colIndex} className="px-4 py-2 border-b text-gray-700">
                        {row[header]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-4 py-2 border-b text-center" colSpan={headers.length}>
                    No records to view
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center space-x-2">
              <button className="p-2 bg-gray-200 rounded hover:bg-gray-300">{"<<"}</button>
              <button className="p-2 bg-gray-200 rounded hover:bg-gray-300">{"<"}</button>
              <span className="text-sm">
                Page <input type="number" className="border border-gray-300 rounded w-12 text-center" value="1" readOnly /> of 0
              </span>
              <button className="p-2 bg-gray-200 rounded hover:bg-gray-300">{">"}</button>
              <button className="p-2 bg-gray-200 rounded hover:bg-gray-300">{">>"}</button>
            </div>
            <div className="flex items-center space-x-2">
              <select className="border border-gray-300 rounded p-1">
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="text-sm">records to view</span>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 p-4 rounded-b-lg flex justify-end space-x-2">
          <button className="bg-orange-500 text-white py-2 px-4 rounded flex items-center space-x-2 hover:bg-orange-600 focus:outline-none">
            <FiPlusCircle />
            <span>Add SKU</span>
          </button>
          <button className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400 focus:outline-none" onClick={()=>{setModal(false)}}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SKUPickList;
