import React, { useState, useEffect } from 'react';
import { sizeHeaders } from '../../../../data/Headers';
import DataTableModal from '../../../common/DataTableModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { sizePoints, sizeGrouppoints } from '../../../../services/apis';
import { apiConnector } from '../../../../services/apiconnector';
import { useSelector } from 'react-redux';
import { createSize, updateSize } from '../../../../services/operations/sizeApi';
import Loading from '../../../common/Loading';

// Fetch Size function
const fetchSize = async (token) => {
  const response = await apiConnector('GET', sizePoints.SHOW_ALL_SIZE, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data =response?.data?.data;
  return data.map(item => ({
    id: item.id,
    sizeGroup: item.size_group_id,
    size: item.name,
    size_group_id: item.size_group_id
  }));
};

// Fetch Size Groups function
const fetchSizeGroup = async (token) => {
  const response = await apiConnector('GET', sizeGrouppoints.SHOW_ALL_SIZE_GROUPS, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data = response?.data?.data;
  return data.map(item => ({
    id: item.id,
    sizeGroupDescription: item.name
  }));
};

const Size = () => {
  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  
   // Query for size data
   const { data: sizeData, error: sizeError, isLoading: isSizeLoading } = useQuery({
    queryKey: ['size', token],
    queryFn: () => fetchSize(token),
    enabled: !!token,
  });

  // Query for size groups
  const { data: sizeGroupData, error: sizeGroupError, isLoading: isSizeGroupLoading } = useQuery({
    queryKey: ['sizeGroups', token],
    queryFn: () => fetchSizeGroup(token),
    enabled: !!token,
  });

  // Handle loading and error states
  if (isSizeLoading || isSizeGroupLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading/></div>;
  }

  if (sizeError || sizeGroupError) {
    return <div>Error: {sizeError?.message || sizeGroupError?.message}</div>;
  }

  // Handle adding new size
  const handleAddNew = async (newData) => {
    await createSize(newData, token);
    queryClient.invalidateQueries(['size']); 
  };

  // Handle updating size
  const handleUpdate = async (id, updatedData) => {
    await updateSize(id, updatedData, token);
    queryClient.invalidateQueries(['size']);
  };

  // Update sizeHeaders with size group options
  const updatedSizeHeaders = sizeHeaders.map(header => 
    header.key === 'sizeGroup'
      ? { ...header, options: sizeGroupData.map(option => ({ label: option.sizeGroupDescription, value: option.id })) }
      : header
  );


  return (
    <div className="rounded-lg p-4 mt-2 min-h-screen">
      <DataTableModal 
        id='4' 
        isAddEnabled={true} 
        headers={updatedSizeHeaders} 
        data={sizeData}  
        title="New" 
        name="size"
        onAddNew={handleAddNew} 
        onUpdate={handleUpdate} 
      />
    </div>
  );
};

export default Size;
