import React, { useState, useEffect } from 'react';
import { toast } from "react-hot-toast";

const TaxModal = ({ headers, toggleModal, currentData, onUpdate, onAddNew }) => {
  const [newRecord, setNewRecord] = useState(currentData || {});
 {console.log(newRecord)}
  useEffect(() => {
    if (currentData) {
      setNewRecord(currentData);
    }
  }, [currentData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value
    }));
  };

  const handleSaveOrUpdate = () => {
    if (currentData) {
      currentData===newRecord ? toast.error("Please! Update Required Details"):onUpdate(currentData.id, newRecord);
    } else {
      onAddNew(newRecord);
    }
    toggleModal(); // Close the modal after saving/updating
  };

  return (
    <div className="fixed inset-0 z-[2000] overlay bg-black bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="relative mx-auto p-6 border w-full max-w-lg shadow-lg rounded-md bg-white transform transition-transform duration-300">
        <h2 className="text-xl mb-4">
          {newRecord ? "Edit Record" : "Add New Record"}
        </h2>
        <div className="max-h-[70vh] overflow-y-auto">
          {headers.map((header) => (
            <div key={header.key} className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{header.label}</label>
              {header.isInput ? (
                <input
                  type={header.type || 'text'}
                  name={header.key}
                  value={newRecord[header.key] || ''}
                  onChange={handleInputChange}
                  className="mt-1 p-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 placeholder-gray-500"
                  placeholder={`Enter ${header.label}`}
                />
              ) : (
                <select
                  name={header.key}
                  value={newRecord[header.key] || ''}
                  onChange={handleInputChange}
                  className="mt-1 p-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100 text-gray-900 placeholder-gray-500"
                >
                  <option value="">Select {header.label}</option>
                  {header.options.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSaveOrUpdate}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
          >
            {currentData ? "Update" : "Save"}
          </button>
          <button
            onClick={toggleModal}
            className="bg-gray-600 text-white px-4 py-2 rounded ml-2 hover:bg-gray-700 transition"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaxModal;
