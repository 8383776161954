import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { FaSave } from 'react-icons/fa';
import DataTableSelectionModal from '../../common/DataTableSelectionModal';

const SkuComponent = () => {
  const [modal,setModal] = useState(false);
  const [formData, setFormData] = useState({
    skuCode: '',
    skuDesc: '',
    qtyRatio: '',
    priceRatio: '',
  });

  const [skuData, setSkuData] = useState([]);

  const headers = ["SKU Code", "SKU Desc", "UOM"];
  const data = [
    { "skuCode": "001", "skuDesc": "Product A", "UOM": "Each" },
    { "skuCode": "002", "skuDesc": "Product B", "UOM": "Box" },
    { "skuCode": "003", "skuDesc": "Product C", "UOM": "Pack" },
    { "skuCode": "004", "skuDesc": "Product D", "UOM": "Each" },
    { "skuCode": "005", "skuDesc": "Product E", "UOM": "Box" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add new item to the table
    setSkuData([...skuData, { ...formData, id: skuData.length }]);
    // Clear the form
    setFormData({
      skuCode: '',
      skuDesc: '',
      qtyRatio: '',
      priceRatio: '',
    });
  };

  const handleRemove = (id) => {
    // Remove the item from the table
    setSkuData(skuData.filter((item) => item.id !== id));
  };

  const handlePriceRatioChange = (id, value) => {
    // Update the priceRatio for the specific item in the table
    const updatedSkuData = skuData.map((item) =>
      item.id === id ? { ...item, priceRatio: value } : item
    );
    setSkuData(updatedSkuData);
  };

  const handleFinalSubmit = () => {
    // Handle the final submission of all skuData
    console.log("Final SKU Data:", skuData);
    // Here you can send skuData to the server or another function
  };

  return (
    <div className="p-4 mx-auto">
      <div className='flex items-end justify-end'>
       <button onClick={handleFinalSubmit} className="py-2 px-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center justify-center">
          <FaSave className="mr-2 h-5 w-5" />
          Submit
      </button>
      </div>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="grid grid-cols-3 gap-4">
        <div className="relative col-span-3 sm:col-span-1">
          <label htmlFor="skuCode" className="block text-sm font-medium text-gray-700">
            SKU Code
          </label>
          <input
            type="text"
            id="skuCode"
            name="skuCode"
            value={formData.skuCode}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <div
            className="absolute right-1 bottom-1 flex items-center  cursor-pointer"
            onClick={()=>{setModal(true)}}
          >
            <div className="bg-blue-500 text-white px-2 py-1 rounded">
              <span className="text-sm">...</span>
            </div>
          </div>
         </div>

          <div className="col-span-3 sm:col-span-1">
            <label htmlFor="skuDesc" className="block text-sm font-medium text-gray-700">
              SKU Desc
            </label>
            <input
              type="text"
              id="skuDesc"
              name="skuDesc"
              value={formData.skuDesc}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="col-span-3 sm:col-span-1">
            <label htmlFor="qtyRatio" className="block text-sm font-medium text-gray-700">
              Qty Ratio
            </label>
            <input
              type="text"
              id="qtyRatio"
              name="qtyRatio"
              value={formData.qtyRatio}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="col-span-3 sm:col-span-1">
            <label htmlFor="priceRatio" className="block text-sm font-medium text-gray-700">
              Price Ratio
            </label>
            <input
              type="text"
              id="priceRatio"
              name="priceRatio"
              value={formData.priceRatio}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className='flex items-end justify-end'>
        <div className='flex justify-between gap-2'>
        <button
          type="submit"
          className="mt-4  py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add 
        </button>
        <button
          onClick={(e)=>{e.preventDefault();  setSkuData([])}}
          className="mt-4  py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Reset
        </button>
        </div>
        </div>
      </form>

      <table className="min-w-full divide-y divide-gray-200 border mb-4">
        <thead className="bg-slate-600 text-white">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">SKU Code</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">SKU Desc</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Qty Ratio</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Price Ratio</th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {skuData.map((item) => (
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.skuCode}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.skuDesc}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.qtyRatio}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <input
                  type="text"
                  value={item.priceRatio}
                  onChange={(e) => handlePriceRatioChange(item.id, e.target.value)}
                  className="w-full px-2 py-1 border border-gray-300 rounded-md"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                  type="button"
                  onClick={() => handleRemove(item.id)}
                  className="text-red-600 hover:text-red-800"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modal && <DataTableSelectionModal title="SKU Selection" headers={headers} data={data} setModal={setModal} setFormData={setFormData} />}
    </div>
  );
};

export default SkuComponent;
