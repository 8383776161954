import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { statePoints } from "../apis";
const { CREATE_STATES,UPDATE_STATES } = statePoints;

export const createState = async (data, token) => {
    let result = null
    const toastId = toast.loading("Loading...")
    const formattedData = {
        id: data.id,
        country_id: data.country,
        name: data.state,
        is_active:  1,
      };
    try {
      const response = await apiConnector("POST", CREATE_STATES,formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      })
      console.log("CREATE STATE ............", response)
       if (!response?.data?.success) {
         throw new Error("STATE could not be created")
       }
     toast.success("STATE created successfully");
    } catch (error) {
      console.log("CREATE STATE  API ERROR............", error)
      toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result
  }
  
  export const updateState = async (id, data, token) => {
    let result = null;
    const toastId = toast.loading("Loading...");
    const formattedData = {
      id: data.id,
      country_id: data.country,
      name: data.state,
      is_active:  1,
    };
    console.log(formattedData); // For debugging
    try {
      const response = await apiConnector("PUT", `${UPDATE_STATES}${id}`, formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      });
  
      console.log("UPDATE STATE  RESPONSE............", response);

       if (!response?.data?.success) {
         throw new Error("STATE  could not be updated");
       }
      toast.success("Your STATES have been updated");
    } catch (error) {
      console.log("UPDATE STATE  API ERROR............", error);
      toast.error(error.message);
    }
  
    toast.dismiss(toastId);
    return result;
  };