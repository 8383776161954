import React, { useState } from 'react';
import { materialsHeaders } from '../../../../data/Headers';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import DataTableModal from '../../../common/DataTableModal';
import { materialpoints } from '../../../../services/apis';
import { apiConnector } from '../../../../services/apiconnector';
import { useSelector } from 'react-redux';
import { createMaterials,updateMaterials } from '../../../../services/operations/materialsApi';
import Loading from '../../../common/Loading';

// Fetch brands function
const fetchMaterials = async (token) => {
  const response = await apiConnector('GET', materialpoints.SHOW_ALL_MATERIALS, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data=response.data.data;
  console.log(response);
  return data.map(item => ({
    id: item.id,
    materialName: item.name,
  }));
};

const Materials = () => {

  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  const { data: materialData, error, isLoading } = useQuery({
    queryKey: ['materials', token],
    queryFn: () => fetchMaterials(token),
    enabled: !!token,
  });

  if (isLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading/></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddNew = async (newData) => {
    await  createMaterials(newData, token);
    queryClient.invalidateQueries(['materials']); 
  };

  const handleUpdate = async (id, updatedData) => {
    await updateMaterials(id, updatedData, token);
    queryClient.invalidateQueries(['materials']);
  };
      


  return (
    <div className="rounded-lg p-4 mt-2">
      <DataTableModal id='4' isAddEnabled={true} isDownloadEnabled={true} headers={ materialsHeaders} data={materialData || []}  title="New"  name="Materials"
        onAddNew={handleAddNew}
        onUpdate={handleUpdate} />
    </div>
  );
};

export default Materials;
