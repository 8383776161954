import React, { useState } from 'react';

const DimensionDetails = () => {
  const [formData, setFormData] = useState({
    height: '',
    length: '',
    width: '',
    cube: '',
    weight: '',
    grossWeight: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Dimension Details Data:', formData);
    // Handle form submission here
  };

  return (
    <form onSubmit={handleSubmit} className="p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 font-medium text-xs lg:grid-cols-3 gap-2">
        {/* Height */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Height (cm) (CM)</label>
          <input
            type="text"
            name="height"
            value={formData.height}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Length */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Length (cm) (CM)</label>
          <input
            type="text"
            name="length"
            value={formData.length}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Width */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Width (cm) (CM)</label>
          <input
            type="text"
            name="width"
            value={formData.width}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Cube */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Cube</label>
          <input
            type="text"
            name="cube"
            value={formData.cube}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Weight */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Weight (kg) (KG)</label>
          <input
            type="text"
            name="weight"
            value={formData.weight}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Gross Weight */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Gross Weight (kg) (KG)</label>
          <input
            type="text"
            name="grossWeight"
            value={formData.grossWeight}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>
      </div>

      <div className="flex justify-center mt-6">
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default DimensionDetails;
