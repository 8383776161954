import React, { useState } from 'react';

const ShelfLifeForm = () => {
  const [formData, setFormData] = useState({
    shelfLifeType: '',
    totalShelfLife: '',
    shelfLifeOnReceiving: '',
    shelfLifeOnPicking: '',
    serialTracking: false,
    stackable: false,
    hazardous: false,
    poisonous: false,
    isPurchasable: false,
    isSaleable: false,
    isStocked: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // Handle form submission here
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 font-medium text-xs">
        {/* Shelf Life Type */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Shelf Life Type</label>
          <select
            name="shelfLifeType"
            value={formData.shelfLifeType}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            <option value="type1">Type 1</option>
            <option value="type2">Type 2</option>
            {/* Add other options as needed */}
          </select>
        </div>

        {/* Total Shelf Life */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Total Shelf Life</label>
          <input
            type="text"
            name="totalShelfLife"
            value={formData.totalShelfLife}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Shelf Life on Receiving */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Shelf Life on Receiving (No. of Days)</label>
          <input
            type="text"
            name="shelfLifeOnReceiving"
            value={formData.shelfLifeOnReceiving}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Shelf Life on Picking */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Shelf Life on Picking</label>
          <input
            type="text"
            name="shelfLifeOnPicking"
            value={formData.shelfLifeOnPicking}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Serial Tracking */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="serialTracking"
              checked={formData.serialTracking}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Serial Tracking</span>
          </label>
        </div>

        {/* Stackable */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="stackable"
              checked={formData.stackable}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Stackable</span>
          </label>
        </div>

        {/* Hazardous */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="hazardous"
              checked={formData.hazardous}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Hazardous</span>
          </label>
        </div>

        {/* Poisonous */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="poisonous"
              checked={formData.poisonous}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Poisonous</span>
          </label>
        </div>

        {/* Is Purchasable */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isPurchasable"
              checked={formData.isPurchasable}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Is Purchasable</span>
          </label>
        </div>

        {/* Is Saleable */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isSaleable"
              checked={formData.isSaleable}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Is Saleable</span>
          </label>
        </div>

        {/* Is Stocked */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isStocked"
              checked={formData.isStocked}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Is Stocked</span>
          </label>
        </div>
      </div>

      <div className="flex justify-center mt-6">
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ShelfLifeForm;
