import React, { useState } from 'react';
import { paymentTermsHeaders } from '../../../../data/Headers';
import DataTableModal from '../../../common/DataTableModal';
import { paymentTermPoints } from '../../../../services/apis';
import { createPaymentTerms,updatePaymentTerms } from '../../../../services/operations/paymentTerms';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiConnector } from '../../../../services/apiconnector';
import { useSelector } from 'react-redux';
import Loading from '../../../common/Loading';

// Fetch Payment Terms function
const fetchPaymentTerms = async (token) => {
  const response = await apiConnector('GET', paymentTermPoints.SHOW_ALL_PAYMENT_TERMS, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data=response.data.data;
  console.log('Response Payment Terms'+response);
  return data.map(item => ({
    id: item.id,
    paymentTerms: item.name,
  }));
};
const PaymentTerms = () => {
  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  
   // Query for size data
   const { data: paymentTermData, error, isLoading } = useQuery({
    queryKey: ['paymentterms', token],
    queryFn: () => fetchPaymentTerms(token),
    enabled: !!token,
  });
  
    
  if (isLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading/></div>;
  }

  if (error) {
    return <div>Error: {error?.message}</div>;
  }

    // Handle adding new size
    const handleAddNew = async (newData) => {
      await createPaymentTerms(newData, token);
      queryClient.invalidateQueries(['paymentterms']); 
    };
  
    // Handle updating size
    const handleUpdate = async (id, updatedData) => {
      await updatePaymentTerms(id, updatedData, token);
      queryClient.invalidateQueries(['paymentterms']);
    };


  return (
    <div className="rounded-lg p-4 mt-2">
      <div className="rounded-lg p-4 mt-2 min-h-screen">
      <DataTableModal 
        id='4' 
        isAddEnabled={true} 
        headers={paymentTermsHeaders} 
        data={paymentTermData}  
        title="New" 
        name="size"
        onAddNew={handleAddNew} 
        onUpdate={handleUpdate} 
      />
    </div>
    </div>
  );
};

export default PaymentTerms;
