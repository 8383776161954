import React from 'react'

const ImportTable = ({tablearray,head}) => {  
  return (
    <>
    <div className=" mx-auto py-0">
    <div className="overflow-x-auto">
    <table className="w-full bg-white border border-gray-200 table-fixed">
<thead>
<tr className="bg-slate-200">
  {head.map((item,index)=>(
    <th className="border border-slate-300" key={index}>{item}</th>
  ))}
</tr>
</thead>
<tbody>
{tablearray.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Object.values(row).map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
</tbody>
</table>
    </div>
</div>
    </>
  )
}

export default ImportTable