import React from 'react'


const header=[{inputType:"text",head:"SKU Code",name:""},
    {inputType:"text",head:"SKU Name",name:"ChName"},
    {inputType:"text",head:"SKU Short Name",name:"status"},
    {inputType:"text",head:"SKU Classification",name:"RegDate"},
    {inputType:"text",head:"Style",name:"ChConfig"},
    {inputType:"option",head:"SKU Source",name:"ChConfig", option:["outright","outbound"]},
    {inputType:"option",head:"Attribute Set",name:"ChConfig",option:["--select--","default"]},
    {inputType:"option",head:"Size Group",name:"ChConfig",option:["--select--","default"]},
    {inputType:"text",head:"size",name:"ChConfig"},
    {inputType:"option",head:"Colour",name:"ChConfig",option:["--select--","default"]},
    {inputType:"option",head:"Material",name:"ChConfig",option:["--select--","default"]},
    {inputType:"text",head:"Primary UPC/EAN",name:"ChConfig"},
    {inputType:"option",head:"Base UOM",name:"ChConfig",option:["--select--","default"]},
    {inputType:"text",head:"Hierarchy Code",name:"ChConfig"},
    {inputType:"text",head:"Vendor SKU Code",name:"ChConfig"},
    {inputType:"text",head:"Primary Code",name:"ChConfig"},
    {inputType:"text",head:"Vendor Name",name:"ChConfig"},
    {inputType:"text",head:"Is Approved",name:"ChConfig"},
    {inputType:"option",head:"Status",name:"ChConfig",option:["Active","Deactive"]},
    {inputType:"option",head:"Country Of Origin",name:"ChConfig",option:["India","Nepal"]},
    {inputType:"text",head:"Brand Code",name:"ChConfig"},
    {inputType:"text",head:"Link To Store",name:"ChConfig"},
    {inputType:"text",head:"Part Model No.",name:"ChConfig"},
    {inputType:"text",head:"Season",name:"ChConfig"},
    {inputType:"text",head:"SKU Grade",name:"ChConfig"},
    {inputType:"text",head:"Gradable SKU",name:"ChConfig"},
];

export const ProductDetailSubTab = () => {
  return (
    <>
    <div className="flex flex-wrap">
    {header.map((item, index)=>
        (item.inputType==="text"?
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">
        <input type="text" className='border rounded border-gray-200 w-full' />
        </div>   
        </div>
      </div>
        :
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">
            <select className="w-full max-w-52 border rounded border-gray-200">
              {item.option.map((opt)=>(<option >{opt}</option>))}
            </select>
        </div>   
        </div>
      </div>
    )
    )}
    </div>
    </>
  )
}

export const PricingDetailSubTab = () => {
    return (
      <>
      <div className="flex flex-wrap">
      <div>Pricing Detail</div>
      </div>
      </>
    )
  }

  export const DimensionDetailSubTab = () => {
    return (
      <>
      <div className="flex flex-wrap">
      <div>Dimension Detail</div>
      </div>
      </>
    )
  }

  export const ImageSubTab = () => {
    return (
      <>
      <div className="flex flex-wrap">
      <div>Image SKU</div>
      </div>
      </>
    )
  }
