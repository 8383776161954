import React, { useState } from 'react';

const Home = () => {
  const [vendorData, setVendorData] = useState({
    vendorCode: '',
    vendorName: '',
    vendorShortName: '',
    cstRegNo: '',
    vatRegNo: '',
    tinNo: '',
    creditDays: '',
    taxZone: '',
    status: '',
    return: '',
    vendorType: '',
    panItNumber: '',
  });

  const [bankData, setBankData] = useState({
    bankName: '',
    accountNo: '',
    branchName: '',
    branchAddress: '',
    ifscCode: '',
    swiftCode: '',
    micrCode: '',
    expiryDays: '',
  });

  const handleVendorChange = (e) => {
    const { id, value } = e.target;
    setVendorData({
      ...vendorData,
      [id]: value,
    });
  };

  const handleBankChange = (e) => {
    const { id, value } = e.target;
    setBankData({
      ...bankData,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.example.com/submit-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          vendorData,
          bankData,
        }),
      });
      if (response.ok) {
        console.log('Data submitted successfully');
        // Reset form state if needed
        setVendorData({
          vendorCode: '',
          vendorName: '',
          vendorShortName: '',
          cstRegNo: '',
          vatRegNo: '',
          tinNo: '',
          creditDays: '',
          taxZone: '',
          status: '',
          return: '',
          vendorType: '',
          panItNumber: '',
        });
        setBankData({
          bankName: '',
          accountNo: '',
          branchName: '',
          branchAddress: '',
          ifscCode: '',
          swiftCode: '',
          micrCode: '',
          expiryDays: '',
        });
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container mx-auto mt-4">
      {/* <div className="flex justify-end mb-2">
        <div className="mt-6">
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-indigo-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </div> */}

      <div className="border border-slate-800 p-6 rounded-lg relative">
        <h2 className="text-md absolute -top-4 left-4 bg-white font-semibold mb-6 text-gray-800">Vendor Master</h2>
  
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="vendorCode" className="block text-xs font-medium text-gray-700 mb-1">
                Vendor Code*
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="vendorCode"
                value={vendorData.vendorCode}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="vendorName" className="block text-xs font-medium text-gray-700 mb-1">
                Vendor Name*
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="vendorName"
                value={vendorData.vendorName}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="vendorShortName" className="block text-xs font-medium text-gray-700 mb-1">
                Vendor Short Name
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="vendorShortName"
                value={vendorData.vendorShortName}
                onChange={handleVendorChange}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="cstRegNo" className="block text-xs font-medium text-gray-700 mb-1">
                CST Regi. No
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="cstRegNo"
                value={vendorData.cstRegNo}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="vatRegNo" className="block text-xs font-medium text-gray-700 mb-1">
                VAT Regi. No
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="vatRegNo"
                value={vendorData.vatRegNo}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="tinNo" className="block text-xs font-medium text-gray-700 mb-1">
                Tin No
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="tinNo"
                value={vendorData.tinNo}
                onChange={handleVendorChange}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="creditDays" className="block text-xs font-medium text-gray-700 mb-1">
                Credit Days
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="creditDays"
                value={vendorData.creditDays}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="taxZone" className="block text-xs font-medium text-gray-700 mb-1">
                Tax Zone*
              </label>
              <select
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="taxZone"
                value={vendorData.taxZone}
                onChange={handleVendorChange}
              >
                <option value="">--- Select ---</option>
                {/* Add options as needed */}
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="status" className="block text-xs font-medium text-gray-700 mb-1">
                Status
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="status"
                value={vendorData.status}
                onChange={handleVendorChange}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="return" className="block text-xs font-medium text-gray-700 mb-1">
                Return
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="return"
                value={vendorData.return}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="vendorType" className="block text-xs font-medium text-gray-700 mb-1">
                Vendor Type*
              </label>
              <select
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="vendorType"
                value={vendorData.vendorType}
                onChange={handleVendorChange}
              >
                <option value="">--- Select ---</option>
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="panItNumber" className="block text-xs font-medium text-gray-700 mb-1">
                PAN/IT Number
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="panItNumber"
                value={vendorData.panItNumber}
                onChange={handleVendorChange}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="return" className="block text-xs font-medium text-gray-700 mb-1">
                Parent Vendor
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="return"
                value={vendorData.return}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="vendorType" className="block text-xs font-medium text-gray-700 mb-1">
                Currency Code*
              </label>
              <select
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="vendorType"
                value={vendorData.vendorType}
                onChange={handleVendorChange}
              >
                <option value="">--- Select ---</option>
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="panItNumber" className="block text-xs font-medium text-gray-700 mb-1">
                  GST No.
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="panItNumber"
                value={vendorData.panItNumber}
                onChange={handleVendorChange}
              />
            </div>
          </div>


          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="return" className="block text-xs font-medium text-gray-700 mb-1">
                Min Order Value
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="return"
                value={vendorData.return}
                onChange={handleVendorChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="panItNumber" className="block text-xs font-medium text-gray-700 mb-1">
                 Lead Time(In Time)*
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="panItNumber"
                value={vendorData.panItNumber}
                onChange={handleVendorChange}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="panItNumber" className="block text-xs font-medium text-gray-700 mb-1">
                SOR Days
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="panItNumber"
                value={vendorData.panItNumber}
                onChange={handleVendorChange}
              />
            </div>
          </div>
        </form>
      </div>

      <div className="mt-8 border border-slate-800 relative p-6 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold mb-6 absolute -top-4 left-4 bg-white text-gray-800">Bank Details</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="bankName" className="block text-xs font-medium text-gray-700 mb-1">
                Bank Name
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="bankName"
                value={bankData.bankName}
                onChange={handleBankChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="accountNo" className="block text-xs font-medium text-gray-700 mb-1">
                Account No
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="accountNo"
                value={bankData.accountNo}
                onChange={handleBankChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="branchName" className="block text-xs font-medium text-gray-700 mb-1">
                Branch Name
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="branchName"
                value={bankData.branchName}
                onChange={handleBankChange}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="branchAddress" className="block text-xs font-medium text-gray-700 mb-1">
                Branch Address
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="branchAddress"
                value={bankData.branchAddress}
                onChange={handleBankChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="ifscCode" className="block text-xs font-medium text-gray-700 mb-1">
                IFSC Code
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="ifscCode"
                value={bankData.ifscCode}
                onChange={handleBankChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="swiftCode" className="block text-xs font-medium text-gray-700 mb-1">
                Swift Code
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="swiftCode"
                value={bankData.swiftCode}
                onChange={handleBankChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
            <div className="mb-2">
              <label htmlFor="micrCode" className="block text-xs font-medium text-gray-700 mb-1">
                MICR Code
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="micrCode"
                value={bankData.micrCode}
                onChange={handleBankChange}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="expiryDays" className="block text-xs font-medium text-gray-700 mb-1">
                Expiry Days
              </label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="expiryDays"
                value={bankData.expiryDays}
                onChange={handleBankChange}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Home;
