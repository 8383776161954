export const order = [{head:"Order Sync*",name:"orderSync",val:"",type:"option",opt:["Yes","No"]},
{head:"Order Sync From Date",name:"orderSynDate",val:"",type:"text"},
{head:"Sync From Order No*",name:"orderSynNum",val:"",type:"text"},
{head:"Shipping By",name:"shippingBy",val:"",type:"option",opt:["Ship By Marketplace","Ship Not bY Marketplace"]},
{head:"Bill To Party & Its Master*",name:"bill",val:"",type:"option",opt:["Baby & Mom Retail Pvt Ltd","Ship By Marketplace"]},
{head:"Invoice No By",name:"bill",val:"",type:"option",opt:["Self","Third Party"]},
{head:"PrePack Enabled",name:"bill",val:"",type:"option",opt:["Yes","No"]},
{head:"Mark Ready To ship At",name:"bill",val:"",type:"option",opt:["Manifest","Not Manifest"]},
{head:"Each Qty Per Line",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Return Order Sync",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Return Sync From Date",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Use MP Invoice",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Shipping Label",name:"bill",val:"",type:"option",opt:["--Select--","No"]},
{head:"International Label",name:"bill",val:"",type:"option",opt:["--Select--","No"]},
{head:"Domestic Label",name:"bill",val:"",type:"option",opt:["--Select--","No"]},
{head:"Use MP Shipping Label",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Enable Return Push",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Push Item Cancellation",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Update Invoice OM Manifest",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Transporter Wise Manifest",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Enable OTP For C&C",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Is OmniChannel",name:"bill",val:"",type:"option",opt:["No","Yes"]},
];

export const channelsku = [{head:"Channel SKU Suffix",name:"orderSync",val:"",type:"text"},
{head:"Channel SKU Prefix",name:"orderSynDate",val:"",type:"text"},
{head:"SKU Sync",name:"shippingBy",val:"",type:"option",opt:["Pull","Not Pull"]},
{head:"SKU Sync from date",name:"orderSynDate",val:"",type:"text"},
{head:"Sku Create Mode",name:"bill",val:"",type:"option",opt:["Self","Third Party"]},
];

export const channelone=[{head:"Channel Code",name:"orderSync",val:"",type:"text"},
{head:"Channel Name*",name:"orderSync",val:"",type:"text"},
{head:"State",name:"bill",val:"",type:"option",opt:["Active","Deactive"]},
{head:"Order Fulfillment WH*",name:"bill",val:"",type:"option",opt:["Baby & Mom Retail Pvt Ltd.","Ship Not bY Marketplace"]},
{head:"Auto Range SKU",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Configured",name:"bill",val:"",type:"link",opt:["No","Yes"]},
{head:"Channel SLA(in hrs) *",name:"bill",val:"",type:"text"},
{head:"Customer",name:"bill",val:"",type:"text",opt:["--Select--","Third Party"]},
];

export const topcontent={heading:"Channel Type",subhead:"One MG",status:true};

export const inventory=[{head:"Inventory",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Safety Stock",name:"orderSync",val:"",type:"text"},
{head:"Max Allowed Inventory",name:"orderSync",val:"",type:"text"},
{head:"Sync Method",name:"bill",val:"",type:"option",opt:["Custom","Third Party"]},
{head:"Enable Inventory Recon",name:"bill",val:"",type:"option",opt:["Custom","Third Party"]},
{head:"Channel Inventory Percentage",name:"bill",val:"",type:"text"},
{head:"Delicate WH for Inventory",name:"bill",val:"",type:"text"},
{head:"Price Sync",name:"bill",val:"",type:"option",opt:["No","Yes"]},
{head:"Enable Back Order Pull",name:"bill",val:"",type:"option",opt:["No","Yes"]},
];

