import React, { useRef } from 'react'

const SearchPannel = ({chanefilterfield,search}) => {
        const status=["Default","Active","Inactive"]
        const header=[{inputType:"text",head:"Channel Code",name:"channelCode"},
            {inputType:"text",head:"Channel Name",name:"ChName"},
            {inputType:"option",head:"Status",name:"status"},
            {inputType:"text",head:"Registration Date",name:"RegDate"},
            {inputType:"text",head:"Channel Configured",name:"ChConfig"},
        ]

  return (
    <>
    <div className="flex flex-wrap -mx-2" >
    {header.map((item, index)=>
    (item.inputType==="text"?
    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 p-2" key={index}>
    <div className="flex justify-center">
        <div 
        className='px-2'>{item.head}: </div>
        <input  type="text" className='border rounded border-gray-200' onChange={chanefilterfield} name={item.name} value={search[item.name] || ''}/>
    </div>
  </div>
    :
    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 p-2"  key={index}>
    <div className="flex justify-center">
        <div className='px-2'>{item.head}: </div>     
        <select id="importType" className="w-full max-w-52 border border-gray-300 rounded-md shadow-sm" onChange={chanefilterfield} value={search[item.name] || ''} name={item.name}>
        {status.map((stat, idx)=>(<option value={stat} key={idx}>{stat}</option>))}
        </select>
    </div>
  </div>
)
)}
</div>
    </>
  )
}

export default SearchPannel