import React, { useState } from 'react';

const LocationCreate = () => {
  const [locationDetails, setLocationDetails] = useState({
    createPO: '',
    hierarchyDescription: '',
    hierarchyType: '',
    vatRegNo: '',
    cstRegNo: '',
    tinNo: '',
    taxZone: '',
    extLoc: '',
    skuLinkToLocation: '',
    default: '',
    isARSReq: '',
    lastHierarchyCode: '',
    arsRunLoc: '',
    locLiveDate: '',
    digitalSignURL: '',
    digitalSignImage: '',
    gstTinNo: '',
    invoiceReport: '',
    panNumber: '',
    licenseNo: '',
    locationTAT: '',
  });

  const [addressDetails, setAddressDetails] = useState({
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    latitude: '',
    longitude: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    phone: '',
    alternatePhone: '',
    email: '',
    alternateEmail: ''
  });

  const handleLocationChange = (event) => {
    const { id, value } = event.target;
    if (Object.keys(locationDetails).includes(id)) {
      setLocationDetails((prevData) => ({
        ...prevData,
        [id]: value
      }));
    } else if (Object.keys(addressDetails).includes(id)) {
      setAddressDetails((prevData) => ({
        ...prevData,
        [id]: value
      }));
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="border border-slate-800 p-6 rounded-lg relative">
        <h2 className="text-lg absolute -top-4 left-4 bg-white font-semibold mb-6 text-gray-800">Create Location</h2>
        <form>
          {/* Location Details */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            {Object.keys(locationDetails).map((key) => (
              <div className="mb-2" key={key}>
                <label htmlFor={key} className="block text-xs font-medium text-gray-700 mb-1">
                  {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full py-1 px-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id={key}
                  value={locationDetails[key]}
                  onChange={handleLocationChange}
                />
              </div>
            ))}
          </div>
      </form>
      </div>
    <div className='border border-slate-800 p-6 rounded-lg relative  mt-8'>
     <form>
          {/* Address Details */}
          
            <h2 className="text-lg absolute -top-4 left-4 bg-white font-semibold mb-6 text-gray-800">Address Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
              {Object.keys(addressDetails).map((key) => (
                <div className="mb-2" key={key}>
                  <label htmlFor={key} className="block text-xs font-medium text-gray-700 mb-1">
                    {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  </label>
                  <input
                    type={key.includes('email') ? 'email' : 'text'}
                    className="mt-1 block w-full py-1 px-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id={key}
                    value={addressDetails[key]}
                    onChange={handleLocationChange}
                  />
                </div>
              ))}
            </div>
        </form>
        </div>
      </div>
  );
};

export default LocationCreate;
