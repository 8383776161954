import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useExportToExcel from '../../../../../../../hooks/useExportToExcel';
import { tabData } from '../../../../../../../data/verticalTabData';

const PurchOrdTable = ({ data=[], headers=[], name, link=false,id, title }) => {
  const [search, setSearch] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;


  // data.map((item)=>{console.log("item",item[headers[0].key],item,Object.keys(item),typeof item)},tabsData)

  const handleSearchChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });
  };

  const resetSearchChange = () => {
    setSearch({});
  };


  const filteredData = (data || []).filter(item =>
    Object.keys(search).every(key =>
      search[key]
        ? String(item[key]).toLowerCase().includes(search[key].toLowerCase())
        : true
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const uniqueValues = (key) => [...new Set(data.map(item => item[key]))];

  const exportToExcel = useExportToExcel(headers, name, currentItems);

  return (
    <div className="rounded-lg  mt-2">
      <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200 rounded-lg">
        <thead className="bg-slate-600 text-white">
          <tr>
            {headers.map((header) => (
              <th key={header.key} className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr className="bg-indigo-50">
            {headers.map((header) => (
              <td key={header.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {header.inputType=="text" ? (
                  <input
                    type="text"
                    name={header.key}
                    placeholder={`${header.label}`}
                    value={search[header.key] || ''}
                    onChange={handleSearchChange}
                    className="mt-1 p-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                ) :header.inputType=="option"? (
                  <select
                    name={header.key}
                    value={search[header.key] || ''}
                    onChange={handleSearchChange}
                    className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="">All {header.label}</option>
                    {uniqueValues(header.key).map((value) => (
                      <option key={value} value={value}>{value}</option>
                    ))}
                  </select>
                ):
                <div></div>
                }
              </td>
            ))}
          </tr>
          {currentItems.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {link==false?<div>{item[headers[0].key]}</div>:
                <div className='flex justify-between'>
                  <div>
                <Link
                  to={`${link}?${title}code=${item[headers[0].key]}`}
                  className="text-blue-600"
                >
                  {item[headers[0].key]}
                </Link>
                <div className='flex justify-between'>
                  <div className='pr-2 text-stone-400'>PO Qty :</div>
                  <div className='pr-2'>{data[0].poCode.poQty}</div>
                </div>
                <div className='flex justify-between'>
                  <div className='pr-2 text-stone-400'>closed :</div>
                  <div className='pr-2'>{data[0].poCode.closed}</div>
                </div>
                <div className='flex justify-between'>
                  <div className='pr-2 text-stone-400'>ASN Qty :</div>
                  <div className='pr-2'>{data[0].poCode.asnQty}</div>
                </div>
                </div>
                <div>
                  <div>&nbsp;</div>
                <div className='flex justify-between'>
                  <div className='pr-2 text-stone-400'>Pending :</div>
                  <div className='pr-2'>{data[0].poCode.pending}</div>
                </div>
                <div className='flex justify-between'>
                  <div className='pr-2 text-stone-400'>recived :</div>
                  <div className='pr-2'>{data[0].poCode.rcvd}</div>
                </div>
                <div className='flex justify-between'>
                  <div className='pr-2 text-stone-400'>Amount :</div>
                  <div className='pr-2'>{data[0].poCode.amt}</div>
                </div>
                </div>
                </div>}
              </td>
              {headers.slice(1).map((header) => (
                <>
                <td key={header.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {header.key === 'status' ? (
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${item[header.key] === 'Active' ? 'bg-green-700 text-white' : 'bg-red-700 text-white'}`}>
                        {item[header.key]}
                      </span>
                    ) : (
                      item[header.key]
                    )}
                  </td>
                </>
                // <td key={header.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                //     {item[header.key]}
                // </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="flex justify-between items-center py-4">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-sm text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PurchOrdTable;
