import React, { useState } from 'react'
import { OrderFailedShipmentButton } from '../OrderButton';
import { FailedShipmentHeader } from '../../../../../../data/Headers';
import DataTableTwo from '../../../../../common/DataTableTwo';


const FailedShipmentTab = ({maindata}) => {
    const [key, setKey] = useState(0);

  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };

  const FailedShipmentData=maindata.FailedShipmentData;

  return (
    <>
        <OrderFailedShipmentButton resetHandler={resetTable}/>
        <div key={key}>
        <DataTableTwo id='4' headers={FailedShipmentHeader} data={FailedShipmentData} name="Failed Shipment List" title="Failed shipment"  />
        </div>
    </>
  )
}

export default FailedShipmentTab