import React from 'react';
import ImportPageTwo from '../../../../../../common/ImportPageTwo';

const PurchaseOrdImp = ({maindata}) => {
  console.log(maindata.poImport)
  const head=["Seq No","PO Code","Import File Name","SKU","Remarks"]

  return (
    <>
    <ImportPageTwo fileName={"sample.txt"} intialarry={maindata.poImport} head={head}/>
    </>
  );
};

export default PurchaseOrdImp;
