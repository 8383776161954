import React, { useEffect, useState } from 'react'
import { LinkSearchTabButton } from '../ChannelListingButtons';
import { LinkHeaders } from '../../../../../../data/Headers';
import DataTableTwo from '../../../../../common/DataTableTwo';


const intialarry=[
    {id:1,channel:"M06-Tata 1MG",ertailSKU:"",SKUdesc:"",channelSKU:"",prouductId:"",pickInst:""},
    {id:2,channel:"M05-Tata 1MG",ertailSKU:"",SKUdesc:"",channelSKU:"",prouductId:"",pickInst:""}
];

const LinkSearchTab = () => {
  const [key, setKey] = useState(0);   
  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };


  return (
    <>
    <LinkSearchTabButton resetHandler={resetTable}/>
    <div key={key}>
      <DataTableTwo id='6' headers={LinkHeaders} data={intialarry} name="Link Search List" title="Link Search" />
      </div>
    </>
  )
}

export default LinkSearchTab;
