import React, { useState } from 'react'



export const MainCont=({children})=>{
    return (
        <>
        <div className="flex flex-col xl:flex-row xl:items-center mb-2 xl:mb-0 p-2">{children}</div>
        </>
    )
};

export const SvgLogo = () => {
    return (
      <>
      <svg
            className="-mr-1 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
      </>
    )
  }


const SectionView = ({sectionType}) => {
  return (
    <>
    <MainCont>
        <>
        <div className="xl:my-auto">{sectionType.name}</div>
        <div className="relative inline-block text-left xl:ml-5 my-auto">
          
        <button
        type="button"
        className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        id="menu-button"
        aria-expanded={sectionType.togglestat}
        aria-haspopup="true"
        onClick={sectionType.toggle}
      >
        <p>{sectionType.initstate}</p>
        <SvgLogo></SvgLogo>
      </button>

      {sectionType.togglestat && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
          <div className="py-1" role="none">
            {sectionType.arry.map((item) => (
              <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-slate-100" id={item} key={item} onClick={() => { sectionType.fxncall(item); }}>{item}</div>
            ))}
          </div>
        </div>
      )}

        </div>
        </>

  </MainCont>
    </>
  )
}

export default SectionView







