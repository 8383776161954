import React, { useState } from 'react';
import ImageCarousel from './ImageCarousel'; // Adjust the path as needed

const SkuImage = () => {
  const [formData, setFormData] = useState({
    imageUrl: '',
    productPageUrl: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // Handle form submission here
  };

  return (
    <div className="p-6">
      {/* Form Fields */}
      <form onSubmit={handleSubmit} className="mt-6 space-y-4">
        {/* Image URL Field */}
        <div className="mb-4">
          <label htmlFor="imageUrl" className="block text-gray-700 font-medium text-xs mb-2">Image URL</label>
          <input
            type="text"
            id="imageUrl"
            name="imageUrl"
            value={formData.imageUrl}
            onChange={handleChange}
            className="w-full px-1 py-1 text-xs border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Product Page URL Field */}
        <div className="mb-4">
          <label htmlFor="productPageUrl" className="block text-gray-700 font-medium text-xs mb-2">Product Page URL</label>
          <input
            type="text"
            id="productPageUrl"
            name="productPageUrl"
            value={formData.productPageUrl}
            onChange={handleChange}
            className="w-full px-1 py-1 text-xs border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

         {/* Image Carousel */}
         <ImageCarousel />

        {/* Submit Button
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          >
            Submit
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default SkuImage;
