import React, { useState } from 'react'
import { OrderFailedButton } from '../OrderButton'
import DataTableTwo from '../../../../../common/DataTableTwo'
import { FailedOrderHeader } from '../../../../../../data/Headers';




const FailedOrderTab = ({maindata}) => {
    const [key, setKey] = useState(0);

  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };

  return (
    <>
    <OrderFailedButton resetHandler={resetTable}/>
    <div key={key}>
    <DataTableTwo id='2' headers={FailedOrderHeader} data={maindata.failedOrderData} name="Failed Order List" title="Failed Order"  />
    </div>
    </>
  )
}

export default FailedOrderTab