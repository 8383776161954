import { createSlice } from "@reduxjs/toolkit";

const purchaseOrderDetailData={
    "code":"WH1336",
    "poDetail":{
        "poDetails":{
            "motherDOCNo":"",
            "poRevNo":"",
            "poDate":"04/09/2024",
            "eaQty":10000.00,
            "poAmount":424800.00,
            "poTax":64800.00,
            "poDiscount":0.00,
            "landedCost":424800.00,
            "baseAmount":424800.00,
            "baseTaxAmount":64800.00,
            "addCharge":0.00,
            "poCurrency":"Indian Rupee",
            "baseCurrency":"Indian Rupee",
            "conversionRatio":1
        },
        "venDetails":{
            "vendorName":"PASCO ENTERPRISES",
            "venCurrency":"Indian Rupee",
            "creditDays":"0 Days"
        },
        "otherDetails":{
            "otherDetail":"other"
        }
    },
    "vendorCode":"",
    "deliveryLoc":"",
    "poType":"",
    "buyerCode":"",
    "recvValidCode":"",
    "extPONo":"",
    "refNo":"",
    "status":"",
    "expDeliveryDate":"",
    "expDate":"",
    "note":"",
    "addCharge":[{
        "chargeName":"Baby & Moms",
        "type":"real",
        "amount":9000,
        "charge":80
    },{
      "chargeName":"Baby & Moms",
      "type":"real",
      "amount":9000,
      "charge":80
  }
  ],
    "commentHistory":[],
    "poTags":[{
      "poTag":"rare",
      "poStatus":"rare",
      "tagData":"rare",
      "taggedby":"rare"
    }],
    "poInbound":[{
      "inboundNo":"Indus",
      "inboundDate":"Indus",
      "grnNo":"Indus",
      "asnNo":"Indus",
      "grnDate":"Indus",
      "invoiceNo":"Indus",
      "status":"Indus"
    }],
    "tabledata":[
        {
            "poCode":{"code":"NW-5070-P","productName":"NEWISH Pepper Spray 55ml"},
            "image":"https://5.imimg.com/data5/NB/OQ/OJ/SELLER-29327678/self-defense-pepper-spray.jpg",
            "poQty":6000,
            "mrp":245.00,
            "unitCost":36.00,
            "qty":{"poRcvdQty":0,"closedQty":0,"openQty":6000,"asnQty":0},
            "attributes":{"uom":"Each","eaQty":6000,"size":55,"color":"Black"},
            "deliveryDate":"11/09/2024",
            "discount":"",
            "tax":{"taxGroup":"G18.0P","taxAmt":38880},
            "chargeAmt":216000.00
        },
        {
          "poCode":{"code":"NW-5070-P","productName":"NEWISH Pepper Spray 55ml"},
          "image":"https://5.imimg.com/data5/NB/OQ/OJ/SELLER-29327678/self-defense-pepper-spray.jpg",
          "poQty":6000,
          "mrp":245.00,
          "unitCost":36.00,
          "qty":{"poRcvdQty":0,"closedQty":0,"openQty":6000,"asnQty":0},
          "attributes":{"uom":"Each","eaQty":6000,"size":55,"color":"Black"},
          "deliveryDate":"11/09/2024",
          "discount":"",
          "tax":{"taxGroup":"G18.0P","taxAmt":38880},
          "chargeAmt":216000.00
      }
    ],
    "poImport":[{"SeqNo":1,"poCode":"OPRED","ImpName":"sample.txt","SKU":"MH0876","Remarks":"Weird"}]
 }

const initialState = {
  data: purchaseOrderDetailData,
  
  loading: false,
};

const purchaseOrderDetailSlice = createSlice({
   name: "purchaseOrderDetail",
   initialState: initialState,
   reducers: {
     setPurchaseOrderDetail(state, value) {
       state.user = value.payload;
     },
     setPurchaseOrderDetailLoading(state, value) {
       state.loading = value.payload;
     },
   },
});
const purchaseOrderDetailSliceReducer=purchaseOrderDetailSlice.reducer;
export const { setPurchaseOrderDetail, setPurchaseOrderDetailLoading} = purchaseOrderDetailSlice.actions;
export default purchaseOrderDetailSliceReducer;

