import { createSlice } from "@reduxjs/toolkit";

const orderEnquiriesData={
    "orderEnquireyData":[
        {
    "orderNo":112323,
    "extOrderNo":"gfg",
    "channel":"lkjh",
    "orderType":"kjhuytrrjklkjhgfdsdfghjhgfds",
    "orderDate":"kjh",
    "status":"poiu",
    "orderAmount":"jh",
    "shipByDate":"dfg",
    "customer":"sdf",
    "deliverySlot":"dfg",
    "orderTag":"dfgh",
    "shipDate":"cvb",
    "onhold":"cvb",
    "verificationStatus":"fgh",
    "orderFullfillment":"kjh",
    "orderCurrency":"kj",
    "deliveryDate":"lkj",
    "externalStatus":"kj",
    "subOrderId":"lkjh",
    "orderSource":"oihg"
    },
         {
    "orderNo":112454,
    "extOrderNo":"gfg",
    "channel":"lkjh",
    "orderType":"kjhuytrrjklkjhgfdsdfghjhgfds",
    "orderDate":"kjh",
    "status":"Active",
    "orderAmount":"jh",
    "shipByDate":"dfg",
    "customer":"sdf",
    "deliverySlot":"dfg",
    "orderTag":"dfgh",
    "shipDate":"cvb",
    "onhold":"cvb",
    "verificationStatus":"fgh",
    "orderFullfillment":"kjh",
    "orderCurrency":"kj",
    "deliveryDate":"lkj",
    "externalStatus":"kj",
    "subOrderId":"lkjh",
    "orderSource":"oihg"
    }
    ],
    "failedOrderData":[
        {"orderNo":123,"channelOrdId":"yuh","channel":"No","createDate":"hggyww","remarks":"ok","issueDes":"no"},
        {"orderNo":345,"channelOrdId":"ooiui","channel":"Yes","createDate":"iuyr","remarks":"too","issueDes":"yes"}
    ],
    "cancelOrderData":[
        {"channel":"w433we","OrderNo":"","extOrdNo":"","pickList":"","skuCode":"","unallocationD":"","bigEnquiry":"","previousSatus":"","sourceWH":"","frmLPN":"","toLPN":"","pigeonholeID":""},
        {"channel":"","OrderNo":"","extOrdNo":"","pickList":"","skuCode":"","unallocationD":"","bigEnquiry":"","previousSatus":"","sourceWH":"","frmLPN":"","toLPN":"","pigeonholeID":""},
    ],
    "FailedShipmentData":[
        {"type":"gouh","channel":"","status":"","extOrdNo":"","orderNo":"","deliveryNo":"","packDate":"","updatedDate":"","errorDesc":""},
        {"type":"","channel":"","status":"","extOrdNo":"","orderNo":"","deliveryNo":"","packDate":"","updatedDate":"","errorDesc":""}
    ]
}

const initialState = {
  data: orderEnquiriesData,  
  loading: false,
};

const orderEnquiriesSlice = createSlice({
   name: "orderEnquiries",
   initialState: initialState,
   reducers: {
     setOrderEnquiries(state, value) {
       state.user = value.payload;
     },
     setOrderEnquiriesLoading(state, value) {
       state.loading = value.payload;
     },
   },
});
const orderEnquiriesSliceReducer=orderEnquiriesSlice.reducer;
export const { setOrderEnquiries, setOrderEnquiriesLoading} = orderEnquiriesSlice.actions;
export default orderEnquiriesSliceReducer;
