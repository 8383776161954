import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { VscCopy } from "react-icons/vsc";
import { IoSaveOutline } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import ModalPopup from './ModalPopup';
import { SlQuestion } from "react-icons/sl";
import { AiOutlineAudit } from "react-icons/ai";
import { channelone, channelsku, inventory, order, topcontent } from './DataDisplayContent';
import DataDispaly from './DataDisplay';




const ButtonBar=()=>{
  return (
    <>
    <div className='flex justify-end p-2 mb-2'>
    <button type="button" className="relative flex rounded-md bg-gray-700 px-3 py-2 text-sm font-medium text-white m-2 hover:bg-gray-500">
    <VscCopy className='my-auto text-lg font-large'/>
    &nbsp; Copy Channel Sku Mapping
        </button>
        <button type="button" className="relative flex rounded-md bg-gray-700 px-3 py-2 text-sm font-medium text-white m-2 hover:bg-gray-500">
        <VscCopy className='my-auto text-lg font-large'/>
        &nbsp; Copy Channel
        </button>
        <button type="button" className="relative flex rounded-md bg-gray-700 px-3 py-2 text-sm font-medium text-white m-2 hover:bg-gray-500">
        <IoSaveOutline className='my-auto text-lg font-large'/>
        &nbsp; Save
        </button>
        <button type="button" className="relative flex rounded-md bg-gray-300 px-3 py-2 text-sm font-medium m-2 hover:bg-slate-400">
        <GoPlusCircle className='my-auto text-lg font-large'/>
        &nbsp; Add New
        </button>
        <button type="button" className="relative flex rounded-md bg-gray-300 px-3 py-2 text-sm font-medium m-2 hover:bg-slate-400">
        <AiOutlineAudit  className='my-auto text-lg font-large'/>
        &nbsp; Audit
        </button>
        <button type="button" className="relative flex rounded-md  px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <SlQuestion className='my-auto text-lg font-large text-cyan-500'/>
          </button>
    </div>
    </>
  )
};

const ChannelPage = () => {
  const [channelbox, setchannelbox]=useState(true);
  const [orderbox,setorderbox]=useState(true);
  const [channelskubox,setchannelskubox]=useState(true);
  const [inventorybox,setinventorybox]=useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  
  const tooglechannelbox=()=>{setchannelbox(!channelbox)}
  const toogleorderbox=()=>{setorderbox(!orderbox)}
  const tooglechannelskubox=()=>{setchannelskubox(!channelskubox)}
  const toogleinventorybox=()=>{setinventorybox(!inventorybox)}

  // Close modal when clicking outside of the modal content
  const handleOutsideClick = (e) => {
    if (e.target.className === 'modal') {
      closeModal();
    }
  };

  const isOpen="ok"


  return (
    <>
      
      <div className='bg-white p-2 shadow-sm m-2'>
      <ButtonBar/>
      <div className='bg-slate-600 text-white shadow-sm p-2'>
            <strong>Channel Maintance</strong>      
      </div>
      <DataDispaly isOpen={channelbox} tooglebox={tooglechannelbox} topcontent={topcontent} mainhead="Channel Detail" content={channelone} openModal={openModal}/>
      <DataDispaly isOpen={orderbox} tooglebox={toogleorderbox} mainhead="Order" content={order}/>
      <DataDispaly isOpen={channelskubox} tooglebox={tooglechannelskubox} mainhead="SKU Channel" content={channelsku}/>
      <DataDispaly isOpen={inventorybox} tooglebox={toogleinventorybox} mainhead="Inventory" content={inventory}/>
      </div>
      <div>




{/* The Modal */}
{isModalOpen && (
  <div className="modal" onClick={handleOutsideClick}>
    {/* Modal content */}
    <div className="modal-content">
      <span className="close" onClick={closeModal}>&times;</span>
      <ModalPopup closeModal={closeModal}></ModalPopup>
    </div>
  </div>
)}
      </div>
    </>
  )
}

export default ChannelPage