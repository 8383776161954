import React, { useState } from 'react';

const SellerDetails = () => {
  const [formData, setFormData] = useState({
    defaultDeliveryMode: '',
    invoiceBy: '',
    deliveryModeUpdationAllowed: false,
    fulfillmentCharges: '',
    paymentCharges: '',
    courierCharges: '',
    returnAcceptanceDays: 30,
    returnLocationType: '',
    digitalSignURL: '',
    slaInHours: '',
    digitalSignImage: '',
    timezone: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: files[0]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach(key => {
      if (formData[key] instanceof File) {
        formDataToSubmit.append(key, formData[key]);
      } else {
        formDataToSubmit.append(key, formData[key]);
      }
    });

    fetch('https://api.example.com/submit-seller-details', {
      method: 'POST',
      body: formDataToSubmit,
    })
      .then(response => {
        if (response.ok) {
          console.log('Seller details submitted successfully');
          setFormData({
            defaultDeliveryMode: '',
            invoiceBy: '',
            deliveryModeUpdationAllowed: false,
            fulfillmentCharges: '',
            paymentCharges: '',
            courierCharges: '',
            returnAcceptanceDays: 30,
            returnLocationType: '',
            digitalSignURL: '',
            slaInHours: '',
            digitalSignImage: '',
            timezone: ''
          });
        } else {
          console.error('Failed to submit seller details');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="container mx-auto mt-6 p-6 border border-slate-900 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-900">Seller Details Form</h2>
      <form onSubmit={handleSubmit} className="grid text-xs grid-cols-1 md:grid-cols-3 gap-6">
        {/* Default Delivery Mode */}
        <div className="col-span-1">
          <label htmlFor="defaultDeliveryMode" className="block text-gray-700 font-medium mb-2">Default Delivery Mode*</label>
          <select
            id="defaultDeliveryMode"
            name="defaultDeliveryMode"
            value={formData.defaultDeliveryMode}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">--- Select ---</option>
            <option value="standard">Standard</option>
            <option value="express">Express</option>
            <option value="same-day">Same Day</option>
          </select>
        </div>

        {/* Invoice By */}
        <div className="col-span-1">
          <label htmlFor="invoiceBy" className="block text-gray-700 font-medium mb-2">Invoice By*</label>
          <select
            id="invoiceBy"
            name="invoiceBy"
            value={formData.invoiceBy}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">--- Select ---</option>
            <option value="seller">Seller</option>
            <option value="third-party">Third Party</option>
          </select>
        </div>

        {/* Delivery Mode Updation Allowed */}
        <div className="col-span-1 flex items-center">
          <input
            type="checkbox"
            id="deliveryModeUpdationAllowed"
            name="deliveryModeUpdationAllowed"
            checked={formData.deliveryModeUpdationAllowed}
            onChange={handleChange}
            className="mr-2"
          />
          <label htmlFor="deliveryModeUpdationAllowed" className="text-gray-700">Delivery Mode Updation Allowed</label>
        </div>

        {/* Fulfillment Charges */}
        <div className="col-span-1">
          <label htmlFor="fulfillmentCharges" className="block text-gray-700 font-medium mb-2">Fulfillment Charges</label>
          <input
            type="number"
            id="fulfillmentCharges"
            name="fulfillmentCharges"
            value={formData.fulfillmentCharges}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Payment Charges */}
        <div className="col-span-1">
          <label htmlFor="paymentCharges" className="block text-gray-700 font-medium mb-2">Payment Charges</label>
          <input
            type="number"
            id="paymentCharges"
            name="paymentCharges"
            value={formData.paymentCharges}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Courier Charges */}
        <div className="col-span-1">
          <label htmlFor="courierCharges" className="block text-gray-700 font-medium mb-2">Courier Charges</label>
          <input
            type="number"
            id="courierCharges"
            name="courierCharges"
            value={formData.courierCharges}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Return Acceptance Days */}
        <div className="col-span-1">
          <label htmlFor="returnAcceptanceDays" className="block text-gray-700 font-medium mb-2">Return Acceptance Days</label>
          <input
            type="number"
            id="returnAcceptanceDays"
            name="returnAcceptanceDays"
            value={formData.returnAcceptanceDays}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Return Location Type */}
        <div className="col-span-1">
          <label htmlFor="returnLocationType" className="block text-gray-700 font-medium mb-2">Return Location Type</label>
          <select
            id="returnLocationType"
            name="returnLocationType"
            value={formData.returnLocationType}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">--- Select ---</option>
            <option value="warehouse">Warehouse</option>
            <option value="store">Store</option>
          </select>
        </div>

        {/* Digital Sign URL */}
        <div className="col-span-1">
          <label htmlFor="digitalSignURL" className="block text-gray-700 font-medium mb-2">Digital Sign URL</label>
          <input
            type="url"
            id="digitalSignURL"
            name="digitalSignURL"
            value={formData.digitalSignURL}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* SLA (In Hours) */}
        <div className="col-span-1">
          <label htmlFor="slaInHours" className="block text-gray-700 font-medium mb-2">SLA (In Hours)</label>
          <input
            type="number"
            id="slaInHours"
            name="slaInHours"
            value={formData.slaInHours}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Digital Sign Image */}
        <div className="col-span-1">
          <label htmlFor="digitalSignImage" className="block text-gray-700 font-medium mb-2">Digital Sign Image</label>
          <input
            type="file"
            id="digitalSignImage"
            name="digitalSignImage"
            onChange={handleFileChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Timezone */}
        <div className="col-span-1">
          <label htmlFor="timezone" className="block text-gray-700 font-medium mb-2">Timezone</label>
          <select
            id="timezone"
            name="timezone"
            value={formData.timezone}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">--- Select ---</option>
            <option value="utc">UTC</option>
            <option value="pst">PST</option>
            <option value="est">EST</option>
            <option value="cst">CST</option>
          </select>
        </div>
      </form>
    </div>
  );
};

export default SellerDetails;
