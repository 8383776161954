import React, { useState } from 'react';

const TransporterDetails = () => {
  const [formData, setFormData] = useState({
    transporterType: '',
    transporterCode: '',
    transporterName: '',
    companyName: '',
    glCode: '',
    validateServiceLoc: false,
    sendShipMailId: false,
    reverseValidateServiceLoc: false,
    deliveryMode: '',
    uploadAWBSeries: false,
    isActive: false,
    trackingHour: '',
    dimensionalFactor: '',
    transType: '',
    otpFlag: false,
    secureShippingFlag: false,
    gstin: '',
    minWeight: '',
    maxWeight: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    phone: '',
    alternatePhone: '',
    fax: '',
    email: '',
    alternateEmail: '',
    country: 'India',
    state: '',
    city: '',
    pincode: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add your form submission logic here
  };

  return (
    <div className="container mx-auto mt-6 p-6 rounded-lg">
      <h2 className="text-xl font-bold mb-6 text-gray-900">Transporter Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 text-xs md:grid-cols-3 gap-1">
          <div>
            <label className="block text-gray-700 font-medium mb-2">Transporter Type*</label>
            <select
              name="transporterType"
              value={formData.transporterType}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">--- Select ---</option>
              {/* Add options here */}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Transporter Code*</label>
            <input
              type="text"
              name="transporterCode"
              value={formData.transporterCode}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Transporter Name*</label>
            <input
              type="text"
              name="transporterName"
              value={formData.transporterName}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Company Name</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">GL Code</label>
            <input
              type="text"
              name="glCode"
              value={formData.glCode}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="validateServiceLoc"
              checked={formData.validateServiceLoc}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700 font-medium">Validate Service Loc</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="sendShipMailId"
              checked={formData.sendShipMailId}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700 font-medium">Send Ship Mail Id</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="reverseValidateServiceLoc"
              checked={formData.reverseValidateServiceLoc}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700 font-medium">Reverse Validate Service Loc</label>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Delivery Mode</label>
            <select
              name="deliveryMode"
              value={formData.deliveryMode}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">--- Select ---</option>
              {/* Add options here */}
            </select>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="uploadAWBSeries"
              checked={formData.uploadAWBSeries}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700 font-medium">Upload AWB series</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="isActive"
              checked={formData.isActive}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700 font-medium">Is Active</label>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Tracking Hour</label>
            <input
              type="text"
              name="trackingHour"
              value={formData.trackingHour}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Dimensional Factor</label>
            <input
              type="text"
              name="dimensionalFactor"
              value={formData.dimensionalFactor}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">TransType</label>
            <input
              type="text"
              name="transType"
              value={formData.transType}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="otpFlag"
              checked={formData.otpFlag}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700 font-medium">OTP Flag</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="secureShippingFlag"
              checked={formData.secureShippingFlag}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-gray-700 font-medium">Secure Shipping Flag</label>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">GSTIN/CIN</label>
            <input
              type="text"
              name="gstin"
              value={formData.gstin}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Min Weight (KG)</label>
            <input
              type="text"
              name="minWeight"
              value={formData.minWeight}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Max Weight (KG)</label>
            <input
              type="text"
              name="maxWeight"
              value={formData.maxWeight}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className='border relative border-gray-900 rounded-lg p-6 text-xs mt-8 grid md:grid-cols-3 grid-cols-1 gap-6'>
        <h3 className="text-lg absolute -top-4 left-4 bg-white mb-4">Contact Details</h3>  
          <div>
            <label className="block text-gray-700 font-medium mb-2">Address 1*</label>
            <input
              type="text"
              name="address1"
              value={formData.address1}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Address 2*</label>
            <input
              type="text"
              name="address2"
              value={formData.address2}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Address 3</label>
            <input
              type="text"
              name="address3"
              value={formData.address3}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Address 4</label>
            <input
              type="text"
              name="address4"
              value={formData.address4}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Phone</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Alternate Phone</label>
            <input
              type="text"
              name="alternatePhone"
              value={formData.alternatePhone}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Fax</label>
            <input
              type="text"
              name="fax"
              value={formData.fax}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Alternate Email</label>
            <input
              type="email"
              name="alternateEmail"
              value={formData.alternateEmail}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Country*</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              readOnly
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">State*</label>
            <select
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">--- Select State ---</option>
              {/* Add state options here */}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Pincode</label>
            <input
              type="text"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          </div>
      </form>
    </div>
  );
};

export default TransporterDetails;
