
import React, {useState } from "react";
import ExcelJS from 'exceljs';
import SectionView, { MainCont } from "./SectionView";


const exportToExcel = async (selectedHeaders, selectedImportName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Template');

  // Add header row with selected headers
  const headerRow = worksheet.addRow(selectedHeaders);

  // Style the header row
  headerRow.eachCell((cell) => {
    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text color
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF4F81BD' }, // Dark blue background color
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  // Adjust column widths based on headers
  worksheet.columns = selectedHeaders.map((header) => ({
    header,
    width: header.length < 12 ? 12 : header.length + 2, // Adjust the width
  }));

  // Generate Excel file buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Create a blob and trigger a download
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${selectedImportName}_template.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
};


const ImportPage = () => {
    const [dropdownImpOpen, setDropdownImpOpen] = useState(false);
    const [dropdownAttOpen, setDropdownAttOpen] = useState(false);
    const [importType, setImportType] = useState(["--select--","Count update","Account settings", "Support", "License"]);
    const [attributeType, setAttributeType] = useState(["Default"]);
    const [selectedImpValue, setSelectedImpValue] = useState("Count update");
    const [selectedAttValue, setSelectedAttValue] = useState("Default");
   
    const toggleImpDropdown = () => {
      setDropdownImpOpen(!dropdownImpOpen);
    };
  
    const toggleAttDropdown = () => {
      setDropdownAttOpen(!dropdownAttOpen);
    };
  
    const optionImpSelect = (para) => {
      toggleImpDropdown();
      if (para!="--select--"){
        setSelectedImpValue(para);
      }
    };
  
    const optionAttSelect = (para) => {
      toggleAttDropdown();
      if (para!="--select--"){
        setSelectedAttValue(para);
      }
    };
  
    const uploadFileHandler = (e) => {
      console.log("File is uploading");
      const file = e.target.files[0];
      if (file) {
        console.log(file);
      } else {
        console.error("No file selected");
      }
    };
  
    const sectionForImportType={id:1,name:"Import Type",    arry:importType,   fxncall:optionImpSelect,toggle:toggleImpDropdown,togglestat:dropdownImpOpen, initstate:selectedImpValue}
    const sectionForApptributeType={id:2,name:"Attribute Set *",arry:attributeType,fxncall:optionAttSelect,toggle:toggleAttDropdown,togglestat:dropdownAttOpen, initstate:selectedAttValue}


    const downloadFileHandler = () => {
      const headers = {
        Countupdate: ['Vendor Code', 'Vendor Name', 'Address'], // Example headers for Vendor Import
        Accountsettings: ['Customer Code', 'Customer Name', 'Contact'], // Example headers for Customer Import
        Support: ['Transporter Code', 'Transporter Name', 'Service'], // Example headers for Transporter Import
        License: ['Client Code', 'Client Name', 'Contact Person'], // Example headers for Client Import
      };
      if (selectedImpValue===null && selectedImpValue==="--select--"){
        alert("select field")
      } else {
      let fileinfo=selectedImpValue.replace(/ /g, "");
      const selectedHeaders = headers[fileinfo] || [];
      exportToExcel(selectedHeaders, fileinfo);
    }
    };
    
  
  return (
    <>
    <div className="p-2 w-full">
    <div className=" rounded border-2 border-gray-400 pt-2 pr-1 pl-1 ">
    <div className="flex flex-col xl:flex-row justify-around">
    <SectionView sectionType={sectionForImportType}></SectionView>
    <SectionView sectionType={sectionForApptributeType}></SectionView>
    <MainCont>
    <div className="xl:my-auto">Update Template</div>
      <div className="relative inline-block text-left xl:ml-5 my-auto">
        <input type="file" onChange={uploadFileHandler}/>
      </div>
    </MainCont>
    <MainCont>
    <div>
    <button
    onClick={downloadFileHandler}
    className="bg-slate-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
  >
    Download Template
  </button>
  </div>
    </MainCont>
  
  </div>
  <p className="mt-4 text-gray-600">
  <strong>Note:</strong> Maximum rows can be imported in an attempt of Import: 10000
  </p>
  </div>
  </div>
    </>
  )
}

export default ImportPage
