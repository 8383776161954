import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  merchHierarchyType: '',
  merchCode: '',
  extMerchCode: '',
  merchName: '',
  merchDesc: '',
  parentMerchCode: '',
  parentMerchDesc: '',
  parentMerchType: '',
  isEdit: false,
  loading: false,
};

const merchSlice = createSlice({
  name: 'merch',
  initialState,
  reducers: {
    setNodeData: (state, action) => {
      const {
        merchHierarchyType,
        merchCode,
        extMerchCode,
        merchName,
        merchDesc,
        parentMerchCode,
        parentMerchDesc,
        parentMerchType,
      } = action.payload;
      state.merchHierarchyType = merchHierarchyType || '';
      state.merchCode = merchCode || '';
      state.extMerchCode = extMerchCode || '';
      state.merchName = merchName || '';
      state.merchDesc = merchDesc || '';
      state.parentMerchCode = parentMerchCode || '';
      state.parentMerchDesc = parentMerchDesc || '';
      state.parentMerchType = parentMerchType || '';
    },
    setEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const { setNodeData, setEdit, setLoading, reset } = merchSlice.actions;
export default merchSlice.reducer;
