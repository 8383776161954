import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { paymentTermPoints } from "../apis";
const { CREATE_PAYMENT_TERMS,UPDATE_PAYMENT_TERMS } = paymentTermPoints;

export const createPaymentTerms = async (data, token) => {
    let result = null
    const toastId = toast.loading("Loading...")
    const formattedData = {
      id: data.id,
      name: `${data.paymentTerms}`,  // Using template literals for string concatenation
      days: data.paymentTerms,
    };
    
    try {
      const response = await apiConnector("POST", CREATE_PAYMENT_TERMS,formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      })
      console.log("CREATE Payment Term............", response)
       if (!response?.data?.success) {
         throw new Error("Payment Term could not be created");
       }
     toast.success(`${response?.data?.message}`);
    } catch (error) {
      console.log("CREATE Payment Term API ERROR............", error)
      toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result
  }
  
  export const updatePaymentTerms = async (id, data, token) => {
    let result = null;
    const toastId = toast.loading("Loading...");
    const formattedData = {
      id: data.id,
      name: `${data.paymentTerms}`,  // Using template literals for string concatenation
      days: data.paymentTerms,
    };
    console.log(formattedData); // For debugging
    try {
      const response = await apiConnector("PUT", `${UPDATE_PAYMENT_TERMS}/${id}`, formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      });
  
      console.log("UPDATE Payment Term RESPONSE............", response);
       if (!response?.data?.success) {
         throw new Error("Payment Term could not be updated");
       }
      toast.success(`${response?.data?.message}`);
    } catch (error) {
      console.log("UPDATE Payment Term API ERROR............", error);
      toast.error(error.message);
    }
  
    toast.dismiss(toastId);
    return result;
  };