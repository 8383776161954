import React, { useState } from 'react';

const CustomerForm = () => {
  const [formData, setFormData] = useState({
    customerCode: '',
    customerName: '',
    isActive: '',
    parentCustomer: '',
    extCustomerCode: '',
    creationSource: '',
    paymentTerms: '',
    gstinTin: '',
    glCode: '',
    taxZone: '',
    type: '',
    isFormC: '',
    panNo: '',
    taxRefNo: '',
    creditDays: '',
    earnedPoints: '',
    expiryDate: '',
    isTaxExempt: '',
    isTcsApplicable: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  const parentCustomers = [
    { value: '', label: '--- Select ---' },
    { value: 'parent1', label: 'Parent 1' },
    { value: 'parent2', label: 'Parent 2' },
    // Add more options as needed
  ];

  const selectOptions = [
    { value: '', label: '--- Select ---' },
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    // Add more options as needed
  ];

  return (
    <div className="container mx-auto relative mt-6 p-4 border border-gray-900 rounded-lg">
      <form onSubmit={handleSubmit} className="grid text-xs grid-cols-1 md:grid-cols-3 gap-1">
        <div>
          <label className="block text-gray-700 font-medium mb-2">Customer Code</label>
          <input
            type="text"
            name="customerCode"
            value={formData.customerCode}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Customer Name*</label>
          <input
            type="text"
            name="customerName"
            value={formData.customerName}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Is Active</label>
          <div className="flex items-center">
            <input
              type="radio"
              name="isActive"
              value="yes"
              checked={formData.isActive === 'yes'}
              onChange={handleChange}
              className="mr-2"
            /> Yes
            <input
              type="radio"
              name="isActive"
              value="no"
              checked={formData.isActive === 'no'}
              onChange={handleChange}
              className="ml-4 mr-2"
            /> No
          </div>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Parent Customer</label>
          <select
            name="parentCustomer"
            value={formData.parentCustomer}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {parentCustomers.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Ext Customer Code</label>
          <input
            type="text"
            name="extCustomerCode"
            value={formData.extCustomerCode}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Creation Source</label>
          <input
            type="text"
            name="creationSource"
            value={formData.creationSource}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Payment Terms</label>
          <select
            name="paymentTerms"
            value={formData.paymentTerms}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">GSTIN/TIN</label>
          <input
            type="text"
            name="gstinTin"
            value={formData.gstinTin}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">GL Code</label>
          <input
            type="text"
            name="glCode"
            value={formData.glCode}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Tax Zone</label>
          <select
            name="taxZone"
            value={formData.taxZone}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Type*</label>
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Is Form C</label>
          <div className="flex items-center">
            <input
              type="radio"
              name="isFormC"
              value="yes"
              checked={formData.isFormC === 'yes'}
              onChange={handleChange}
              className="mr-2"
            /> Yes
            <input
              type="radio"
              name="isFormC"
              value="no"
              checked={formData.isFormC === 'no'}
              onChange={handleChange}
              className="ml-4 mr-2"
            /> No
          </div>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">PAN No.</label>
          <input
            type="text"
            name="panNo"
            value={formData.panNo}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Tax Ref No</label>
          <input
            type="text"
            name="taxRefNo"
            value={formData.taxRefNo}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Credit Days</label>
          <input
            type="number"
            name="creditDays"
            value={formData.creditDays}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Earned Points</label>
          <input
            type="number"
            name="earnedPoints"
            value={formData.earnedPoints}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Expiry Date</label>
          <input
            type="date"
            name="expiryDate"
            value={formData.expiryDate}
            onChange={handleChange}
            className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Is Tax Exempt</label>
          <div className="flex items-center">
            <input
              type="radio"
              name="isTaxExempt"
              value="yes"
              checked={formData.isTaxExempt === 'yes'}
              onChange={handleChange}
              className="mr-2"
            /> Yes
            <input
              type="radio"
              name="isTaxExempt"
              value="no"
              checked={formData.isTaxExempt === 'no'}
              onChange={handleChange}
              className="ml-4 mr-2"
            /> No
          </div>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">Is TCS Applicable</label>
          <div className="flex items-center">
            <input
              type="radio"
              name="isTcsApplicable"
              value="yes"
              checked={formData.isTcsApplicable === 'yes'}
              onChange={handleChange}
              className="mr-2"
            /> Yes
            <input
              type="radio"
              name="isTcsApplicable"
              value="no"
              checked={formData.isTcsApplicable === 'no'}
              onChange={handleChange}
              className="ml-4 mr-2"
            /> No
          </div>
        </div>
      </form>
    </div>
  );
};

export default CustomerForm;
