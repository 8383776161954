import React from 'react';
// min-h-screen max-w-3xl 
function ModalPopup({closeModal}) {
  return (
    <>

      <div className="w-1/2 mx-auto bg-white p-6 rounded-lg shadow-lg"> 
        <h2 className="text-xl font-semibold mb-4 p-2 bg-gray-700 text-white">Channel Configure</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">AWS Access Key Id</label>
            <input 
              type="password" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">AWS Secret Access Key Id</label>
            <input 
              type="password" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">AWS IAM Role ARN</label>
            <input 
              type="password" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Connector Name</label>
            <input 
              type="text" 
              value="Vinculum" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Location Id/Store Id</label>
            <input 
              type="text" 
              value="762bbed2-a10e-47e8-xxxx" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Client Id</label>
            <input 
              type="password" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Refresh Token</label>
            <input 
              type="password" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">MWS MerchantId</label>
            <input 
              type="text" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Channel Name</label>
            <input 
              type="text" 
              value="FBA" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">AWS KMS Key ARN</label>
            <input 
              type="password" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Marketplace Name</label>
            <input 
              type="text" 
              value="AMAZON_IN" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Client Secret</label>
            <input 
              type="password" 
              className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Pull 4 x 6 Label</label>
            <select className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end mt-6 space-x-4" >
          <button className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-500" onClick={closeModal}>OK</button>
        </div>
      </div>

    </>
  );
}

export default ModalPopup;
