import React, { useState } from 'react';
import { stateTypeHeaders } from '../../../../data/Headers';
import DataTableModal from '../../../common/DataTableModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { statePoints,countryPoints } from '../../../../services/apis';
import { apiConnector } from '../../../../services/apiconnector';
import { useSelector } from 'react-redux';
import { createState,updateState } from '../../../../services/operations/stateApi';
import Loading from '../../../common/Loading';


// Fetch Country function
const fetchCountry = async (token) => {
  const response = await apiConnector('GET', countryPoints.SHOW_ALL_COUNTRY, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data = response.data.data;
  return data.map(item => ({
    id: item.id,
    country: item.name,
  }));
};

// Fetch States function
const fetchState = async (token) => {
  const response = await apiConnector('GET', statePoints.SHOW_ALL_STATES, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data = response.data.data;
  console.log('State',data);
  return data.map(item => ({
    id: item.id,
    state: item.name,
    country: item.country_id,
  }));
};

const State = () => {
  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();

  // Query for country data
  const { data: countryData, error: countryError, isLoading: isCountryLoading } = useQuery({
    queryKey: ['country', token],
    queryFn: () => fetchCountry(token),
    enabled: !!token,
  });

  // Query for state data
  const { data: stateData, error: stateError, isLoading: isStateLoading } = useQuery({
    queryKey: ['state', token],
    queryFn: () => fetchState(token),
    enabled: !!token,
  });

  // Handle loading and error states
  if (isCountryLoading || isStateLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading /></div>;
  }

  if (countryError || stateError) {
    return <div>Error: {countryError?.message || stateError?.message}</div>;
  }

  // Handle adding new state
  const handleAddNew = async (newData) => {
    await createState(newData, token);
    queryClient.invalidateQueries(['state']); 
  };

  // Handle updating state
  const handleUpdate = async (id, updatedData) => {
    await updateState(id, updatedData, token);
    queryClient.invalidateQueries(['state']);
  };

  // Update stateHeaders with country options
  const updatedStateHeaders = stateTypeHeaders.map(header => 
    header.key === 'country'
      ? { ...header, options: countryData.map(option => ({ label: option.country, value: option.id })) }
      : header
  );

  return (
    <div className="rounded-lg p-4 mt-2 min-h-screen">
      <DataTableModal 
        id='4' 
        isAddEnabled={true} 
        headers={updatedStateHeaders} 
        data={stateData}  
        title="New" 
        name="state"
        onAddNew={handleAddNew} 
        onUpdate={handleUpdate} 
      />
    </div>
  );
};

export default State;
