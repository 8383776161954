import React from 'react'

const AdditionalChargeTab = ({maindata}) => {
  
  const data=[{head:"Charge Name",result:maindata.addCharge.chargeName},{head:"Type",result:maindata.addCharge.type},{head:"Amount",result:maindata.addCharge.amount},{head:"Charge",result:maindata.addCharge.charge}]

  return (
    <>
    <div className=' w-full'>
    <div className="oferflow w-full mt-2">
    <table className="w-full">
        <thead className="bg-slate-600 text-white">        
            <tr>
            {data.map((item,index)=>(
              <th className="px-6 py-4 whitespace-nowrap text-sm" key={index}>{item.head}</th>
            ))}
            </tr> 
        </thead>
        <tbody className="divide-y divide-gray-200">
        
              {maindata.addCharge.map((item,index)=>(   
                <tr className="hover:bg-gray-100" key={index}>             
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.chargeName}</td>      
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.type}</td>   
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.amount}</td>   
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.charge}</td>    
                  </tr>         
              ))}
               
        </tbody>
      </table>
      </div>
    </div>
    </>
  )
}

export default AdditionalChargeTab