import React, {useEffect, useState } from 'react'
import { OrderAllButton } from '../../OrderButton';
import DataTableTwo from '../../../../../../common/DataTableTwo';
import { orderEnquiryHeaders } from '../../../../../../../data/Headers';
import { useSelector, useDispatch } from "react-redux";
import { setOrderEnquiriesLoading } from '../../../../../../../slice/orderEnquiresSlice';
// import { setOrderEnquiry, setOrderEnquiryLoading } from '../../../../../../../slice/orderEnquirySlice';




const OrderAllTable = ({maindata}) => {
  const [key, setKey] = useState(0);
  const loading = useSelector((state) => state.orderEnquiries.loading);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setOrderEnquiriesLoading(true));

    
    setTimeout(() => {
      dispatch(setOrderEnquiriesLoading(false));
    }, 1000);
  }, [dispatch]);

  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

      const orderEnquireyData=maindata.orderEnquireyData;
    
  return (
    <>
      <OrderAllButton  resetHandler={resetTable}/>
      <div key={key}>
      <DataTableTwo id='1' headers={orderEnquiryHeaders} data={orderEnquireyData} name="Order Enquirey List" title="Order Enquirey" link={`/sales/order-enquiry/orderdetail`} />
      </div>
    </>
  )
}

export default OrderAllTable