
import React, { useRef, useState } from "react";
import ExcelJS from 'exceljs';
import { Link, Navigate } from "react-router-dom";


const TableView = ({tablearray}) => {

  return (
    <>
<div className=" mx-auto py-0">
    <div className="overflow-x-auto">
    <table className="w-full bg-white border border-gray-200 table-fixed">
<thead>
<tr className="bg-slate-200">
  <th className="border border-slate-300"></th>
  <th className="border border-slate-300">Channel Code</th>
  <th className="border border-slate-300">Channel Name</th>
  <th className="border border-slate-300">Status</th>
  <th className="border border-slate-300">Registration Date</th>
  <th className="border border-slate-300">Channel Configured</th>
</tr>
</thead>
<tbody>
{tablearray.map((itm,idx)=>(
  <tr className={`${itm.id%2===0?"bg-slate-200":""} hover:bg-gray-300`} key={idx}>
  <td >{itm.id}</td>
  <td ><Link to={`/sales/manage-channels/channel?chanel_ID=${itm.id}`} className="text-blue-600 visited:text-purple-600">{itm.chanelCode}</Link></td>
  <td >{itm.ChName}</td>
  <td >{itm.status}</td>
  <td >{itm.RegDate}</td>
  <td >{itm.ChConfig}</td>
</tr>
))}
</tbody>
</table>
    </div>
</div>
    </>
  )
}

export default TableView