import React from 'react'

const OutBoundDelivery = ({maindata,handleInputChange}) => {
    const data=maindata;
  return (
    <>
    <div className=" mx-auto py-0">
        <div className='p-2 rounded border flex'>
            <h1 className='font-medium'>Order No : </h1>
            <p className='px-2'>{data.orderNo}</p>
        </div>
    <div className="overflow-x-auto mt-2">
      <table className="min-w-full divide-y divide-gray-200 rounded-lg">
        <thead className="bg-slate-600 text-white">
          <tr>
            <th className="px-6 py-4 whitespace-nowrap text-sm "></th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Delivery No</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Source WH</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Transport Code</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">AWB No</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Trans Status</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Manifest No</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Trans Remarks</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Invoice No</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Order Shipment</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Weight</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Qty</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">UDF</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.outbondDelivery.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="">
                        <div className="text-cyan-600 font-semibold">{item.deliveryNo}</div>
                        <div className="flex">Date: <p className="text-center w-full ">{item.deliveryDate}</p></div>
                        <div className="flex">Status: <p className="text-center w-full ">{item.deliveryStat}</p></div>
                    </div>                                
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.sourceWH}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.transportCode===""?<p>--No Transport--</p>:<p>{item.transportCode}</p>}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.awbNo}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.transStatus}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.manifestNo}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.transRemark}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.invoiceNo}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.orderShipment}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.weight}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.qty}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-cyan-600 font-bold">{item.udf}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>




</div>
    </>
  )
}

export default OutBoundDelivery