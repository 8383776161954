
import React from "react";



const TableView = ({data}) => {
  return (
    <>
<div className=" mx-auto py-0">
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200 rounded-lg">
        <thead className="bg-slate-600 text-white">
          <tr>
            <th className="px-6 py-4 whitespace-nowrap text-sm "></th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">SKU</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Status</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Order Qty</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">In Process Qty</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Completed Qty</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Unit Price</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Discount</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Taxes</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Live Amount</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Source</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.skuDetail.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" style={{minWidth:"20rem"}}>
                    <div className="flex justify-between">
                        <div>
                        <div className="text-cyan-600 font-semibold">{item.skuCode}</div>
                        <div className="">{item.skuname}</div>
                        <div className="text-sky-700 font-semibold">View Available Qty</div>
                        <div className="flex">Size: <p className="text-center w-full font-semibold">{item.size}</p></div>
                        </div>
                        <div>
                        <img src={item.image} alt="Girl in a jacket" width="70px"/>
                        </div>
                    </div>                                
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.status}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="flex">UOM: <p className="px-1 text-right w-full font-semibold text-red-900">{item.orderQty.uom}</p></div>
                    <div className="flex">UOM Qty: <p className="px-1 text-right w-full font-semibold">{item.orderQty.uomQty}</p></div>
                    <div className="flex">EA Qty: <p className="px-1 text-right w-full font-semibold">{item.orderQty.eaQty}</p></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="flex">Open Qty: <p className="px-1 text-right w-full font-semibold">{item.inProcessQty.openQty}</p></div>
                    <div className="flex">Committed Qty: <p className="px-1 text-right w-full font-semibold">{item.inProcessQty.commitedQty}</p></div>
                    <div className="flex">Picked Qty: <p className="px-1 text-right w-full font-semibold">{item.inProcessQty.pickedQty}</p></div>     
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="flex">Shipped Qty: <p className="px-1 text-right w-full font-semibold">{item.completedQty.shippedQty}</p></div>
                    <div className="flex">Return Qty: <p className="px-1 text-right w-full font-semibold">{item.completedQty.returnQty}</p></div>
                    <div className="flex">Closed Qty: <p className="px-1 text-right w-full font-semibold">{item.completedQty.closedQty}</p></div>     
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.unitPrice}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.discount}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-semibold text-sky-700">View Taxes</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-cyan-600 font-bold">{item.liveAmount}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>




</div>
    </>
  )
}

export default TableView