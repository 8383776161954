import React, { useState } from 'react'
import { RiMenuAddFill } from "react-icons/ri";
import TableView from './tableView';
import { AllSubOrderTabButton } from '../../../OrderButton';

import AddPopupTab from './AddPopupTab';
import DetailSummary from '../../../../../../../common/DetailSummary';
import OrderDetailTab from './OrderDetailTab';




const CreateOrderEdit = ({maindata,handleInputChange}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);   
      const data=maindata;
        const header=[{inputType:"text",head:"Ext. Order No",name:"orderNo",category:"orderNo"},
            {inputType:"option",head:"Site Location",name:"siteLocation",category:"orderDetail",opt:["Yes","No"]},
            {inputType:"option",head:"Payment Type",name:"paymentType",category:"orderDetail",opt:["Yes","No"]},
            {inputType:"option",head:"Customer Type",name:"customerType",category:"orderDetail",opt:["Yes","No"]},
            {inputType:"text",head:"Customer Code",name:"customerCode",category:"orderDetail"},
            {inputType:"option",head:"On Hold Status",name:"onholdStatus",category:"orderDetail",opt:["Yes","No"]},
            {inputType:"option",head:"Send Notification",name:"sendNotification",category:"orderDetail",opt:["Yes","No"]},
            {inputType:"text",head:"Status",name:"ChConfig",category:"orderDetail"},
            {inputType:"textarea",head:"Remark",name:"Remark",category:"orderDetail"},
        ];
        const openModal = () => {
          setIsModalOpen(true);
        };

        const closeModal = () => {
          setIsModalOpen(false);
        };
      
        // Close modal when clicking outside of the modal content
        const handleOutsideClick = (e) => {
          if (e.target.className === 'modal') {
            closeModal();
          }
        };
  return (
    <>
    <AllSubOrderTabButton/>
    <div className='flex justify-center'>
  <div className='flex flex-wrap w-full'>
    {/* First Column */}
    <div className=' xl:w-3/4 lg:w-3/4 md:w-2/3 sm:w-full xs:w-1/2 w-full p-2'>
    <div className="flex flex-wrap">
    {header.map((item, index)=>
        (item.inputType==="text"?
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">{item.head==="Status"?<div>          
          <div className="inline-block bg-green-600 text-white px-3 py-1 rounded-l-full relative">
          {data.orderDetail.status}
  <div className="absolute right-0 top-0 h-full w-3 bg-green-600 transform translate-x-full flex items-center">
    <div className="h-0 w-0 border-y-[15px] border-y-transparent border-l-[12px] border-l-white rotate-180"></div>
  </div>
  </div>
          </div>:
        <input type="text" className='border rounded border-gray-200 w-full' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}/> }</div>   
        </div>
      </div>
        :item.inputType==="textarea"?
        <div className="xl:w-full lg:w-full sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className='px-2'>{item.head}:</div>     
        <textarea rows="2" cols="50" className='border border-slate-400 rounded' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
        
      </textarea>
        </div>
      </div>
        :
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">
            <select className="w-full max-w-52 border rounded border-gray-200" name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
            {item.opt.map((stat, idx)=>(<option value={stat} key={idx}>{stat}</option>))}
            </select>
        </div>   
        </div>
      </div>
    )
    )}

      <div className='p-1 flex justify-items-start my-5'>
        <button className="rounded-l-lg bg-gray-300 p-2 border-x-2 hover:bg-slate-400">More</button>
        <button className="rounded-r-lg bg-gray-300 p-2 border-r-2 hover:bg-slate-400 flex justify-center" onClick={openModal}><RiMenuAddFill className='my-auto'/>&nbsp;Add SKU</button>
      </div>


    </div>

    <div>
      <TableView data={data}/>
    </div>

    </div>
    {/* Second Column */}
    <div className='xl:w-1/4 lg:w-1/4 md:w-1/3 sm:w-full xs:w-1/2 w-full'>
      <OrderDetailTab />
    </div>

  </div>


  {isModalOpen && (
  <div className="modal" onClick={handleOutsideClick}>
    {/* Modal content */}
    <div className="modal-content">
      <span className="close" onClick={closeModal} >&times;</span>
      <AddPopupTab/>
    </div>
  </div>
)}




</div>
    </>
  )
}

export default CreateOrderEdit