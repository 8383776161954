import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { materialpoints } from "../apis";
const { CREATE_MATERIALS,UPDATE_MATERIAL } = materialpoints;

export const createMaterials = async (data, token) => {
    let result = null
    const toastId = toast.loading("Loading...")
    const formattedData = {
        id: data.id,
        name: data.materialName,
        description: data.materialName,
        is_active:  1,
      };
    try {
      const response = await apiConnector("POST", CREATE_MATERIALS,formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      })
      console.log("CREATE MATERIALS............", response)
       if (!response?.data?.success) {
         throw new Error("MATERIALS could not be created")
       }
     toast.success(`${response?.data?.message}`);
    } catch (error) {
      console.log("CREATE MATERIALS API ERROR............", error)
      toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result
  }
  
  export const updateMaterials = async (id, data, token) => {
    let result = null;
    const toastId = toast.loading("Loading...");
    const formattedData = {
        id: data.id,
        name: data.materialName,
        description: data.materialName,
        is_active:  1,
      };
    console.log(formattedData); // For debugging
    try {
      const response = await apiConnector("PUT", `${UPDATE_MATERIAL}/${id}`, formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      });
  
      console.log("UPDATE MATERIALS RESPONSE............", response);

       if (!response?.data?.success) {
         throw new Error("MATERIALS could not be updated");
       }
      toast.success(`${response?.data?.message}`);
    } catch (error) {
      console.log("UPDATE MATERIALS API ERROR............", error);
      toast.error(error.message);
    }
  
    toast.dismiss(toastId);
    return result;
  };