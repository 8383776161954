
import React, {useState} from "react";

import { ChLinkImpTabButton } from "../ChannelListingButtons";
import ImportTable from "../../../../../common/ImportTable";
import ImportPageTwo from "../../../../../common/ImportPageTwo";

const intialarry=[
    {SeqNo:1,chanel:"M08",eretailSKU:"",chSKU:"Tata 1 MG",chProdID:"Active",backOrdInv:"18/07/2022",ChPrice:"Yes",status:"18/07/2022",errDesc:"Yes"},
    {SeqNo:2,chanel:"M08",eretailSKU:"",chSKU:"Tata 1 MG",chProdID:"Active",backOrdInv:"18/07/2022",ChPrice:"Yes",status:"18/07/2022",errDesc:"Yes"},
    {SeqNo:3,chanel:"M08",eretailSKU:"",chSKU:"Tata 1 MG",chProdID:"Active",backOrdInv:"18/07/2022",ChPrice:"Yes",status:"18/07/2022",errDesc:"Yes"},
    {SeqNo:4,chanel:"M08",eretailSKU:"",chSKU:"Tata 2 MG",chProdID:"Active",backOrdInv:"18/07/2022",ChPrice:"Yes",status:"18/07/2022",errDesc:"Yes"},
    {SeqNo:5,chanel:"T08",eretailSKU:"",chSKU:"Tata 1 MG",chProdID:"Active",backOrdInv:"18/07/2022",ChPrice:"Yes",status:"18/07/2022",errDesc:"Yes"},
  ];


const ChannelLinkImpTab = () => {
    const head=["Seq No","Channel","Eretail SKU","Channel SKU","Channel Product Id","Back Order Inventory","Channel Price","Status","Error Description"]

    const uploadFileHandler = (e) => {
      const file = e.target.files[0];
      if (file) {
        console.log(file);
      } else {
        console.error("No file selected");
      }
    };
    
    const resetHandler=()=>{
        console.log("to reset")
      };
        
  
  return (
    <>
    <ChLinkImpTabButton resetHandler={resetHandler} importfun={uploadFileHandler}/>
    <ImportPageTwo fileName={"sample.txt"} intialarry={intialarry} head={head}/>
    </>
  )
}

export default ChannelLinkImpTab;