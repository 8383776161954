import React from 'react';

const Address = () => {
  return (
    <div className="mx-auto my-8">
      <div className="border border-slate-800 p-6 rounded-lg relative">
        <h2 className="text-lg font-semibold mb-4 absolute -top-4 left-4 bg-white px-2">Shipping Address Details</h2>
        <form className="grid grid-cols-1 text-xs gap-4 lg:grid-cols-3">
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="address1">Address 1*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="address1" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="address2">Address 2</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="address2" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="address3">Address 3</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="address3" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="contactPerson">Contact Person*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="contactPerson" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="phone">Phone*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="tel" id="phone" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="email">Email*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="email" id="email" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="latitude">Latitude</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="latitude" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="longitude">Longitude</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="longitude" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="country">Country*</label>
            <select className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" id="country" required>
              <option>India</option>
            </select>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="state">State*</label>
            <select className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" id="state" required>
              <option>---Select State---</option>
            </select>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="city">City*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="city" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="pinCode">Pin Code*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="pinCode" required />
          </div>
          <div className="mb-4 lg:mb-0 flex items-center lg:col-span-3">
            <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" id="copyToBilling" />
            <label className="ml-2 text-gray-700" htmlFor="copyToBilling">Copy To Billing</label>
          </div>
        </form>
      </div>
      <div className="mt-8 p-6 border border-slate-800 rounded-lg relative">
        <h2 className="text-lg font-semibold mb-4 absolute -top-4 left-4 bg-white px-2">Billing Address Details</h2>
        <form className="grid grid-cols-1 gap-4 text-xs lg:grid-cols-3">
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingAddress1">Address 1*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="billingAddress1" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingAddress2">Address 2</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="billingAddress2" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingAddress3">Address 3</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="billingAddress3" />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingContactPerson">Contact Person*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="billingContactPerson" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingPhone">Phone*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="tel" id="billingPhone" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingEmail">Email*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="email" id="billingEmail" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingCountry">Country*</label>
            <select className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" id="billingCountry" required>
              <option>India</option>
            </select>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingState">State*</label>
            <select className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" id="billingState" required>
              <option>---Select State---</option>
            </select>
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingCity">City*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="billingCity" required />
          </div>
          <div className="mb-4 lg:mb-0">
            <label className="block text-gray-700 font-medium mb-2" htmlFor="billingPinCode">Pin Code*</label>
            <input className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" type="text" id="billingPinCode" required />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Address;
