import React, {useState} from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { SlQuestion } from "react-icons/sl";
import { VscRefresh } from "react-icons/vsc";
import { GoDownload } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";

export const LinkSearchTabButton=({resetHandler})=>{
    return (
      <>
      <div className='flex flex-wrap justify-end'>
      <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <IoIosAddCircleOutline className='my-auto text-lg font-large'/>
          &nbsp; Add New
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <VscRefresh  className='my-auto text-lg font-large'/>
          &nbsp; Retry
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200" onClick={resetHandler}>
          <IoCloseOutline className='my-auto text-lg font-large'/>
          &nbsp; Reset
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <GoDownload  className='my-auto text-lg font-large'/>
          &nbsp; Export
          </button>
          <button type="button" className="relative flex rounded-md  px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <SlQuestion className='my-auto text-lg font-large text-cyan-500'/>
          </button>
      </div>
      </>
    )
  };

  export const ChLinkImpTabButton=({resetHandler})=>{
    return (
      <>
      <div className='flex flex-wrap justify-end'>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200" onClick={resetHandler}>
          <IoCloseOutline className='my-auto text-lg font-large'/>
          &nbsp; Reset
          </button>
          <button type="button" className="relative flex rounded-md  px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <SlQuestion className='my-auto text-lg font-large text-cyan-500'/>
          </button>
      </div>
      </>
    )
  };

  export const UnmappedTabButton=({resetHandler})=>{
    return (
      <>
      <div className='flex flex-wrap justify-end'>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200" onClick={resetHandler}>
          <IoCloseOutline className='my-auto text-lg font-large'/>
          &nbsp; Reset
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <GoDownload  className='my-auto text-lg font-large'/>
          &nbsp; Export
          </button>
          <button type="button" className="relative flex rounded-md  px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <SlQuestion className='my-auto text-lg font-large text-cyan-500'/>
          </button>
      </div>
      </>
    )
  };