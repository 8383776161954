
import React, {useEffect, useState} from "react";
import ImportTable from "./ImportTable";




const ImportPageTwo = ({fileName,intialarry,head}) => {
    const [tablearray,settablearray]=useState(intialarry);

    useEffect(() => {
        settablearray(intialarry); // Sync with updated parent state
      }, [intialarry]);


    const uploadFileHandler = (e) => {
      const file = e.target.files[0];
      if (file) {
        console.log(file);
      } else {
        console.error("No file selected");
      }
    };

    const downloadFileHandler = (filename) => {
      const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/filecollection/${filename}`; // File path in the public folder
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    };
    
    const resetHandler=()=>{
        settablearray([...intialarry])
      };
        
  
  return (
    <>
  <div className="p-2 w-full">
    <div className=" rounded border-2 border-gray-400 pt-2 pr-1 pl-1 ">
    <div className="flex flex-col xl:flex-row justify-around p-2">
    <div className="flex flex-col xl:flex-row xl:items-center mb-2 xl:mb-0 p-2">
        <div className="xl:my-auto px-2">Download Template</div>
        <button onClick={()=>downloadFileHandler(fileName)} className="text-blue-500 py-2 px-4">
        {fileName}
        </button>
    </div>
    <div className="flex flex-col xl:flex-row xl:items-center mb-2 xl:mb-0 p-2">
        <div className="xl:my-auto">Update Template</div>
        <div className="relative inline-block text-left xl:ml-5 my-auto">
            <input type="file" onChange={uploadFileHandler}/>
        </div>
    </div>
    <div className="flex flex-col xl:flex-row xl:items-center mb-2 xl:mb-0 p-2">
        <div className="xl:my-auto px-2">Import Batch No</div>
    </div>
    </div>
    <p className="mt-4 text-gray-600">
        <strong>Note:</strong> Maximum rows can be imported in an attempt of Import: 10000
    </p>
    </div>
    </div>

    <div>
        <ImportTable tablearray={tablearray} head={head}/>
    </div>
    </>
  )
}

export default ImportPageTwo;