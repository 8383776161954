import React, { useState } from 'react';

const SkuForm = () => {
  const [formData, setFormData] = useState({
    skuCode: '',
    skuName: '',
    skuShortName: '',
    skuClassification: '',
    free: false,
    style: '',
    skuSource: '',
    outright: '',
    attributeSet: 'Default',
    sizeGroup: '',
    size: '',
    colour: '',
    material: '',
    primaryUpcEan: '',
    baseUom: '',
    hierarchyCode: '',
    vendorSkuCode: '',
    primaryVendor: '',
    vendorName: '',
    isApproved: false,
    status: 'Active',
    countryOfOrigin: 'India',
    brandCode: '',
    linkToStore: '',
    partModelNo: '',
    season: '',
    backOrder: false,
    isPrimaryStyle: false,
    primaryVariant: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // Handle form submission here
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">

      <div className="grid grid-cols-1 text-xs font-medium md:grid-cols-3 gap-2">
        {/* SKU Code */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">SKU Code</label>
          <input
            type="text"
            name="skuCode"
            value={formData.skuCode}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* SKU Name */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">SKU Name</label>
          <input
            type="text"
            name="skuName"
            value={formData.skuName}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* SKU Short Name */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">SKU Short Name</label>
          <input
            type="text"
            name="skuShortName"
            value={formData.skuShortName}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* SKU Classification */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">SKU Classification</label>
          <select
            name="skuClassification"
            value={formData.skuClassification}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            <option value="normal">Normal</option>
            <option value="bom">BOM</option>
          </select>
        </div>

        {/* Free */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Free</label>
          <input
            type="checkbox"
            name="free"
            checked={formData.free}
            onChange={handleChange}
            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
          />
        </div>

        {/* Style */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Style</label>
          <input
            type="text"
            name="style"
            value={formData.style}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* SKU Source */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">SKU Source</label>
          <input
            type="text"
            name="skuSource"
            value={formData.skuSource}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Outright */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Outright</label>
          <input
            type="text"
            name="outright"
            value={formData.outright}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Attribute Set */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Attribute Set</label>
          <select
            name="attributeSet"
            value={formData.attributeSet}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="Default">Default</option>
            {/* Add other options if necessary */}
          </select>
        </div>

        {/* Size Group */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Size Group</label>
          <select
            name="sizeGroup"
            value={formData.sizeGroup}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options for size group */}
          </select>
        </div>

        {/* Size */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Size</label>
          <input
            type="text"
            name="size"
            value={formData.size}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Colour */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Colour</label>
          <select
            name="colour"
            value={formData.colour}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options for colour */}
          </select>
        </div>

        {/* Material */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Material</label>
          <select
            name="material"
            value={formData.material}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options for material */}
          </select>
        </div>

        {/* Primary UPC/EAN */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Primary UPC/EAN</label>
          <input
            type="text"
            name="primaryUpcEan"
            value={formData.primaryUpcEan}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Base UOM */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Base UOM</label>
          <select
            name="baseUom"
            value={formData.baseUom}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options for Base UOM */}
          </select>
        </div>

        {/* Hierarchy Code */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Hierarchy Code</label>
          <input
            type="text"
            name="hierarchyCode"
            value={formData.hierarchyCode}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Vendor SKU Code */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Vendor SKU Code</label>
          <input
            type="text"
            name="vendorSkuCode"
            value={formData.vendorSkuCode}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Primary Vendor */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Primary Vendor</label>
          <input
            type="text"
            name="primaryVendor"
            value={formData.primaryVendor}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Vendor Name */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Vendor Name</label>
          <input
            type="text"
            name="vendorName"
            value={formData.vendorName}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Is Approved */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Is Approved</label>
          <input
            type="checkbox"
            name="isApproved"
            checked={formData.isApproved}
            onChange={handleChange}
            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
          />
        </div>

        {/* Status */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>

        {/* Country Of Origin */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Country Of Origin</label>
          <input
            type="text"
            name="countryOfOrigin"
            value={formData.countryOfOrigin}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Brand Code */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Brand Code</label>
          <input
            type="text"
            name="brandCode"
            value={formData.brandCode}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Link To Store */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Link To Store</label>
          <input
            type="text"
            name="linkToStore"
            value={formData.linkToStore}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Part Model No. */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Part Model No.</label>
          <input
            type="text"
            name="partModelNo"
            value={formData.partModelNo}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Season */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Season</label>
          <select
            name="season"
            value={formData.season}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options for season */}
          </select>
        </div>

        {/* Back Order */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Back Order</label>
          <input
            type="checkbox"
            name="backOrder"
            checked={formData.backOrder}
            onChange={handleChange}
            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
          />
        </div>

        {/* Is Primary Style */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Is Primary Style</label>
          <input
            type="checkbox"
            name="isPrimaryStyle"
            checked={formData.isPrimaryStyle}
            onChange={handleChange}
            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
          />
        </div>

        {/* Primary Variant */}
        <div className="mb-2">
          <label className="block text-gray-700  mb-1">Primary Variant</label>
          <input
            type="text"
            name="primaryVariant"
            value={formData.primaryVariant}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>
      </div>

      {/* <div className="flex justify-center mt-6">
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Submit
        </button>
      </div> */}
    </form>
  );
};

export default SkuForm;
