import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaUser } from "react-icons/fa";




const CommentHistoryTab = ({maindata,handleInputChange}) => {
    const [comment, setComment] = useState(maindata.commentHistory);
    const user = useSelector((state) => state.profile.user);
    const [newComment,setNewComment]=useState("");

    
   
    useEffect(() => {
        setComment(maindata.commentHistory); // Sync with updated parent state
      }, [maindata.commentHistory]);

    const commentHandler=()=>{
        const currentComment = newComment;
        handleInputChange("commentHistory",{name:user?.name,comment:currentComment},"commentHistory");
        setNewComment("");
    }

    

  return (
    <>
    <div className='w-full' >
        <div style={{maxWidth:"70rem"}} className='mx-auto'>
        <div className="flex flex-wrap">
        <div className='px-2 w-1/5'>Add Purchase Order Comments</div>     
        <textarea rows="6" cols="50" className='border border-slate-400 rounded w-4/5' name={user} value={newComment} onChange={(e)=>setNewComment(e.target.value)}>  
        </textarea>
        </div>
        </div>
        <div className='flex w-full mt-2'>
        <div className="ml-auto relative">
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-slate-600 px-3 py-2 text-white font-medium hover:bg-slate-400" onClick={commentHandler}>
            Submit Comment
        </button>
        </div>        
        </div>
        <div className='rounded border mt-4'>
            <div className='bg-slate-700 w-full text-white font-semibold py-2 px-3'>
                Comments
            </div>
            <div className='p-2'>
                {comment.map((item,index)=>(
                    <>
                        <div className='w-full flex' key={index}>
                        <div className='w-1/6 p-1' >
                        <div className='flex'>
                            <div className='my-auto px-2'><FaUser /></div>
                            <div className='w-full'>{item.name}</div>
                        </div>
                        </div>
                        <div className='w-5/6 p-1'>
                        <div className='w-full break-words overflow-hidden'>{item.comment}</div>
                        </div>
                    </div>
                    <hr/>
                    </>
                ))}
            </div>
        </div>
    </div>
    </>
  )
}

export default CommentHistoryTab