import React, { useState } from 'react'
import ImportPage from './ImportTab';


const SkuImport = () => {
  const [tempStatus, settempStatus]=useState(["Import","Download","do"]);
  const [currTemp, setTemp]=useState(tempStatus[0]);

  const setPageTemp=(temp)=>{
    setTemp(temp)
    console.log(currTemp)
  }

  return (
    <>
      <div className='bg-white p-2 shadow-sm'>
      <div className="p-2 ">
        <div className="flex pt-2 pr-1 pl-1">
        <nav className="-mb-px flex space-x-2" aria-label="Tabs">
        {tempStatus.map((temp) => (
            <button
              key={temp}
              onClick={() => setPageTemp(temp)}
              className={`bg-slate-600 text-white py-2 px-4 rounded-md shadow-sm  ${currTemp===temp ? 'bg-slate-900' : ''}`}
            >
              {temp}
            </button>
          ))}
        </nav>
        </div>
      </div>
      {currTemp==="Import"?<ImportPage></ImportPage>:<h1>ok</h1>}
      </div>
    </>
  );
};


export default SkuImport;
