import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { uomPoints } from "../apis";
const { CREATE_UNITS,UPDATE_UNIT } = uomPoints;

export const createUnits = async (data, token) => {
    let result = null
    const toastId = toast.loading("Loading...")
    const formattedData = {
        id: data.id,
        code:data.uomCode,
        name: data.uomName,
        description: data.description,
        has_decimal: data.hasDecimal == 'YES'? 1 : 0,
        is_active: data.status,
      };
      console.log('formattedData UOM'+formattedData); // For debugging
    try {
      const response = await apiConnector("POST", CREATE_UNITS,formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      })
      console.log("CREATE UOM ............", response)
       if (!response?.data?.success) {
         throw new Error("UOM could not be created")
       }
     toast.success("UOM created successfully");
    } catch (error) {
      console.log("CREATE UOM API ERROR............", error)
      toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result
  }
  
  export const updateUnits = async (id, data, token) => {
    let result = null;
    const toastId = toast.loading("Loading...");
    console.log('update unit'+data);
    const formattedData = {
        id: data.id,
        code:data.uomCode,
        name: data.uomName,
        description: data.description,
        has_decimal: data.hasDecimal == 'YES'? 1 : 0,
        is_active: Number(data.status),
      };
    console.log('formattedData UOM'+formattedData); // For debugging
    try {
      const response = await apiConnector("PATCH", `${UPDATE_UNIT}/${id}`, formattedData, {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      });
  
      console.log("UPDATE UOM  RESPONSE............", response);

       if (!response?.data?.success) {
         throw new Error("UOM could not be updated");
       }
      toast.success("Your UOM have been updated");
    } catch (error) {
      console.log("UPDATE UOM  API ERROR............", error);
      toast.error(error.message);
    }
  
    toast.dismiss(toastId);
    return result;
  };