import ProductDetails from '../components/Tabs/Vertical Tabs/ProductDetails';
import PricingDetails from '../components/Tabs/Vertical Tabs/PricingDetails';
import DimensionDetails from '../components/Tabs/Vertical Tabs/DimensionDetails';
import SkuImage from '../components/Tabs/Vertical Tabs/SkuImage';
import Details1 from '../components/Tabs/Vertical Tabs/Other Details/Details1';
import Details2 from '../components/Tabs/Vertical Tabs/Other Details/Details2';
import Brands from '../components/Tabs/Vertical Tabs/Other Masters/Brands';
import Materials from '../components/Tabs/Vertical Tabs/Other Masters/Materials';
// import SubscribeHSCCode from '../components/Tabs/Vertical Tabs/Other Masters/SubscribeHSCCode';
import SizeGroup from '../components/Tabs/Vertical Tabs/Other Masters/SizeGroup';
import Size from '../components/Tabs/Vertical Tabs/Other Masters/Size';
import Color from '../components/Tabs/Vertical Tabs/Other Masters/Color';
// import CustomerType from '../components/Tabs/Vertical Tabs/Other Masters/CustomerType';
import State from '../components/Tabs/Vertical Tabs/Other Masters/State';
// import Reasons from '../components/Tabs/Vertical Tabs/Other Masters/Reasons';
// import Tags from '../components/Tabs/Vertical Tabs/Other Masters/Tags';
import PaymentTerms from '../components/Tabs/Vertical Tabs/Other Masters/PaymentTerms';
// import SKUFulfillmentType from '../components/Tabs/Vertical Tabs/Other Masters/SKUFulfillmentType';
// import ChannelConfiguration from '../components/Tabs/Vertical Tabs/Other Masters/ChannelConfiguration';
// import ManageCurrency from '../components/Tabs/Vertical Tabs/Other Masters/ManageCurrency';
import UOM from '../components/Tabs/Vertical Tabs/Other Masters/UOM';

export const tabData = [
    {
        id: 1, 
        name: 'Product Details',
        component: ProductDetails,
      },
      {
        id: 2,
        name: 'Pricing Details',
        component: PricingDetails,
      },
      {
        id: 3,
        name: 'Dimension Details',
        component: DimensionDetails,
      },
      {
        id: 4,
        name: 'Sku Image',
        component: SkuImage,
      }
]


export const otherData = [
    {
        id: 1, 
        name: 'Details1',
        component:Details1,
      },
      {
        id: 2,
        name: 'Details2',
        component:Details2,
      },
]

export const tabConfig = [
  {
    id: 1, 
    name: 'Brands',
    component: Brands,
  },
  {
    id: 2, 
    name: 'Materials',
    component: Materials,
  },
  {
    id: 3, 
    name: 'Size Group',
    component: SizeGroup,
  },
  {
    id: 4, 
    name: 'Size',
    component: Size,
  },
  {
    id: 5, 
    name: 'Color',
    component: Color,
  },
  {
    id: 6, 
    name: 'State',
    component: State,
  },
  {
    id: 7, 
    name: 'UOM',
    component: UOM,
  },
  {
    id: 8, 
    name: 'Payment Terms',
    component: PaymentTerms,
  },
];