import React from 'react';
const Loading = () => {
  return (
    <div className="spinner">
      <div className="spinner-text">Waveretail</div>
      <div className="spinner-animation"></div>
    </div>
  );
};

export default Loading;
