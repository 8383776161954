import React, { useState } from 'react'
import { DimensionDetailSubTab, ImageSubTab, PricingDetailSubTab, ProductDetailSubTab } from './PopUpSubTab';


const AddPopupTab = () => {
  const subPopTabs=[{name:"Product Details",view:false},{name:"Pricing Details",view:false},{name:"Dimension Details",view:false},{name:"SKU Image",view:false}]
  const [tabstatus, setTabStatus]=useState(subPopTabs[0].name)
  const tabChangeHandler=(tabname)=>{
      setTabStatus(tabname)
  }


  return (
    <>
    <div className='w-3/5 mx-auto'>
    <div className="flex flex-wrap -mx-2 bg-white p-5">
    <div className="w-full sm:w-1/4 md:w-1/6 px-2">
    <div className='bg-gray-100'>
      {subPopTabs.map((item,indx)=>(
        <>
        {tabstatus===item.name?
        <div key={indx} className='p-1 bg-gray-200 font-semibold' onClick={()=>{tabChangeHandler(item.name)}}>{item.name}</div>:
        <div key={indx} className='p-1 hover:bg-gray-200' onClick={()=>{tabChangeHandler(item.name)}}>{item.name}</div>
        }
        </>
        ))}
      </div>
    </div>
    <div className="w-full sm:w-3/4 md:w-5/6 px-2">
    {tabstatus===subPopTabs[0].name?<ProductDetailSubTab/>:
    tabstatus===subPopTabs[1].name?<PricingDetailSubTab/>:
    tabstatus===subPopTabs[2].name?<DimensionDetailSubTab/>:
    tabstatus===subPopTabs[3].name?<ImageSubTab/>:
    <div>no</div>}
    </div>
  </div>
  </div>
    </>
  )
}

export default AddPopupTab