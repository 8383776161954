import React from 'react'

const PurchaseOrdTable = ({maindata}) => {

  const imagelink="https://5.imimg.com/data5/NB/OQ/OJ/SELLER-29327678/self-defense-pepper-spray.jpg"

  const data=maindata.tabledata;


  return (
    <>
    <div className=" mx-auto py-0">
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200 rounded-lg">
        <thead className="bg-slate-600 text-white">
          <tr>
            <th className="px-6 py-4 whitespace-nowrap text-sm "></th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">SKU Code</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">PO Qty</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">MRP</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Unit Cost</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Qty</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Attributes</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Delivery Date</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Discount</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Tax</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Charges/Amount</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
        {data.map((item,index)=>(
            <tr className="hover:bg-gray-100" key={index}>                
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" style={{minWidth:"20rem"}}>
                        <div className="flex justify-between">
                          <div>
                          <div className='text-cyan-600 font-semibold'>{item.poCode.code}</div>
                          <div>{item.poCode.productName}</div>
                          </div>
                            <div>
                            <img src={item.image} alt="Girl in a jacket" width="70px" className="transition-transform duration-300 ease-in-out transform hover:scale-110"/>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.poQty}
                          </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.mrp}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.unitCost}</td>                        
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">  
                          <div className="flex">PO Rcvd Qty: <p className="px-1 text-right w-full font-semibold text-red-900">{item.qty.poRcvdQty}</p></div>   
                            <div className="flex">Closed Qty: <p className="px-1 text-right w-full font-semibold">{item.qty.closedQty}</p></div>
                            <div className="flex">Open Qty: <p className="px-1 text-right w-full font-semibold">{item.qty.openQty}</p></div>
                            <div className="flex">ASN Qty: <p className="px-1 text-right w-full font-semibold">{item.qty.asnQty}</p></div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div className="flex">UOM: <p className="px-1 text-right w-full font-semibold text-red-900">{item.attributes.uom}</p></div>
                        <div className="flex">EA Qty: <p className="px-1 text-right w-full font-semibold">{item.attributes.eaQty}</p></div>
                        <div className="flex">Size: <p className="px-1 text-right w-full font-semibold">{item.attributes.size}</p></div>
                        <div className="flex">Color: <p className="px-1 text-right w-full font-semibold">{item.attributes.color}</p></div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            <div>{item.deliveryDate}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            <div>{item.discount}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div className="flex">Tax Group: <p className="px-1 text-right w-full font-semibold">{item.tax.taxGroup}</p></div>
                        <div className="flex">Tax Amt: <p className="px-1 text-right w-full font-semibold">{item.tax.taxAmt}</p></div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div className="flex">Line Amt: <p className="px-1 text-right w-full font-semibold">{item.chargeAmt}</p></div>
                        </td>              
            </tr>
        ))}
        </tbody>
      </table>
      </div>
      </div>
    </>
  )
}

export default PurchaseOrdTable