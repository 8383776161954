import React from 'react'

const PurchaseOrderInbound = ({maindata}) => {
    const header=["Inbound No","Inbound Date","GRN No","ASN No","GRN Date","Invoice No","status"]

    return (
      <>
      <div>
        <div className='border w-full rounded'>
            <div className='flex mx-4 p-4'>
                <div className='px-3'>PO Tags</div>
            </div>
        </div>
        <div>
        <div className=' w-full'>
      <div className="oferflow w-full mt-2">
      <table className="w-full">
          <thead className="bg-slate-600 text-white">        
              <tr>
              {header.map((item,index)=>(
                <th className="px-6 py-4 whitespace-nowrap text-sm" key={index}>{item}</th>
              ))}
              </tr> 
          </thead>
          <tbody className="divide-y divide-gray-200">          
                {maindata.poInbound.map((item,index)=>(   
                    <tr className="hover:bg-gray-100" key={index}>             
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.inboundNo}</td>    
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.inboundDate}</td> 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.grnNo}</td> 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.asnNo}</td> 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.grnDate}</td> 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.invoiceNo}</td> 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.status}</td> 
                    </tr>            
                ))}                  
                </tbody>
            </table>
            </div>
        </div>
        </div>
      </div>
      
      </>
    )
  }

export default PurchaseOrderInbound