import React, { useState } from 'react'

const DetailSummary = ({detailTab}) => {
      const [tab,setTabs]=useState(detailTab);

      console.log("detailtab",detailTab)

      const tabManger=(index)=>{
        const updatedTabs = tab.map((item, i) => 
            i === index ? { ...item, state: !item.state } : item
          );
        
          // Update the state with the new array
          setTabs(updatedTabs);
      }

  return (
    <>    
    {tab.map((item,index)=>(
        <div className='bg-gray-200 rounded' key={index}>    
        <div className='bg-gray-300 rounded text-center font-semibold' onClick={() => tabManger(index)}>{item.TabName}</div>
        {item.state && <div>
            {item.data.map((obj,idx)=>(
              <div className="flex flex-wrap" key={idx}>
              <div className="sm:w-1/2 w-full">
              <div className='px-2'>{obj.head}</div>
              </div>
              <div className="sm:w-1/2 w-full my-auto">
              {obj.result}
              </div>   
              </div>
          ))}
        </div>}   
        </div>
    ))}
    </>
  )
}

export default DetailSummary