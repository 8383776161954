export const vendorHeaders = [
    { key: 'vendorCode', label: 'Vendor Code', isInput: true },
    { key: 'vendorName', label: 'Vendor Name', isInput: true },
    { key: 'vendorType', label: 'Vendor Type', isInput: false },
    { key: 'creditDays', label: 'Credit Days', isInput: false },
    { key: 'country', label: 'Country', isInput: false },
    { key: 'state', label: 'State', isInput: false },
    { key: 'city', label: 'City', isInput: true },
    { key: 'status', label: 'Status', isInput: false },
  ];


  export const customerHeaders = [
    { key: 'customerCode', label: 'Customer Code', isInput: true },
    { key: 'customerName', label: 'Customer Name', isInput: true },
    { key: 'type', label: 'Type', isInput: false },
    { key: 'status', label: 'Status', isInput: false },
    { key: 'gstTin', label: 'GST/TIN', isInput: true },
    { key: 'primaryContact', label: 'Primary Contact', isInput: true },
    { key: 'primaryEmail', label: 'Primary Email', isInput: true },
    { key: 'createdDate', label: 'Created Date', isInput: true },
  ];
 
  export const transportHeaders = [
    { key: 'transporterCode', label: 'Transporter Code', isInput: true },
    { key: 'transporterName', label: 'Transporter Name', isInput: true },
    { key: 'transporterCompany', label: 'Transporter Company', isInput: true },
    { key: 'type', label: 'Type', isInput: false },
    { key: 'country', label: 'Country', isInput: false },
    { key: 'state', label: 'State', isInput: false },
    { key: 'city', label: 'City', isInput: true },
    { key: 'status', label: 'Status', isInput: false },
  ];
    
  export const clientHeaders = [
    { key: 'clientId', label: 'Client ID', isInput: true },
    { key: 'clientName', label: 'Client Name', isInput: true },
    { key: 'clientShortName', label: 'Client Short Name', isInput: true },
    { key: 'status', label: 'Status', isInput: false },
    { key: 'country', label: 'Country', isInput: false },
    { key: 'state', label: 'State', isInput: false },
    { key: 'city', label: 'City', isInput: true },
  ];

  export const taxCategoryHeaders = [
    { key: 'taxCategoryCode', label: 'Tax Category (HSN/SAC)', isInput: true },
    { key: 'description', label: 'Description', isInput: true },
    { key: 'status', label: 'status', isInput: false, type: 'select', options: [{label:'Active',value:1},{label:'Inactive',value:0}] }
  ];
  
  export const taxCodeHeaders = [
    { key: 'taxCode', label: 'Tax Code', isInput: true,type:'text' },
    { key: 'taxCodeType', label: 'Tax Code Type', isInput: false, options: [{label:'gst',value:'gst'}] }, // Add your actual types here
    { key: 'start', label: 'Start', isInput: true, type: 'date' },
    { key: 'percentage', label: 'Percentage', isInput: true,type:'text' },
    { key: 'description', label: 'Description', isInput: true,type:'text' },
    { key: 'status', label: 'Status', isInput: false,  type: 'select', options: [{label:'Active',value:1},{label:'Inactive',value:0}] },
  ];
  
  export const taxGroupHeaders = [
    { key: 'taxGroupCode', label: 'Tax Group Code', isInput: true,type:'text' },
    { key: 'appliedOn', label: 'Applied On', isInput: false, options: ['Type1', 'Type2'] }, // Add your actual types here
    { key: 'startDate', label: 'Start Date', isInput: true, type: 'date' },
    { key: 'isActive', label: 'is Active', isInput: true,type:'checkbox' },
    { key: 'taxLevel', label: 'Tax Level', isInput: false, options: ['1', '2'] },
    { key: 'taxCode', label: 'Tax Code', isInput: false, options: ['G-28', 'G-28'] },
  ];

  export const taxZoneHeaders = [
    { key: 'taxZone', label: 'Tax Zone', isInput: true, type: 'text' }, // Input type text
    { key: 'taxZoneName', label: 'Tax Zone Name', isInput: true, type: 'text' }, // Input type text
    { key: 'description', label: 'Description', isInput: true, type: 'text' }, // Input type text
    { key: 'country', label: 'Country', isInput: false, type: 'select',options: [{label:'India',value:101}] },
    { key: 'state', label: 'State', isInput: false, type: 'select',options: ['Active', 'Inactive'] }, // Input type text
    { key: 'status', label: 'Status', isInput: false, type: 'select', options: [{label:'Active',value:1},{label:'Inactive',value:0}] }// Dropdown select options
  ];
  
  export const taxApplicationHeaders = [
    { key: 'taxCategory', label: 'Tax Category', isInput: false, options: ['Category1', 'Category2'] },
    { key: 'taxType', label: 'Tax Type', isInput: false, options: ['Type1', 'Type2'] },
    { key: 'taxGroupCode', label: 'Tax Group Code', isInput: false, options: ['G-28', 'G-29'] },
    { key: 'goodsDirection', label: 'Goods Direction', isInput: false, options: ['Inbound', 'Outbound'] },
    { key: 'taxZone', label: 'Tax Zone', isInput: false, options: ['Zone1', 'Zone2'] },
    { key: 'startDate', label: 'Start Date', isInput: true, type: 'date' },
    { key: 'isActive', label: 'Is Active', isInput: false, type: 'select', options: ['Active', 'Inactive'] },
    { key: 'fromMRP', label: 'From MRP', isInput: true, type: 'number' },
    { key: 'toMRP', label: 'To MRP', isInput: true, type: 'number' }
  ];

  export const skuMasterHeaders = [
    { key: 'skuCode', label: 'SKU Code', isInput: true, type: 'text' }, // Input type text
    { key: 'style', label: 'Style', isInput: true, type: 'text' }, // Input type text
    { key: 'skuName', label: 'SKU Name', isInput: true, type: 'text' }, // Input type text
    { key: 'classification', label: 'Classification', isInput: false, options: ['Option1', 'Option2'],type:'select' }, // Dropdown select options
    { key: 'sizeColor', label: 'Size/Color', isInput: false, type: 'label' }, // Label
    { key: 'mrp', label: 'MRP', isInput: false, type: 'label' }, // Label
    { key: 'bestCost', label: 'Best Cost', isInput: false, type: 'label' }, // Label
    { key: 'salePrice', label: 'Sale Price', isInput: false, type: 'label' }, // Label
    { key: 'skuMfgCode', label: 'SKU Mfg Code', isInput: false, type: 'label' }, // Label
    { key: 'primaryVendor', label: 'Primary Vendor', isInput: true, type: 'text' }, // Input type text
    { key: 'brandCode', label: 'Brand Code', isInput: true, type: 'text' }, // Input type text
    { key: 'hierarchyCode', label: 'Hierarchy Code', isInput: true, type: 'text' }, // Input type text
    { key: 'attributeSet', label: 'Attribute Set', isInput: false, options: ['Option1', 'Option2'],type:'select' }, // Dropdown select options
    { key: 'status', label: 'Status', isInput: false, options: ['Active', 'Inactive'],type:'select' }, // Dropdown select options
    { key: 'createdBy', label: 'Created By', isInput: false, options: ['User1', 'User2'],type:'select' }, // Dropdown select options
    { key: 'createdDate', label: 'Created Date', isInput: true, type: 'date' }, // Date input
    { key: 'updatedBy', label: 'Updated By', isInput: true, type: 'text' }, // Input type text
    { key: 'updatedDate', label: 'Updated Date', isInput: true, type: 'date' }, // Date input
    { key: 'backOrder', label: 'Back Order', isInput: false, options: ['Yes', 'No'],type:'select' }, // Dropdown select options
    { key: 'taxCategory', label: 'Tax Category (HSN/SAC)', isInput: false, type: 'label' } // Label
  ];

  export const locationHeaders = [
    { key: 'locationCode', label: 'Location Code', isInput: true, type: 'text' }, // Input type text
    { key: 'locationName', label: 'Location Name', isInput: true, type: 'text' }, // Input type text
    { key: 'shortName', label: 'Short Name', isInput: true, type: 'text' }, // Input type text
    { key: 'locationType', label: 'Location Type', isInput: false, options: ['Type1', 'Type2'], type: 'select' }, // Dropdown select options
    { key: 'sourceWarehouse', label: 'Source Warehouse', isInput: false, options: ['Warehouse1', 'Warehouse2'], type: 'select' }, // Dropdown select options
    { key: 'hierarchyCode', label: 'Hierarchy Code', isInput: false, options: ['Code1', 'Code2'], type: 'select' }, // Dropdown select options
    { key: 'hierarchyType', label: 'Hierarchy Type', isInput: false, options: ['Type1', 'Type2'], type: 'select' }, // Dropdown select options
    { key: 'status', label: 'Status', isInput: false, options: ['Active', 'Inactive'], type: 'select' } // Dropdown select options
  ];
  

 export const HSCheaders = [
    { key: 'hsncode', label: 'HSN Code', isInput: true, type: 'text' },
    { key: 'percentage', label: 'Percentage', isInput: true, type: 'text' },
    { key: 'description', label: 'Description', isInput: true, type: 'text' },
    { key: 'status', label: 'Status', isInput: false,type:'select', options: [{label:'Subscribed',value:1}, {label:'Unsubscribed',value:0}] },
    { key: 'fromMRP', label: 'From MRP', isInput: false, type: 'label' },
    { key: 'toMRP', label: 'To MRP', isInput: false, type: 'label' }
  ];
  
  export const brandHeaders = [
    { key: 'brandDescription', label: 'Brand Description', isInput: true, type: 'text' },
    { key: 'externalBrand', label: 'External Brand', isInput: true, type: 'text' },
    { key: 'status', label: 'Status', isInput: false, type: 'select', options: [{label:'Active',value:1},{label:'Inactive',value:0}] },
  ];

  export const materialsHeaders = [
    { key: 'materialName', label: 'Material Name', isInput: true, type: 'text' }
  ];

  export const sizeGroupHeaders = [
    { key: 'sizeGroupDescription', label: 'Size Group Description', isInput: true, type: 'text' }
  ];
  
  export const sizeHeaders = [
    { key: 'sizeGroup', label: 'Size Group', isInput: false, type: 'select',options:[] },
    { key: 'size', label: 'Size', isInput: true, type: 'text' },
    { key: 'externalSizeCode', label: 'External Size Code', isInput: true, type: 'text' }
  ];
  
  // new change order Enquirey tabs tables
  export const orderEnquiryHeaders = [
    { key: 'orderNo', label:'Order No',inputType:'text'},
    { key: 'extOrderNo', label:'Ext Order No',inputType:'text'},
    { key: 'channel', label:'Channel',inputType:'option'},
    { key: 'orderType', label:'Order Type',inputType:'text'},
    { key: 'orderDate', label:'Order Date',inputType:'text'},
    { key: 'status', label:'Status',inputType:'option'},
    { key: 'orderAmount', label:'Order Amount',inputType:''},
    { key: 'shipByDate', label:'Ship By Date',inputType:'text'},
    { key: 'customer', label:'Customer',inputType:'text'},
    { key: 'deliverySlot', label:'Delivery Slot',inputType:'option'},
    { key: 'orderTag', label:'Order Tag',inputType:'option'},
    { key: 'shipDate', label:'Ship Date',inputType:'text'},
    { key: 'onhold', label:'Onhold',inputType:'option'},
    { key: 'verificationStatus', label:'Verification Status',inputType:'option'},
    { key: 'orderFullfillment', label:'Order Fullfillment Location',inputType:'option'},
    { key: 'orderCurrency', label:'Order Currency Location',inputType:''},
    { key: 'deliveryDate', label:'Delivery Date',inputType:'text'},
    { key: 'externalStatus', label:'External Status',inputType:'text'},
    { key: 'subOrderId', label:'Sub Order ID',inputType:'text'},
    { key: 'orderSource', label:'Order Source',inputType:'option'}
    ];

  export const FailedOrderHeader=[
      { key: 'orderNo', label:'Order No',inputType:'text'},
      {inputType:"text",label:"Channel Order Id",key:"channelOrdId"},
      {inputType:"option",label:"Channel",key:"channel"},
      {inputType:"text",label:"Date Created",key:"createDate"},
      {inputType:"",label:"Remarks",key:"remarks"},
      {inputType:"text",label:"Issue Description",key:"issueDes"},
      ];

  export const CancelledPickedOrderHeader=[
    {inputType:"option",label:"Channel",key:"channel"},
        {inputType:"text",label:"Order No",key:"orderNo"},
        {inputType:"text",label:"Ext Order No",key:"extOrdNo"},
        {inputType:"text",label:"Pick List",key:"pickList"},
        {inputType:"text",label:"SKU Code",key:"skuCode"},
        {inputType:"",label:"Unallocation D",key:"unallocationD"},
        {inputType:"",label:"Big Enquiry",key:"bigEnquiry"},
        {inputType:"option",label:"Previous Status",key:"previousSatus"},
        {inputType:"",label:"SourceWH",key:"sourceWH"},
        {inputType:"text",label:"From LPN",key:"frmLPN"},
        {inputType:"text",label:"To LPN",key:"toLPN"},
        {inputType:"text",label:"Pigeonhole Id",key:"pigeonholeID"},
  ];

  export const FailedShipmentHeader=[
    {inputType:"text",label:"Type",key:"type"},
    {inputType:"option",label:"Channel",key:"channel"},
    {inputType:"option",label:"status",key:"status"},
    {inputType:"text",label:"External Order No",key:"extOrdNo"},
    {inputType:"text",label:"Order No",key:"orderNo"},
    {inputType:"text",label:"Delivery No",key:"deliveryNo"},
    {inputType:"",label:"Pack Date",key:"packDate"},
    {inputType:"",label:"Updated Date",key:"updatedDate"},
    {inputType:"",label:"Error Desc",key:"errorDesc"},
    ];

  export const BuyersHeader=[
    {inputType:"",label:"Buyer Code",key:"buyerCode"},
    {inputType:"text",label:"Buyer Name",key:"buyerName"},
    {inputType:"text",label:"Buyer Description",key:"buyerDesc"},
    {inputType:"text",label:"Phone",key:"phone"},
    {inputType:"text",label:"Email",key:"email"},
    {inputType:"option",label:"Status",key:"status"},
    {inputType:"",label:"Location",key:"location"},
    {inputType:"",label:"Created by",key:"createdBy"},
    {inputType:"",label:"Actions",key:"actions"},
  ];

  export const LinkHeaders=[
    {inputType:"option",label:"Channel",key:"channel"},
    {inputType:"text",label:"Eretail SKU",key:"ertailSKU"},
    {inputType:"text",label:"SKU Description",key:"SKUdesc"},
    {inputType:"text",label:"Channel SKU",key:"channelSKU"},
    {inputType:"",label:"Channel Product Id",key:"prouductId"},
    {inputType:"",label:"Picking Instructor",key:"pickInst"},
    ];

  export const UnmappedSKUheaders=[
    {inputType:"text",label:"SKU Name",key:"SKUname"},
    {inputType:"option",label:"Channel",key:"channel"},
    {inputType:"text",label:"Seller SKU",key:"sellerSKU"},
    {inputType:"",label:"ERetail SKU",key:"ertailSKU"},
    {inputType:"text",label:"Product ID",key:"productId"},
    {inputType:"",label:"Pricing",key:"pricing"},
    {inputType:"",label:"other info",key:"otherInfo"},
    {inputType:"",label:"Action",key:"action"},
    {inputType:"",label:"Created Date",key:"createdDate"},
    ];

    export const POEnquireyHeader=[
    {inputType:"text",label:"PO Code",key:"code"},
    {inputType:"text",label:"PO Date",key:"poDate"},
    {inputType:"text",label:"Expiry Date",key:"expDate"},
    {inputType:"text",label:"Vendor",key:"vendor"},
    {inputType:"option",label:"PO Type",key:"poType"},
    {inputType:"option",label:"Status",key:"status"},
    {inputType:"option",label:"Delivery Location",key:"delivLocation"},
    {inputType:"text",label:"Master PO Code",key:"masterPOCode"},
    ];


    export const ZoneDetailsHeader=[
      {inputType:"text",label:"Zone Code",key:"zoneCode"},
      {inputType:"text",label:"Description",key:"desc"},
      {inputType:"option",label:"Pick To Bin",key:"pickBin"},
      {inputType:"option",label:"Kit To Bin",key:"kitBin"},
      {inputType:"text",label:"Qc Bin",key:"qcBin"},
      {inputType:"option",label:"Status",key:"status"},
      {inputType:"option",label:"Zone Type",key:"zoneType"},
      {inputType:"text",label:"Sortation Zone",key:"sortZone"},
      {inputType:"option",label:"Let Down Bin",key:"letDownBin"},
      {inputType:"",label:"Actions",key:"actions"},
      {inputType:"",label:"Linked Pickers",key:"linkPic"},
      ];
  export const colorsHeaders = [
    { key: 'colorName', label: 'Color Name', isInput: true, type: 'text' }
  ];

  export const customerTypeHeaders = [
    { key: 'customerType', label: 'Customer Type', isInput: true, type: 'text' },
    { key: 'status', label: 'Status', isInput: false,type:'select', options: ['Active', 'Unactive'] }
  ];

  export const stateTypeHeaders = [
    { key: 'country', label: 'country', isInput: false, type: 'select', options: ['India', 'Afganistan', 'Australia', 'USA', 'Europe'] },
    { key: 'state', label: 'state', isInput: true, type: 'text' }
  ];

  export const reasonsHeaders = [
    { key: 'ReasonFor', label: 'reason', isInput: false, type: 'select', options: ['Stock Adjustment Rejection', 'Vendor Return Reason', 'Store Rejection Reason'] },
    { key: 'Reason', label: 'Reason', isInput: true, type: 'text' },
    { key: 'isActive', label: 'isActive', isInput: false, type: 'select',options: ['Yes','No'] }
  ];

  export const tagsHeaders = [
    { key: 'TagFor', label: 'Tags', isInput: false, type: 'select', options: ['ASN Tag', 'PO Tag', 'Location Tag','Picklist Tag','Order Tag'] },
    { key: 'TagName', label: 'Tag Name', isInput: true, type: 'text' },
    { key: 'Status', label: 'Status', isInput: false, type: 'select',options: ['Active','Inactive'] }
  ];

  export const paymentTermsHeaders = [
    { key: 'paymentTerms', label: 'Payment Terms(in Days)', isInput: true, type: 'text' }
  ];

  export const uomHeaders = [
    { key: 'uomCode', label: 'UOM Code', isInput: true, type: 'text' },
    { key: 'extUomCode', label: 'Ext UOM Code', isInput:false, options: [] },
    { key: 'uomName', label: 'UOM Name', isInput: true, type: 'text' },
    { key: 'description', label: 'Description', isInput: true, type: 'text' },
    { key: 'hasDecimal', label: 'Has Decimal', isInput: false, options: [{label:'YES',value:'YES'},{label:'NO',value:'NO'}] },
    { key: 'status', label: 'status', isInput: false, type: 'select', options: [{label:'Active',value:1},{label:'Inactive',value:0}] }
  ];
  
