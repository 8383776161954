
import React, { useState } from 'react'
import { AllSubOrderTabButton } from '../../../OrderButton';
import OrderDetailTab from '../OrderDetailTab/OrderDetailTab';




const PaymentDetails = ({maindata,handleInputChange}) => {
 
      const data=maindata;
      console.log("Payment Detail",data.paymentDetail)
        const header=[{inputType:"option",head:"COD Verified",name:"codVerified",category:"paymentDetail",opt:["Yes","No"]},
            {inputType:"text",head:"Amount Received",name:"amountRece",category:"paymentDetail"},
            {inputType:"option",head:"Payment Type*",name:"paymentType",category:"paymentDetail",opt:["Yes","No"]},
            {inputType:"text",head:"Doc No*",name:"docNo",category:"paymentDetail"},
            {inputType:"text",head:"Doc Date*",name:"docDate",category:"paymentDetail"},
            {inputType:"text",head:"Doc Bank",name:"docBank",category:"paymentDetail"},
            {inputType:"text",head:"IFSC Code",name:"ifscCode",category:"paymentDetail"},
            {inputType:"text",head:"Doc Amount*",name:"docAmount",category:"paymentDetail"},
            {inputType:"text",head:"Branch",name:"branch",category:"paymentDetail"},
            {inputType:"text",head:"Auth Code",name:"authCode",category:"paymentDetail"},
            {inputType:"text",head:"EDC No",name:"edcNo",category:"paymentDetail"},
            {inputType:"textarea",head:"Remark",name:"remarks",category:"paymentDetail"},
        ];


  return (
    <>
    <AllSubOrderTabButton/>
    <div className='flex justify-center'>
  <div className='flex flex-wrap w-full'>
    {/* First Column */}
    <div className=' xl:w-3/4 lg:w-3/4 md:w-2/3 sm:w-full xs:w-1/2 w-full p-2'>
    <div className="flex flex-wrap">
    {header.map((item, index)=>
        (item.inputType==="text"?
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">{item.head==="Status"?<div>          
          <div className="inline-block bg-green-600 text-white px-3 py-1 rounded-l-full relative">
          {data.orderDetail.status}
  <div className="absolute right-0 top-0 h-full w-3 bg-green-600 transform translate-x-full flex items-center">
    <div className="h-0 w-0 border-y-[15px] border-y-transparent border-l-[12px] border-l-white rotate-180"></div>
  </div>
  </div>
          </div>:
        <input type="text" className='border rounded border-gray-200 w-full' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}/> }</div>   
        </div>
      </div>
        :item.inputType==="textarea"?
        <div className="xl:w-full lg:w-full sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className='px-2'>{item.head}:</div>     
        <textarea rows="2" cols="50" className='border border-slate-400 rounded' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
        
      </textarea>
        </div>
      </div>
        :
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">
            <select className="w-full max-w-52 border rounded border-gray-200" name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
            {item.opt.map((stat, idx)=>(<option value={stat} key={idx}>{stat}</option>))}
            </select>
        </div>   
        </div>
      </div>
    )
    )}
    <div className='flex w-full'>
        <div className="ml-auto relative">
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-slate-600 px-3 py-2 text-white font-medium hover:bg-slate-400">
            Add Payments 
        </button>
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400">
            Save Payments 
        </button>
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400">
            Remove Payments
        </button>
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400">
            Reset
        </button>
        </div>
        
    </div>



    </div>
    <div>
    <div className="overflow-x-auto mt-2">
      <table className="min-w-full divide-y divide-gray-200 rounded-lg">
        <thead className="bg-slate-600 text-white">
          <tr>
            <th className="px-6 py-4 whitespace-nowrap text-sm "></th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Payment Type</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Doc No</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Doc Date</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Doc Bank</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">IFSC Code</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Doc Amt</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Branch</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Auth Code</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">EDC No</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Amount Received</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">UDF</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.paymentDetail.paymentdetailAll.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.paymentType}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.docNo}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.docDate}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.docBank}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.ifscCode}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.docAmount}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.branch}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.authCode}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.edcNo}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.amountRece}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.udf}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>

    </div>
    {/* Second Column */}
    <div className='xl:w-1/4 lg:w-1/4 md:w-1/3 sm:w-full xs:w-1/2 w-full'>
      <OrderDetailTab />
    </div>

  </div>
</div>
    </>
  )
}

export default PaymentDetails