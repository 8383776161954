import React, { useState } from 'react'


const BillToTab = ({data,handleInputChange}) => {
    const [isOpen, setIsOpen] = useState(true);
    const [text, setText] = useState('Some text inside the textarea');


    const toggleBox=()=>{
        setIsOpen(!isOpen)

    }
    const categoryNm = "address.billto";


  
    // Helper function to access nested data
    const getNestedValue = (data, categoryPath, fieldName) => {
      const pathArray = categoryPath.split('.');
      let nestedData = data;
  
      pathArray.forEach((key) => {
        if (nestedData[key] !== undefined) {
          nestedData = nestedData[key];
        }
      });}

      const dataarray = [
        { type: "text", head: "Customer Name", name: "customerName", category: categoryNm },
        { type: "textarea", head: "Customer Address 1*", name: "customerAddr1", category: categoryNm },
        { type: "text", head: "Customer Address 2", name: "customerAddr2", category: categoryNm },
        { type: "text", head: "Customer Address 3", name: "customerAddr3", category: categoryNm },
        { type: "text", head: "Customer Address 4", name: "customerAddr4", category: categoryNm },
        { type: "text", head: "Land Mark", name: "landmark", category: categoryNm },
        { type: "text", head: "Latitude", name: "latitude", category: categoryNm },
        { type: "text", head: "Longitude", name: "longitude", category: categoryNm },
        { type: "text", head: "Phone*", name: "phone", category: categoryNm },
        { type: "text", head: "Alternate Phone", name: "altphone", category: categoryNm },
        { type: "option", head: "Country*", opt: ["India", "Nepal", "Bhutan", "Thailand"], name: "country", category: categoryNm },
        { type: "option", head: "State*", opt: ["Madhya Pradesh", "Delhi", "Goa", "Uttar Pradesh"], name: "state", category: categoryNm },
        { type: "text", head: "City", name: "city", category: categoryNm },
        { type: "text", head: "PinCode*", name: "pincode", category: categoryNm },
        { type: "text", head: "Email*", name: "email", category: categoryNm },
        { type: "text", head: "Alternate Email", name: "altEmail", category: categoryNm },
        { type: "option", head: "Address Type", opt: ["Other", "Temporary", "Permanent"], name: "addType", category: categoryNm },
        { type: "text", head: "Customer GSTIN/TIN", name: "customerGST/TIN", category: categoryNm },
        { type: "option", head: "Geo Type", opt: ["--Select--", "Graphic"], name: "geoType", category: categoryNm },
        { type: "text", head: "Geo Address", name: "geoAddr", category: categoryNm },
        { type: "text", head: "Geo Latitude", name: "geoLat", category: categoryNm },
        { type: "text", head: "Geo Longitude", name: "geoLong", category: categoryNm },
      ];



 

  return (
    <>
    <div className='bg-white shadow-lg mt-2 '>
        <div className='bg-gray-300 shadow-sm p-1 flex justify-between'>
              <strong>Bill To</strong>
              <div className='' onClick={toggleBox}><strong>+</strong></div>
        </div>
        <div className={`${isOpen ? 'h-auto' : 'max-h-0'} transition-max-height duration-500 ease-in-out overflow-hidden`}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {dataarray.map((item, index) => {
            const value = getNestedValue(data, item.category, item.name);

            return (
              <div key={index} className="px-2 py-2">
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={item.name}>
                  {item.head}
                </label>
                {item.type === "text" && (
                  <input
                    type="text"
                    id={item.name}
                    className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    name={item.name}
                    value={value}
                    onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}
                  />
                )}
                {item.type === "textarea" && (
                  <textarea
                    id={item.name}
                    rows="3"
                    className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    name={item.name}
                    value={value}
                    onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}
                  />
                )}
                {item.type === "option" && (
                  <select
                    id={item.name}
                    className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    name={item.name}
                    value={value}
                    onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}
                  >
                    <option value="">Select {item.head}</option>
                    {item.opt.map((optItem, optIndex) => (
                      <option value={optItem} key={optIndex}>
                        {optItem}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            );
          })}
        </div> 
        </div>
        </div>
    </>
  )
}

export default BillToTab