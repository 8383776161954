import React, { useEffect, useState } from 'react'
import { OrderFailedButton } from '../../Order Enquiery/OrderButton';
import { UnmappedTabButton } from '../ChannelListingButtons';
import DataTableTwo from '../../../../../common/DataTableTwo';
import { UnmappedSKUheaders } from '../../../../../../data/Headers';


const intialarry=[
    {id:1,SKUname:"",channel:"M06-Tata 1MG",sellerSKU:"",ertailSKU:"",productId:"",pricing:"",otherInfo:"",action:"",createdDate:""},
    {id:2,SKUname:"",channel:"M05-Tata 1MG",sellerSKU:"",ertailSKU:"",productId:"",pricing:"",otherInfo:"",action:"",createdDate:""}
];

const UnmappedSKUTab = () => {
  const [key, setKey] = useState(0);   
  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };
        


  return (
    <>
    <UnmappedTabButton resetHandler={resetTable}/>
    <div key={key}>
      <DataTableTwo id='7' headers={UnmappedSKUheaders} data={intialarry} name="Unmapped SKU" title="Unmapped SKU" />
      </div>
    </>
  )
}

export default UnmappedSKUTab;

