import { createSlice } from "@reduxjs/toolkit";

const orderEnquiryData={

    "orderNo":1,
    "orderDetail":{
       "orderDate":"",
       "orderAmount":"",
       "taxAmount":"",
       "otherTaxAmount":"",
       "discountAmount":"",
       "verificationStatus":"",
       "actualShipDate":"",
       "baseCurrency":"",
       "convRate":"",
       "discountCode":"",
       "amount":"",
       "deliveryDate":"",
       "siteLocation":"",
       "paymentType":"",
       "customerType":"",
       "customerCode":"",
       "onholdStatus":"",
       "sendNotification":"",
       "status":"Yes",
       "Remark":""
    },
    "skuDetail":[{
      "skuCode":"",
       "skuname":"",
       "size":"",
       "image":"img_girl.jpg",
       "status":"",
       "orderQty":{
          "uom":"",
          "uomQty":"",
          "eaQty":""
       },
       "inProcessQty":{
          "openQty":"",
          "commitedQty":"",
          "pickedQty":""
       },
       "completedQty":{
          "shippedQty":"",
          "returnQty":"",
          "closedQty":""
       },
       "unitPrice":"",
       "discount":"",
       "taxes":"",
       "liveAmount":"",
       "source":"",
      },],
   "address":{
          "addVerified":"",
          "addressdetail":"No",
          "addressRemark":"",
          "shipto":{
             "customerName":"Costa Rica",
             "customerAddr1":"",
             "customerAddr2":"",
             "customerAddr3":"",
             "customerAddr4":"",
             "landmark":"",
             "latitude":"",
             "longitude":"",
             "phone":"",
             "altphone":"",
             "country":"",
             "state":"",
             "city":"",
             "pincode":"",
             "email":"",
             "altEmail":"",
             "addType":"",
             "customerGST/TIN":"",
             "geoType":"",
             "geoAddr":"",
             "geoLat":"",
             "geoLong":""
          },
          "billto":{
             "customerName":"",
             "customerAddr1":"",
             "customerAddr2":"",
             "customerAddr3":"",
             "customerAddr4":"",
             "landmark":"",
             "latitude":"",
             "longitude":"",
             "phone":"",
             "altphone":"",
             "country":"",
             "state":"",
             "city":"",
             "pincode":"",
             "email":"",
             "altEmail":"",
             "addType":"",
             "customerGST/TIN":"",
             "geoType":"",
             "geoAddr":"",
             "geoLat":"",
             "geoLong":""
          }},
          "outbondDelivery":[{
             "deliveryNo":"",
             "deliveryDate":"",
             "deliveryStat":"",
             "sourceWH":"",
             "transportCode":"",
             "awbNo":"",
             "transStatus":"",
             "manifestNo":"",
             "transRemark":"",
             "invoiceNo":"",
             "orderShipment":"",
             "weight":"",
             "qty":"",
             "udf":""
          }],
          "activityAndRemark":{
             "orderComment":"",
             "activityLog":[{
             "activityDate":"",
             "activityLogs":"",
             "activityUser":""}]
          },
          "paymentDetail":{
             "codVerified":"",
             "remarks":"",
             "amountRece":"",
             "paymentType":"",
             "docNo":"",
             "docDate":"",
             "docBank":"",
             "ifscCode":"",
             "docAmount":"",
             "branch":"",
             "authCode":"",
             "edcNo":"",             
            "paymentdetailAll":[{
            "paymentType":"",
            "amountRece":"",
             "docNo":"",
             "docDate":"",
             "docBank":"",
             "ifscCode":"",
             "docAmount":"",
             "branch":"",
             "authCode":"",
             "edcNo":"",
             "udf":"",
             }]
          }

 }

const initialState = {
  data: orderEnquiryData,
  
  loading: false,
};

const orderEnquirySlice = createSlice({
   name: "orderEnquiry",
   initialState: initialState,
   reducers: {
     setOrderEnquiry(state, value) {
       state.user = value.payload;
     },
     setOrderEnquiryLoading(state, value) {
       state.loading = value.payload;
     },
   },
});
const orderEnquirySliceReducer=orderEnquirySlice.reducer;
export const { setOrderEnquiry, setOrderEnquiryLoading} = orderEnquirySlice.actions;
export default orderEnquirySliceReducer;
