import { createSlice } from "@reduxjs/toolkit";

const wmsdata={
    "zone":[{
        "zoneCode":"Return",
        "desc":"Return Zone",
        "pickBin":"PackBin",
        "kitBin":"",
        "qcBin":"",
        "status":"Active",
        "zoneType":"Normal",
        "sortZone":"",
        "letDownBin":"",
        "actions":"",
        "linkPic":"dfgh",
        },
        {
            "zoneCode":"Return",
            "desc":"Purchase Zone",
            "pickBin":"PackBin",
            "kitBin":"",
            "qcBin":"",
            "status":"Active",
            "zoneType":"Normal",
            "sortZone":"",
            "letDownBin":"",
            "actions":"",
            "linkPic":"dfgh",
            },]
}


const initialState = {
    data: wmsdata,    
    loading: false,
  };
  
  const wmsSlice = createSlice({
     name: "wms",
     initialState: initialState,
     reducers: {
       setwms(state, value) {
         state.user = value.payload;
       },
       setwmsLoading(state, value) {
         state.loading = value.payload;
       },
     },
  });
  const wmsSliceReducer=wmsSlice.reducer;
  export const { setwms, setwmsLoading} = wmsSlice.actions;
  export default wmsSliceReducer;