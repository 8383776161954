import React from 'react'

const DataDispaly = ({mainhead,topcontent,content,openModal,isOpen,tooglebox}) => {
  return (
    <>
    <div className='bg-white shadow-sm mt-2'>
        <div className='bg-gray-300 shadow-sm p-1 flex justify-between'>
              <strong>{mainhead}</strong>
              <div className='' onClick={tooglebox}><strong>+</strong></div>
        </div>
        <div className={`${isOpen ? 'h-auto' : 'max-h-0'} transition-max-height duration-500 ease-in-out overflow-hidden`}>

        {topcontent &&
        (<div className='p-2 flex justify-normal'>
        <label className="block text-lg font-large text-gray-700">{topcontent.heading} </label>     
            <div className="my-auto" ><div className='px-3 font-bold'>{topcontent.subhead}</div></div>
            <div className="my-auto" ><div className='px-3'><img className="inline" src="..." alt="..."/></div></div>
        </div>)}
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" >
            {content.map((item,index)=>(
                <div key={index}>
                    <div className="px-2 py-2">
                    <label className="block text-sm font-medium text-gray-700">{item.head}</label>

                        {item.type==="option"?                 
                                <select className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">        
                                {item.opt.map((itm,ind)=>(<option >{itm}</option>))}  
                                </select>
                        : item.type==="text"?
                            <input  type="text" className="mt-1 p-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" value={item.val}/>
                        : 
                            <div className="mt-1 p-2 w-full text-blue-600 visited:text-purple-600" onClick={openModal}>Configure Interface</div> 
                        }
                    </div>
                </div>
            ))}
        </div>

        </div>
        </div>
    </>
  )
}

export default DataDispaly