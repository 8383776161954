import React from 'react'
import PurchaseOrdTable from './PurchaseOrdTable';
import DetailSummary from '../../../../../../common/DetailSummary';
import PurchasepoDetails from './PurchaseOrderDetails';

const PurchaseOrderTab = ({maindata,handleInputChange}) => {
  const data=maindata;


    const header=[{inputType:"text",head:"Vendor Code*",name:"code",category:"code"},
            {inputType:"option",head:"Delivery Location",name:"deliveryLoc",category:"deliveryLoc",opt:["Baby & Mom Retail Pvt. Ltd.-Delhi","No"]},
            {inputType:"option",head:"PO Type",name:"poType",category:"poType",opt:["Outright","Watching"]},
            {inputType:"option",head:"Buyer Code*",name:"buyerCode",category:"buyerCode",opt:["--Select--",""]},
            {inputType:"option",head:"Recv Valid Code*",name:"recvValidCode",category:"recvValidCode", opt:["Mfg date"]},
            {inputType:"text",head:"External PO No",name:"extPONo",category:"extPONo"},
            {inputType:"text",head:"Reference No",name:"refNo",category:"refNo"},
            
            {inputType:"option",head:"Status",name:"status",category:"status",opt:["Active","Inactive"]},
            {inputType:"text",head:"Exp. Delivery Date",name:"expDeliveryDate",category:"expDeliveryDate"},
            {inputType:"text",head:"Expiry Date",name:"expDate",category:"expDate"},
            {inputType:"textarea",head:"Notes",name:"note",category:"note"},
        ];


  return (
    <>
    <div className='flex justify-center'>
  <div className='flex flex-wrap w-full'>
    {/* First Column */}
    <div className=' xl:w-3/4 lg:w-3/4 md:w-2/3 sm:w-full xs:w-1/2 w-full p-2'>
    <div className="flex flex-wrap">
    {header.map((item, index)=>
        (item.inputType==="text"?
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">{item.head==="Status"?<div>          
          <div className="inline-block bg-green-600 text-white px-3 py-1 rounded-l-full relative">
          "yes"
        <div className="absolute right-0 top-0 h-full w-3 bg-green-600 transform translate-x-full flex items-center">
          <div className="h-0 w-0 border-y-[15px] border-y-transparent border-l-[12px] border-l-white rotate-180"></div>
        </div>
        </div>
          </div>:
        <input type="text" className='border rounded border-gray-200 w-full' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}/> }</div>   
        </div>
      </div>
        :item.inputType==="textarea"?
        <div className="xl:w-full lg:w-full sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className='px-2'>{item.head}:</div>     
        <textarea rows="2" cols="50" className='border border-slate-400 rounded' name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
        
        </textarea>
        </div>
      </div>
        :
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">
            <select className="w-full max-w-52 border rounded border-gray-200" name={item.name} value={item.name===item.category?data[item.category]:data[item.category][item.name]}  onChange={(e) => handleInputChange(item.name, e.target.value, item.category)}>
            {item.opt.map((stat, idx)=>(<option value={stat} key={idx} className='w-full'>{stat}</option>))}
            </select>
        </div>   
        </div>
      </div>
    )
    )}
    <div className='flex w-full mb-3'>
        <div className="ml-auto relative">
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-slate-600 px-3 py-2 text-white font-medium hover:bg-slate-400">
            Add SKU 
        </button>
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400">
            Remove SKU
        </button>
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400">
            Import
        </button>
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400">
            Clear Discount
        </button>
        </div>        
    </div>


    </div>

    <div >
      <PurchaseOrdTable maindata={maindata}/>
    </div>

    </div>
    {/* Second Column */}
    <div className='xl:w-1/4 lg:w-1/4 md:w-1/3 sm:w-full xs:w-1/2 w-full'>
      <PurchasepoDetails maindata={maindata}/>
    </div>

  </div>


</div>

    </>
  )
}

export default PurchaseOrderTab