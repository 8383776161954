import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const TextEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      className="bg-white h-96 w-full"  // Fixed height and full width
      theme="snow"
      placeholder="Write something..."
    />
  );
};

export default TextEditor;
