import { useCallback } from 'react';
import ExcelJS from 'exceljs';

const useExportToExcel = (headers, name, currentItems) => {
  const exportToExcel = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add header row with modern styling
    worksheet.columns = headers.map(header => ({
      header: header.label,
      key: header.key,
      width: 25,
    }));

    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true, color: { argb: 'FFFFFF' } }; // White text color
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4F81BD' } // Dark blue background
    };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.height = 20;

    // Freeze the header row
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    // Add data rows with alternate row colors
    currentItems.forEach((item, index) => {
      const row = worksheet.addRow(headers.map(header => item[header.key] || ''));
      // Alternate row color
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: index % 2 === 0 ? 'DDEBF7' : 'FFFFFF' } // Light blue and white
      };
    });

    // Auto-fit columns
    worksheet.columns.forEach(column => {
      column.width = Math.max(column.width, column.values.reduce((acc, curr) => {
        return Math.max(acc, curr.toString().length + 2);
      }, 10));
    });

    // Add a footer row indicating the sheet is system-generated
    const footerRow = worksheet.addRow([`This is a system-generated document - ${new Date().toLocaleDateString()}-Waviator Solutions`]);
    footerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    footerRow.font = { italic: true, color: { argb: '808080' } }; // Gray text color
    worksheet.mergeCells(`A${footerRow.number}:Z${footerRow.number}`);
    footerRow.height = 20;

    // Export to Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${name}_data.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  }, [headers, name, currentItems]);

  return exportToExcel;
};

export default useExportToExcel;
