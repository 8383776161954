import React, { useEffect } from 'react'
import Tabs from '../../../../common/Tabs'
import { OrdertabsConfig } from '../../../../../data/tabsData'
import { useSelector } from 'react-redux'





const MainPage = () => {
  const orderEnquiries = useSelector((state) => state.orderEnquiries.data);
  const maindata=orderEnquiries;


  return (
    <>
    <div className='bg-white shadow-lg'>
       <Tabs data={OrdertabsConfig} maindata={maindata}/>     
    </div>
    
    </>
  )
}

export default MainPage