import React, { useState } from 'react'

const ActivityAndRemark = ({maindata,handleInputChange}) => {   
    const data=maindata;
    const name="orderComment";
    const category="activityAndRemark";
    const comment=data.activityAndRemark.orderComment;
    const [txtcomment,settxtcomment]=useState(comment);

    // handleInputChange("orderComment", e.target.value, category)}

    const submitComm=()=>{
        handleInputChange("orderComment", txtcomment, category)
    }

  return (
    <>
    <div className=" mx-auto py-0">
        <div>
        <h1 className='font-medium w-full'>Add Another Comments</h1>     
        <textarea  className='border border-slate-400 rounded w-full' value={txtcomment}  onChange={(e) => settxtcomment(e.target.value)}></textarea>
        <div>
        <div className='flex'>
    <button type="button" className="ml-auto relative rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400" onClick={submitComm}>
        Submit Comment 
    </button>
        </div>
        
        </div>
        </div>
        <div className="overflow-x-auto mt-2">
      <table className="min-w-full divide-y divide-gray-200 rounded-lg">
        <thead className="bg-slate-600 text-white">
          <tr>
            <th className="px-6 py-4 whitespace-nowrap text-sm "></th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Activity Date</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Activity Logs</th>
            <th className="px-6 py-4 whitespace-nowrap text-sm ">Activity User</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.activityAndRemark.activityLog.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.activityDate}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.activityLogs}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.activityUser}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
        </div>
    </>
  )
}

export default ActivityAndRemark