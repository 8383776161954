import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { brandHeaders } from '../../../../data/Headers';
import DataTableModal from '../../../common/DataTableModal';
import { brandpoints } from '../../../../services/apis';
import { apiConnector } from '../../../../services/apiconnector';
import { useSelector } from 'react-redux';
import { createBrands,updateBrands } from '../../../../services/operations/brandsApi';
import Loading from '../../../common/Loading';

// Fetch brands function
const fetchBrands = async (token) => {
  const response = await apiConnector('GET', brandpoints.SHOW_ALL_BRANDS, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data=response.data.data;
  console.log(response);
  return data.map(item => ({
    id: item.id,
    brandDescription: item.name,
    externalBrand: item.description || '',
    status: item.is_active,
  }));
};

const Brands = () => {
  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  const { data: brandData, error, isLoading } = useQuery({
    queryKey: ['brands', token],
    queryFn: () => fetchBrands(token),
    enabled: !!token,
  });

  if (isLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading/></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddNew = async (newData) => {
    await createBrands(newData, token);
    queryClient.invalidateQueries(['brands']); 
  };

  const handleUpdate = async (id, updatedData) => {
    await updateBrands(id, updatedData, token);
    queryClient.invalidateQueries(['brands']);
  };

  return (
    <div className="rounded-lg p-4 mt-2">
      <DataTableModal
        id='1'
        isAddEnabled={true}
        headers={brandHeaders}
        data={brandData || []}
        isDownloadEnabled={true}
        title="New"
        name="Brands"
        onAddNew={handleAddNew}
        onUpdate={handleUpdate} // Ensure this function matches the expected format
      />
    </div>
  );
};

export default Brands;
