import React, { useState } from 'react'
import { PurchaseOrderTabsConfig } from '../../../../../../../data/tabsData'
import Tabs from '../../../../../../common/Tabs'
import { useSelector } from 'react-redux';

const PurchaseOrderMain = () => {
  const poDetailData = useSelector((state) => state.poDetails.data);
  // const loading = useSelector((state) => state.orderEnquiery.loading);

  const [data, setData] = useState(poDetailData);

  // console.log("data",data)

  const handleInputChange = (name, value, category) => {
    // console.log(name,value,category)
    const isNumberField = [""].includes(name);
    const parsedValue = isNumberField ? parseFloat(value) || "" : value;
  
    const categoryParts = category.split('.');
    if (category===name && category!="commentHistory"){console.log("rice",value)}
    else if (category==="commentHistory"){console.log("valid for category")};
    if (category===name && category!="commentHistory"){
      console.log("category and name are same")
        setData((prevData) => ({
          ...prevData,
          [name]: parsedValue, // Update directly without category nesting
        }));
    } else if (categoryParts.length===1 && category!="commentHistory"){
      setData((prevData) => ({
        ...prevData,
        [category]: {
          ...prevData[category],
          [name]: parsedValue,
        },
      }));
    } else if (category==="commentHistory") {
      console.log("indonisia")
      // const currentComment = newComment;

      setData((prevData) => ({
        ...prevData, // Spread previous state
        commentHistory: [
          ...prevData[category], // Spread existing comments
          {
            name: value.name || "Anonymous",
            comment: value.comment,
          }
        ]
      }));
        console.log("data updated",data)
    } else {
      console.log("else is running ")
      const updatedData = { ...data };
      let targetObj = categoryParts.reduce((obj, key) => {
        // Ensure we are working with a copy at each level
        obj[key] = { ...obj[key] };
        return obj[key];
    }, updatedData);
    // Update the target value
    targetObj[name] = value;

    // Update the state with the new data object
    setData(updatedData);


  }

  };


  return (
    <>
    <div className='bg-white shadow-lg'>
      <Tabs data={PurchaseOrderTabsConfig} maindata={data} handleInputChange={handleInputChange}/>
    </div>
    </>
  )
}

export default PurchaseOrderMain