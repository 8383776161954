import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { uomHeaders } from '../../../../data/Headers';
import DataTableModal from '../../../common/DataTableModal';
import { uomPoints } from '../../../../services/apis';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiConnector } from '../../../../services/apiconnector';
import { createUnits,updateUnits } from '../../../../services/operations/uomApi';
import Loading from '../../../common/Loading';

const fetchUOM = async (token) => {
  const response = await apiConnector('GET', uomPoints.SHOW_ALL_UNITS, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data = response?.data?.data;
  return data.map(item => ({
    id: item.id,
    uomCode:item.code,
    uomName: item.name,
    description: item.description,
    hasDecimal: item.has_decimal == '1'? 'YES' : 'NO',
    status: item.is_active,
  }));
};
const UOM = () => {
  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();

  const { data: uomData, error: uomError, isLoading: isUOMLoading } = useQuery({
    queryKey: ['uom', token],
    queryFn: () => fetchUOM(token),
    enabled: !!token,
  });


  // Handle loading and error states
  if (isUOMLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading /></div>;
  }

  if (uomError) {
    return <div>Error: {uomError?.message || uomError?.message}</div>;
  }

  // Handle adding new state
  const handleAddNew = async (newData) => {
    await createUnits(newData, token);
    queryClient.invalidateQueries(['uom']); 
  };

  // Handle updating state
  const handleUpdate = async (id, updatedData) => {
    await updateUnits(id, updatedData, token);
    queryClient.invalidateQueries(['uom']);
  };

  // const uomData = [
  //       {
  //         uomCode: 'UOM001',
  //         extUomCode: 'Option1',
  //         uomName: 'Kilogram',
  //         description: 'Unit for measuring weight',
  //         hasDecimal: 'Yes',
  //         status: 'Active'
  //       },
  //       {
  //         uomCode: 'UOM002',
  //         extUomCode: 'Option2',
  //         uomName: 'Liter',
  //         description: 'Unit for measuring volume',
  //         hasDecimal: 'Yes',
  //         status: 'Active'
  //       },
  //       {
  //         uomCode: 'UOM003',
  //         extUomCode: 'Option3',
  //         uomName: 'Piece',
  //         description: 'Unit for individual items',
  //         hasDecimal: 'No',
  //         status: 'Inactive'
  //       },
  //       {
  //         uomCode: 'UOM004',
  //         extUomCode: 'Option1',
  //         uomName: 'Meter',
  //         description: 'Unit for measuring length',
  //         hasDecimal: 'Yes',
  //         status: 'Active'
  //       },
  //       {
  //         uomCode: 'UOM005',
  //         extUomCode: 'Option2',
  //         uomName: 'Box',
  //         description: 'Unit for packaging items',
  //         hasDecimal: 'No',
  //         status: 'Inactive'
  //       }
  //     ];
  return (
    <div className="rounded-lg p-4 mt-2">
      <DataTableModal id='4' isAddEnabled={true} headers={uomHeaders} data={uomData}  title="New" onAddNew={handleAddNew} 
        onUpdate={handleUpdate} />
    </div>
  );
};

export default UOM;
