import React, { useEffect, useState } from 'react'


const ZoneModal = ({closeModal,modalData}) => {


  const header=[{inputType:"text",head:"Zone Code*",name:"zoneCode",category:"zone"},
    {inputType:"text",head:"Zone Description",name:"desc",category:"zone"},
    {inputType:"option",head:"Pick to Bin",name:"pickBin",category:"zone",opt:["Yes","No"]},
    {inputType:"option",head:"KIT To Loc",name:"kitBin",category:"zone",opt:["Yes","No"]},
    {inputType:"option",head:"Is Active",name:"status",category:"zone",opt:["Yes","No"]},
    {inputType:"option",head:"Let Down Bin",name:"letDownBin",category:"zone",opt:["Yes","No"]},
    {inputType:"option",head:"QC Bin",name:"qcBin",category:"zone",opt:["Yes","No"]},
    {inputType:"text",head:"Pic Order Types",name:"linkPic",category:"zone"},
    {inputType:"option",head:"Zone Type",name:"zoneType",category:"zone",opt:["Yes","No"]},
    {inputType:"option",head:"Sortation Zone",name:"sortZone",category:"zone",opt:["Yes","No"]},
];

const [data, setData] = useState(modalData);

useEffect(() => {
}, [data]);

const changeHandler = (value, name) => {
  setData((prevData) => ({
    ...prevData,
    [name]: value, 
  }));
};

  

  return (
    <>
    <div className='w-3/5 mx-auto'>
    <div className='bg-slate-700 text-white p-1 text-lg'>Zone Create/Edit</div>
    <div className="flex flex-wrap bg-white p-5">
    {header.map((item, index)=>
        (item.inputType==="text"?
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">{item.head==="Status"?<div>          
          <div className="inline-block bg-green-600 text-white px-3 py-1 rounded-l-full relative">
          ok
            <div className="absolute right-0 top-0 h-full w-3 bg-green-600 transform translate-x-full flex items-center">
              <div className="h-0 w-0 border-y-[15px] border-y-transparent border-l-[12px] border-l-white rotate-180"></div>
            </div>
          </div>
          </div>:
        <input type="text" className='border rounded border-gray-200 w-full' defaultValue={data[item.name]} onChange={(e)=>changeHandler(e.target.value,item.name)}/> }</div>   
        </div>
      </div>
        :item.inputType==="textarea"?
        <div className="xl:w-full lg:w-full sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className='px-2'>{item.head}:</div>     
        <textarea rows="2" cols="50" className='border border-slate-400 rounded' >
        </textarea>
        </div>
      </div>
        :
        <div className="xl:w-1/2 lg:w-1/2 sm:w-full w-full p-1" key={index}>
        <div className="flex flex-wrap">
        <div className="sm:w-1/2 w-full"><div className='px-2'>{item.head}:</div></div>
        <div className="sm:w-1/2 w-full">
            <select className="w-full max-w-52 border rounded border-gray-200" defaultValue={data[item.name] || ''} onChange={(e)=>changeHandler(e.target.value,item.name)}>
            {item.opt.map((stat, idx)=>(<option value={stat} key={idx}>{stat}</option>))}
            </select>
        </div>   
        </div>
      </div>
    )
    )}

<div className="flex justify-end mt-4">
          <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition" onClick={()=>{console.log(data)}}>Save</button>
          <button onClick={closeModal} className="bg-gray-600 text-white px-4 py-2 rounded ml-2 hover:bg-gray-700 transition">Cancel</button>
        </div>
</div>
    </div>
    </>
  )
}

export default ZoneModal