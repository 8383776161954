import React, { useState } from 'react'

const CreateEditBuyer = ({closeModal}) => {
    const [currentTab,setCurrentTab]=useState("CreateEdit")

    const TabStatus=(tab)=>{
        if (tab==="CreateEdit"){setCurrentTab("CreateEdit")}
        else {setCurrentTab("UserField")}
    }

    const inputfields=[{type:"text",name : "Buyer Code", key:"buyerCode"},{type:"text",name : "Buyer Name", key:"buyerName"},{type:"text",name : "Buyer Description", key:"buyerDesc"},{type:"text",name : "Email*", key:"email"},{type:"text",name : "Phone*", key:"phone"},{type:"text",name : "Alternate Phone", key:"alternatePhn"},{type:"option",name : "Is Active", key:"isActive",option:["Yes","No"]},{type:"text",name : "Category", key:"category"}]

  return (
    <>
    <div className='w-1/2 mx-auto border rounded bg-gray-100'>
        <div className='pt-1 mx-4 flex bg-gray-100'>
            <div onClick={()=>TabStatus("CreateEdit")} className={`${currentTab === "CreateEdit" ? 'bg-white font-medium' : 'bg-gray-100'} p-2`}>Create/Edit</div>
            <div onClick={()=>TabStatus("UserField")} className={`${currentTab === "UserField" ? 'bg-white font-medium' : 'bg-gray-100'} p-2`}>User Defined Fields</div>
        </div>
        <div>
            {currentTab === "CreateEdit"?
            <div className='w-full p-2 bg-white'>
                {inputfields.map((item,idx)=>(
                    <div className='flex w-full py-1' key={idx}>
                    <label className="block w-2/5 text-gray-700 px-3 font-medium mb-2 my-auto">{item.name}</label>
                    {item.type==="text"?
                    <input
                    type="text"
                    name={item.name}
                    className="px-3 w-3/5 py-1 border border-gray-300 rounded-md shadow-sm"
                />
                    :
                    <select
                    name={item.name}
                    className="px-3 w-3/5 py-2 border border-gray-300 rounded-md shadow-sm"
          >
            {item.option.map((opt,idx)=>(<option value={opt} key={idx}>{opt}</option>))}
            
            {/* Add options as needed */}
          </select>
                    }
                    
                    </div>
                    ))}
                    <div className='flex w-full'>
        <div className="ml-auto relative">
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-slate-600 px-3 py-2 text-white font-medium hover:bg-slate-400">
            Save 
        </button>
        <button type="button" className="mx-1 rounded-md border-slate-300 border bg-gray-300 px-3 py-2 font-medium hover:bg-slate-400" onClick={closeModal}>
            Close 
        </button>
        </div>
        </div>
            </div>
            :
            <div>no</div>}
        </div>
    </div>
    </>
  )
}

export default CreateEditBuyer