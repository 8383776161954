const BASE_URL = "https://api.waviatorecom.com";

export const endpoints = {
  LOGIN_API: BASE_URL + "/api/login",
  LOGOUT_API: BASE_URL + "/api/logout",
};

export const brandpoints = {
  SHOW_ALL_BRANDS:BASE_URL + "/api/brands",
  CREATE_BRANDS:BASE_URL + "/api/brands",
  SHOW_BRAND:BASE_URL +"/api/brands/:id",
  UPDATE_BRAND:BASE_URL +"/api/brands",
  DELETE_BRAND:BASE_URL +"/api/brands/:id",
  SEARCH_BRAND:BASE_URL +"/api/brands/search"
}

export const materialpoints = {
  SHOW_ALL_MATERIALS:BASE_URL + "/api/materials",
  CREATE_MATERIALS:BASE_URL + "/api/materials",
  SHOW_MATERIAL:BASE_URL +"/api/materials/:id",
  UPDATE_MATERIAL:BASE_URL +"/api/materials",
  DELETE_MATERIAL:BASE_URL +"/api/materials/:id",
  SEARCH_MATERIAL:BASE_URL +"/api/materials/search"
}

export const sizeGrouppoints = {
  SHOW_ALL_SIZE_GROUPS:BASE_URL + "/api/size-groups",
  CREATE_SIZE_GROUPS:BASE_URL + "/api/size-groups",
  SHOW_SIZE_GROUPS:BASE_URL +"/api/size-groups/:id",
  UPDATE_SIZE_GROUPS:BASE_URL +"/api/size-groups",
  DELETE_SIZE_GROUPS:BASE_URL +"/api/size-groups/:id",
  SEARCH_SIZE_GROUPS:BASE_URL +"/api/size-groups/search"
}

export const sizePoints = {
  SHOW_ALL_SIZE:BASE_URL + "/api/sizes",
  CREATE_SIZE:BASE_URL + "/api/sizes",
  SHOW_SIZE:BASE_URL +"/api/sizes/:id",
  UPDATE_SIZE:BASE_URL +"/api/sizes",
  DELETE_SIZE:BASE_URL +"/api/sizes/:id",
  SEARCH_SIZE:BASE_URL +"/api/sizes/search"
}

export const colorPoints = {
  SHOW_ALL_COLORS:BASE_URL + "/api/colors",
  CREATE_COLORS:BASE_URL + "/api/colors",
  SHOW_COLORS:BASE_URL +"/api/colors/:id",
  UPDATE_COLORS:BASE_URL +"/api/colors",
  DELETE_COLORS:BASE_URL +"/api/colors/:id",
  SEARCH_COLORS:BASE_URL +"/api/colors/search"
}


export const statePoints = {
  SHOW_ALL_STATES:BASE_URL + "/api/states",
  CREATE_STATES:BASE_URL + "/api/states",
  SHOW_STATES:BASE_URL +"/api/states/:id",
  UPDATE_STATES:BASE_URL +"/api/states/",
  DELETE_STATES:BASE_URL +"/api/states/:id",
  SEARCH_STATES:BASE_URL +"/api/states/search"
}

export const countryPoints = {
  SHOW_ALL_COUNTRY:BASE_URL + "/api/countries",
  CREATE_COUNTRY:BASE_URL + "/api/countries",
  SHOW_COUNTRY:BASE_URL +"/api/countries/:id",
  UPDATE_COUNTRY:BASE_URL +"/api/countries",
  DELETE_COUNTRY:BASE_URL +"/api/countries:id",
  SEARCH_COUNTRY:BASE_URL +"/api/countries/search"
}

export const uomPoints = {
  SHOW_ALL_UNITS:BASE_URL + "/api/units",
  CREATE_UNITS:BASE_URL + "/api/units",
  SHOW_UNITS:BASE_URL +"/api/units/:id",
  UPDATE_UNIT:BASE_URL +"/api/units",
  DELETE_UNIT:BASE_URL +"/api/units:id",
  SEARCH_UNIT:BASE_URL +"/api/unit/search"
}

export const taxPoints = {
  SHOW_ALL_TAX:BASE_URL + "/api/tax_categories",
  CREATE_TAX:BASE_URL + "/api/tax_categories",
  SHOW_TAX:BASE_URL +"/api/tax_categories/:id",
  UPDATE_TAX:BASE_URL +"/api/tax_categories",
  DELETE_TAX:BASE_URL +"/api/tax_categories/:id",
}

export const taxCodePoints = {
  SHOW_ALL_TAX_CODE:BASE_URL + "/api/tax_codes",
  CREATE_TAX_CODE:BASE_URL + "/api/tax_codes",
  SHOW_TAX_CODE:BASE_URL +"/api/tax_codes/:id",
  UPDATE_TAX_CODE:BASE_URL +"/api/tax_codes",
  DELETE_TAX_CODE:BASE_URL +"/api/tax_codes/:id",
}

export const taxZonePoints = {
  SHOW_ALL_TAX_ZONES:BASE_URL + "/api/tax_zones",
  CREATE_TAX_ZONES:BASE_URL + "/api/tax_zones",
  SHOW_TAX_ZONES:BASE_URL +"/api/tax_zones/:id",
  UPDATE_TAX_ZONES:BASE_URL +"/api/tax_zones",
  DELETE_TAX_ZONES:BASE_URL +"/api/tax_zones/:id",
}

export const paymentTermPoints = {
  SHOW_ALL_PAYMENT_TERMS:BASE_URL + "/api/payment_terms",
  CREATE_PAYMENT_TERMS:BASE_URL + "/api/payment_terms",
  SHOW_PAYMENT_TERMS:BASE_URL +"/api/payment_terms/:id",
  UPDATE_PAYMENT_TERMS:BASE_URL +"/api/payment_terms",
  DELETE_PAYMENT_TERMS:BASE_URL +"/api/payment_terms/:id",
}

export const LocationPoints = {
  SHOW_ALL_LOCATIONS:BASE_URL + "/api/locations",
  CREATE_LOCATION:BASE_URL + "/api/locations",
  SHOW_LOCATION:BASE_URL +"/api/locations/:id",
  UPDATE_LOCATION:BASE_URL +"/api/locations",
  DELETE_LOCATION:BASE_URL +"/api/locations/:id",
}