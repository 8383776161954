import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import "./App.css";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import OpenRoute from "./components/core/Auth/OpenRoute";
import Loading from "./components/common/Loading";
import SkuImport from "./components/core/Dashboard/Master/SKU Import/MainContent";
import MasterChanel from "./components/core/Dashboard/Master/Manage Chanels/MasterChanel";
import ChannelPage from "./components/core/Dashboard/Master/Manage Chanels/ChannelPage";
import MainPage from "./components/core/Dashboard/Master/Order Enquiery/MainPage";

// import OrderDetailPage from "./components/core/Dashboard/Master/Order Enquiery/SubOrderTabs/SubMainPage";
import MasterChanelListing from "./components/core/Dashboard/Master/SKUL Chanel Listing/MasterChanelListing";
import OrderDetailPage from "./components/core/Dashboard/Master/Order Enquiery/AllOrderEnquirey/SubOrderTabs/OrderDetailTab/SubMainPage";
import Buyers from "./components/core/Dashboard/Master/Procurement Buyers/Buyers/Buyers";
import CreateEditBuyer from "./components/core/Dashboard/Master/Procurement Buyers/Buyers/CreateEditBuyer";
import PurchaseOrderMain from "./components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Purchase Order Main/PurchaseOrderMain";
import MainPurchaseOrder from "./components/core/Dashboard/Master/Procurement Buyers/Purchase Order/Main Purchase Order/MainPurchaseOrder";
import ZoneDetails from "./components/core/Dashboard/Master/WMS Category/Setup WMS/Zone/ZoneDetails";
// Lazy load components
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const MyProfile = lazy(() => import("./components/core/Dashboard/MyProfile"));
const Home = lazy(() => import("./components/core/Dashboard/Home/index"));
const Error = lazy(() => import("./pages/Error"));
const VendorMaster = lazy(() => import("./components/core/Dashboard/Master/Vendor Master/index"));
const CustomerMaster = lazy(() => import("./components/core/Dashboard/Master/Customer Master/index"));
const TransporterMaster = lazy(() => import("./components/core/Dashboard/Master/Transporter Master/index"));
const ClientMaster = lazy(() => import("./components/core/Dashboard/Master/Client Master/index"));
const AddCreate = lazy(() => import("./components/core/Dashboard/Master/Add Create/AddCreate"));
const ImportPage = lazy(() => import("./components/common/ImportPage"));
const Category = lazy(() => import("./components/core/Dashboard/Master/Category/TaxCategory"));
const TaxCode = lazy(() => import("./components/core/Dashboard/Master/Tax Code/TaxCode"));
const TaxGroup = lazy(() => import("./components/core/Dashboard/Master/Tax Group/index"));
const TaxZone = lazy(() => import("./components/core/Dashboard/Master/Tax Zone/TaxZone"));
const TaxApplication = lazy(() => import("./components/core/Dashboard/Master/Tax Application/index"));
const SkuMaster = lazy(() => import("./components/core/Dashboard/Master/SKU Master/index"));
const SkuClassification = lazy(() => import("./components/core/Dashboard/Master/SKU Master/SKU Classification/SkuClassification"));
const SkuCreate = lazy(() => import("./components/core/Dashboard/Master/SKU Master/Add SKU/AddSku"));
const MerchandisingHierarchy = lazy(() => import("./components/core/Dashboard/Master/Merchandising Hierarchy/MerchandisingHierarchy"));
const LocationEnquiry = lazy(() => import("./components/core/Dashboard/Master/Location Enquiry/LocationEnquiry"));
const AddEdit = lazy(() => import("./components/core/Dashboard/Master/Location Enquiry/Add Edit/AddEdit"));
const OtherMasters = lazy(() => import("./components/core/Dashboard/Master/Other Masters/index"));
function App() {
  return (
    <div className="">
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<OpenRoute><Login /></OpenRoute>} />
          <Route element={<PrivateRoute><Dashboard /></PrivateRoute>}>
            <Route path="/Dashboard" element={<Home />} />
            <Route path="/master/trading-partner/vendor-master" element={<VendorMaster />} />
            <Route path="/master/trading-partner/vendor-master/import" element={<ImportPage />} />
            <Route path="/master/trading-partner/customer-master" element={<CustomerMaster />} />
            <Route path="/master/trading-partner/transporter-master" element={<TransporterMaster />} />
            <Route path="/master/trading-partner/client-master" element={<ClientMaster />} />
            <Route path="/myProfile" element={<MyProfile />} />
            <Route path="/master/trading-partner/vendor-create" element={<AddCreate />} />
            <Route path="/master/trading-partner/customer-create" element={<AddCreate />} />
            <Route path="/master/trading-partner/transporter-create" element={<AddCreate />} />
            <Route path="/master/trading-partner/client-create" element={<AddCreate />} />
            <Route path="/master/tax-management/category" element={<Category />} />
            <Route path="/master/tax-management/code" element={<TaxCode />} />
            <Route path="/master/tax-management/group" element={<TaxGroup />} />
            <Route path="/master/tax-management/zone" element={<TaxZone />} />
            <Route path="/master/tax-management/application" element={<TaxApplication />} />
            <Route path="/master/sku-management/master" element={<SkuMaster />} />
            <Route path="/master/sku-management/sku-classification" element={<SkuClassification />} />
            <Route path="/master/sku-management/sku-create" element={<SkuCreate />} />
            <Route path="/master/sku-management/import" element={<SkuImport/>} />
            <Route path="/sales/manage-channels" element={<MasterChanel/>}/>
            <Route path="/sales/manage-channels/channel" element={<ChannelPage/>}/>
            <Route path="/sales/order-enquiry" element={<MainPage/>}/>
            <Route path="/sales/order-enquiry/orderdetail" element={<OrderDetailPage/>}/>
            <Route path="/sales/sku-channel-listing" element={<MasterChanelListing/>}/> 
            <Route path="/master/procurement/buyers" element={<Buyers />} />  
            <Route path="/master/procurement/create-edit" element={<CreateEditBuyer />} />    
            <Route path="/master/procurement/podetail" element={<PurchaseOrderMain />} /> 
            <Route path="/master/procurement/purchase-order" element={<MainPurchaseOrder />} />
            <Route path="/master/wms/zone" element={<ZoneDetails />} />        
            <Route path="/master/sku-management/merchandising-hierarchy" element={<MerchandisingHierarchy/>} />
            <Route path="/master/organization-management/enquiry" element={<LocationEnquiry/>} />
            <Route path="/master/organization-management/create-edit" element={<AddEdit/>} />
            <Route path="/master/sku-management/other-masters" element={<OtherMasters/>} />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
