import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TaxModal from "../Modal/TaxModal";
import useExportToExcel from '../../hooks/useExportToExcel';

const DataTableModal = ({ data, headers, title, isAddEnabled, onUpdate, onAddNew, name, isDownloadEnabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(null);
  const itemsPerPage = 10;

  const handleSearchChange = (e) => {
    const { name, type, checked, value } = e.target;
    setSearch({
      ...search,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const resetSearchChange = () => {
    setSearch({});
  };

  const filteredData = data.filter(item =>
    Object.keys(search).every(key => {
      const searchValue = search[key];
      const itemValue = item[key];
      
      if (searchValue === undefined || searchValue === '') return true;

      const isNumber = typeof itemValue === 'number';
      const isString = typeof itemValue === 'string';

      if (isNumber) {
        const numberSearchValue = parseFloat(searchValue);
        return !isNaN(numberSearchValue) && itemValue === numberSearchValue;
      } else if (isString) {
        return itemValue.toLowerCase().includes(searchValue.toLowerCase());
      } else {
        return String(itemValue).toLowerCase().includes(searchValue.toLowerCase());
      }
    })
  );
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const toggleModal = (data = null) => {
    setCurrentData(data);
    setIsModalOpen(!isModalOpen);
  };

  const exportToExcel = useExportToExcel(headers, name, filteredData);

  const getDisplayName = (id, header) => {
    if (header.type === 'select' && header.options) {
      const option = header.options.find(opt => opt.value === id);
      return option ? option.label : id;
    }
    return id;
  };

  return (
    <div className="rounded-lg p-4 mt-2">
      <div className='flex items-center justify-between'>
        <div className='flex gap-2'>
          {isAddEnabled && <button className="bg-gray-700 text-white px-3 py-2 rounded" onClick={() => toggleModal(null)}>Add {title}</button>}
        </div>
        <div className='flex justify-end mb-4 space-x-2'>
          <button className="bg-gray-200 text-black px-3 py-2 rounded" onClick={resetSearchChange}>Reset</button>
          {isDownloadEnabled && <button className="bg-gray-400 text-white px-3 py-2 rounded" onClick={exportToExcel}>Download</button>}
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 rounded-lg">
          <thead className="bg-slate-600 text-white">
            <tr>
              {headers.map((header) => (
                <th key={header.key} className="px-3 py-2 text-left text-xs font-medium uppercase tracking-wider">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr className="bg-indigo-50">
              {headers.map((header) => (
                <td key={header.key} className="px-3 py-2 whitespace-nowrap text-sm text-gray-900">
                  {header.isInput ? (
                    header.type === 'date' ? (
                      <input
                        type="date"
                        name={header.key}
                        value={search[header.key] || ''}
                        onChange={handleSearchChange}
                        className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : header.type === 'checkbox' ? (
                      <input
                        type="checkbox"
                        name={header.key}
                        checked={search[header.key] || false}
                        onChange={handleSearchChange}
                        className="mt-1 block"
                      />
                    ) : (
                      <input
                        type={header.type}
                        name={header.key}
                        placeholder={`${header.label}`}
                        value={search[header.key] || ''}
                        onChange={handleSearchChange}
                        className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    )
                  ) : (
                    header.type === 'label' ? (
                      <span>{header.label}</span>
                    ) : (
                      <select
                        name={header.key}
                        value={search[header.key] || ''}
                        onChange={handleSearchChange}
                        className="mt-1 px-3 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="">All {header.label}</option>
                        {header.options.map(({ value, label }) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </select>
                    )
                  )}
                </td>
              ))}
            </tr>
            
            {/* Check if there is no data */}
            {currentItems.length === 0 ? (
              <tr>
                 <td colSpan={headers.length} className="px-3 py-6 text-center">
                   <div className="flex flex-col items-center justify-center space-y-4">
                     {/* Icon */}
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-16 h-16 text-gray-400">
                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v6m-3-3h6M21 12A9 9 0 113 12a9 9 0 0118 0z" />
                     </svg>
                     {/* Message */}
                     <p className="text-lg text-gray-600 font-semibold">No Data Found</p>
                     <p className="text-sm text-gray-500">Try adjusting your filters or add new data.</p>
                   </div>
                 </td>
               </tr>
            ) : (
              currentItems.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900">
                    {isAddEnabled ? (
                      <Link
                        to="#"
                        onClick={() => toggleModal(item)}
                        className="text-blue-600"
                      >
                        {getDisplayName(item[headers[0].key], headers[0])}
                      </Link>
                    ) : (
                        getDisplayName(item[headers[0].key], headers[0])
                    )}
                  </td>
                  {headers.slice(1).map((header) => (
                    <td key={header.key} className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">
                      {header.key === 'status' ? (
                        <span className={`px-3 inline-flex text-xs leading-5 font-semibold rounded-full ${getDisplayName(item[header.key], header) === 'Active' || getDisplayName(item[header.key], header) === 'Subscribed' ? 'bg-green-700 text-white' : 'bg-red-700 text-white'}`}>
                          {getDisplayName(item[header.key], header)}
                        </span>
                      ) : (
                        getDisplayName(item[header.key], header)
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center py-2">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          className="px-3 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-sm text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          className="px-3 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {isModalOpen && <TaxModal headers={headers} toggleModal={toggleModal} currentData={currentData} onUpdate={onUpdate} onAddNew={onAddNew} />}
    </div>
  );
};

export default DataTableModal;
