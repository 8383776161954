import React, { useState } from 'react';

const Detail2 = () => {
  const [formData, setFormData] = useState({
    lottableValidation: '',
    skuRotation: '',
    rotateBy: '',
    lot: '',
    recvValidationCode: '',
    pickingInstructions: '',
    shippingInstructions: '',
    thresholdAlertRequired: false,
    thresholdQty: '',
    fulfillmentType: '',
    shippingCharges: '',
    handlingCharges: '',
    defSourceWH: '',
    isUniqueBarcode: false,
    isIpcAllowed: false,
    isRepackAllowed: false,
    minimumTransferQty: '',
    transferUnitFactor: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // Handle form submission here
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 font-medium text-xs lg:grid-cols-3 gap-4">
        {/* Lottable Validation */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Lottable Validation</label>
          <select
            name="lottableValidation"
            value={formData.lottableValidation}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            <option value="0101">0101 - Default</option>
            {/* Add other options as needed */}
          </select>
        </div>

        {/* SKU Rotation */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">SKU Rotation</label>
          <select
            name="skuRotation"
            value={formData.skuRotation}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            <option value="FIFO">FIFO</option>
            {/* Add other options as needed */}
          </select>
        </div>

        {/* Rotate By */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Rotate By</label>
          <input
            type="text"
            name="rotateBy"
            value={formData.rotateBy}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Lot */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Lot</label>
          <input
            type="text"
            name="lot"
            value={formData.lot}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Recv. Validation Code */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Recv. Validation Code</label>
          <select
            name="recvValidationCode"
            value={formData.recvValidationCode}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            <option value="103">103 - Mfg date</option>
            {/* Add other options as needed */}
          </select>
        </div>

        {/* Picking Instructions */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Picking Instructions</label>
          <select
            name="pickingInstructions"
            value={formData.pickingInstructions}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options as needed */}
          </select>
        </div>

        {/* Shipping Instructions */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Shipping Instructions</label>
          <select
            name="shippingInstructions"
            value={formData.shippingInstructions}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options as needed */}
          </select>
        </div>

        {/* Threshold Alert Required */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="thresholdAlertRequired"
              checked={formData.thresholdAlertRequired}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Threshold Alert Required</span>
          </label>
        </div>

        {/* Threshold Qty */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Threshold Qty</label>
          <input
            type="text"
            name="thresholdQty"
            value={formData.thresholdQty}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Fulfillment Type */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Fulfillment Type</label>
          <select
            name="fulfillmentType"
            value={formData.fulfillmentType}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options as needed */}
          </select>
        </div>

        {/* Shipping Charges */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Shipping Charges</label>
          <input
            type="text"
            name="shippingCharges"
            value={formData.shippingCharges}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Handling Charges */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Handling Charges</label>
          <input
            type="text"
            name="handlingCharges"
            value={formData.handlingCharges}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Def Source WH */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Def Source WH</label>
          <select
            name="defSourceWH"
            value={formData.defSourceWH}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">--- Select ---</option>
            {/* Add options as needed */}
          </select>
        </div>

        {/* Is Unique Barcode */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isUniqueBarcode"
              checked={formData.isUniqueBarcode}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Is Unique Barcode</span>
          </label>
        </div>

        {/* Is IPC Allowed */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isIpcAllowed"
              checked={formData.isIpcAllowed}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Is IPC Allowed</span>
          </label>
        </div>

        {/* Is Repack Allowed */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isRepackAllowed"
              checked={formData.isRepackAllowed}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Is Repack Allowed</span>
          </label>
        </div>

        {/* Minimum Transfer Qty */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Minimum Transfer Qty</label>
          <input
            type="text"
            name="minimumTransferQty"
            value={formData.minimumTransferQty}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {/* Transfer Unit Factor */}
        <div className="mb-4">
          <label className="block text-gray-700  mb-2">Transfer Unit Factor</label>
          <input
            type="text"
            name="transferUnitFactor"
            value={formData.transferUnitFactor}
            onChange={handleChange}
            className="w-full px-1 py-1 border border-gray-300 rounded-md shadow-sm"
          />
        </div>
      </div>

      {/* <div className="flex justify-center mt-6">
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
        >
          Submit
        </button>
      </div> */}
    </form>
  );
};

export default Detail2;
