import React from 'react'
import { IoCloseOutline } from "react-icons/io5";
import { CiExport } from "react-icons/ci";
import { AiOutlineExport } from "react-icons/ai";
import { CiImport } from "react-icons/ci";
import { IoIosAddCircleOutline } from "react-icons/io";
import { SlQuestion } from "react-icons/sl";

export const ProcurementBuyersButtons=({resetHandler, addNew})=>{
    return (
      <>
      <div className='flex flex-wrap justify-end'>
          <button type="button" className="relative flex rounded-md border-slate-300 border bg-gray-300 px-3 py-2 text-sm font-medium m-2 hover:bg-slate-400" onClick={resetHandler}>
          <IoCloseOutline className='my-auto text-lg font-large'/>
          &nbsp; Reset
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border bg-gray-300 px-3 py-2 text-sm font-medium m-2 hover:bg-slate-400">
          <CiExport className='my-auto text-lg font-large'/>
          &nbsp; Export
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <AiOutlineExport className='my-auto text-lg font-large'/>
          &nbsp; Detail Export
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <CiImport  className='my-auto text-lg font-large'/>
          &nbsp; Import
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200" onClick={addNew}>
          <IoIosAddCircleOutline className='my-auto text-lg font-large' />
          &nbsp; Add New
          </button>
          <button type="button" className="relative flex rounded-md  px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <SlQuestion className='my-auto text-lg font-large text-cyan-500'/>
          </button>
      </div>
      </>
    )
  };


  export const POMainButton=({resetHandler})=>{
    return (
      <>
      <div className='flex flex-wrap justify-end'>
          <button type="button" className="relative flex rounded-md border-slate-300 border bg-gray-300 px-3 py-2 text-sm font-medium m-2 hover:bg-slate-400" onClick={resetHandler}>
          <IoCloseOutline className='my-auto text-lg font-large'/>
          &nbsp; Reset
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border bg-gray-300 px-3 py-2 text-sm font-medium m-2 hover:bg-slate-400">
          <CiExport className='my-auto text-lg font-large'/>
          &nbsp; Export
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <AiOutlineExport className='my-auto text-lg font-large'/>
          &nbsp; Detail Export
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <CiImport  className='my-auto text-lg font-large'/>
          &nbsp; Import
          </button>
          <button type="button" className="relative flex rounded-md border-slate-300 border px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <IoIosAddCircleOutline className='my-auto text-lg font-large' />
          &nbsp; Add New
          </button>
          <button type="button" className="relative flex rounded-md  px-3 py-2 text-sm font-medium m-2 hover:bg-slate-200">
          <SlQuestion className='my-auto text-lg font-large text-cyan-500'/>
          </button>
      </div>
      </>
    )
  };