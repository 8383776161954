import React, { useEffect, useState } from 'react'
import { ZoneDetailsHeader } from '../../../../../../../data/Headers'
import DataTableTwo from '../../../../../../common/DataTableTwo'
import ZoneModal from './ZoneModal';
import { useSelector } from 'react-redux';



const ZoneDetails = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [modalData,setModalData]=useState([])
    const zonedata = useSelector((state) => state.wms.data);
    const data=zonedata.zone;

    const openModal = (index) => {
        setIsModalOpen(true);
        setModalData({...data[parseInt(index)], inputIndex: index });
      };

      useEffect(() => {
        // Sync with updated parent state
     }, [modalData]);

      const closeModal = () => {
        console.log("closed")
        setIsModalOpen(false);
      };
    
      // Close modal when clicking outside of the modal content
      const handleOutsideClick = (e) => {
        if (e.target.className === 'modal') {
          closeModal();
        }
      };

  return (
    <>
    <div className="container mx-auto p-4 bg-white">
        <div className='border w-full rounded'>
            <div className='flex mx-4 p-4'>
                <div className='px-3 my-auto'>PO Tags:</div>
                <select
                    className="mt-1 p-2 block border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Baby & Mom Retail Pvt Ltd. -Delhi</option>
                    <option value="">Baby & Mom Retail Pvt Ltd. -Gurugram</option>
                    <option value="">Baby & Mom Retail Pvt Ltd. -Banglore</option>
                  </select>
            </div>
        </div>
        <DataTableTwo id='8' headers={ZoneDetailsHeader} data={data} name="Zone Details" title="Zone Details" edithandler={openModal}/>
    </div>
    {isModalOpen && (
  <div className="modal" onClick={handleOutsideClick}>
    {/* Modal content */}
    <div className="modal-content">
      <ZoneModal closeModal={closeModal} modalData={modalData}/>
    </div>
  </div>
)}
    </>
  )
}

export default ZoneDetails