import React from 'react'
import Tabs from '../../../../common/Tabs'
import { ChannelListingtabsConfig, OrdertabsConfig } from '../../../../../data/tabsData'

const MasterChanelListing = () => {
  return (
    <>
    <div className='bg-white shadow-lg'>
       <Tabs data={ChannelListingtabsConfig} />     
    </div>
    </>
  )
}

export default MasterChanelListing