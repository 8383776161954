import React, { useState } from 'react';

const AddressForm = () => {
  const [formData, setFormData] = useState({
    billingAddress1: '',
    billingAddress2: '',
    billingAddress3: '',
    billingAddress4: '',
    billingPhone: '',
    billingAltPhone: '',
    billingFax: '',
    billingEmail: '',
    billingAltEmail: '',
    billingCountry: 'India',
    billingState: '',
    billingCity: '',
    billingPincode: '',
    sameAsBilling: false,
    shippingAddress1: '',
    shippingAddress2: '',
    shippingAddress3: '',
    shippingAddress4: '',
    shippingLatitude: '',
    shippingLongitude: '',
    shippingPhone: '',
    shippingAltPhone: '',
    shippingFax: '',
    shippingEmail: '',
    shippingAltEmail: '',
    shippingCountry: 'India',
    shippingState: '',
    shippingCity: '',
    shippingPincode: '',
    geoType: '',
    geoAddress: '',
    geoLatitude: '',
    geoLongitude: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const states = [
    { value: '', label: '--- Select ---' },
    { value: 'state1', label: 'State 1' },
    { value: 'state2', label: 'State 2' },
    // Add more options as needed
  ];

  return (
    <div className="container mx-auto mt-6">
      <form onSubmit={handleSubmit}>
        {/* Billing Address Details */}
        <div className="mb-6 p-6 border border-slate-800 relative rounded-lg">
          <h3 className="text-lg absolute -top-4 left-4 bg-white mb-4">Billing Address Details</h3>
          <div className="grid text-xs grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 1*</label>
              <input
                type="text"
                name="billingAddress1"
                value={formData.billingAddress1}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 2</label>
              <input
                type="text"
                name="billingAddress2"
                value={formData.billingAddress2}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 3</label>
              <input
                type="text"
                name="billingAddress3"
                value={formData.billingAddress3}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 4</label>
              <input
                type="text"
                name="billingAddress4"
                value={formData.billingAddress4}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Phone*</label>
              <input
                type="text"
                name="billingPhone"
                value={formData.billingPhone}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Alternate Phone</label>
              <input
                type="text"
                name="billingAltPhone"
                value={formData.billingAltPhone}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Fax</label>
              <input
                type="text"
                name="billingFax"
                value={formData.billingFax}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Email*</label>
              <input
                type="email"
                name="billingEmail"
                value={formData.billingEmail}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Alternate Email</label>
              <input
                type="email"
                name="billingAltEmail"
                value={formData.billingAltEmail}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Country*</label>
              <select
                name="billingCountry"
                value={formData.billingCountry}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="India">India</option>
                {/* Add more country options as needed */}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">State*</label>
              <select
                name="billingState"
                value={formData.billingState}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {states.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">City</label>
              <input
                type="text"
                name="billingCity"
                value={formData.billingCity}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Pincode</label>
              <input
                type="text"
                name="billingPincode"
                value={formData.billingPincode}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Shipping Address Details */}
        <div className="mb-6 border border-slate-800 p-6 rounded-lg relative">
          <h3 className="text-lg absolute -top-4 left-4 bg-white font-semibold mb-4">Shipping Address Details</h3>
          <div className="grid text-xs grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 1*</label>
              <input
                type="text"
                name="shippingAddress1"
                value={formData.shippingAddress1}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 2</label>
              <input
                type="text"
                name="shippingAddress2"
                value={formData.shippingAddress2}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 3</label>
              <input
                type="text"
                name="shippingAddress3"
                value={formData.shippingAddress3}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Address 4</label>
              <input
                type="text"
                name="shippingAddress4"
                value={formData.shippingAddress4}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Latitude</label>
              <input
                type="text"
                name="shippingLatitude"
                value={formData.shippingLatitude}
                onChange={handleChange}
                className="w-full ppx-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Longitude</label>
              <input
                type="text"
                name="shippingLongitude"
                value={formData.shippingLongitude}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Phone*</label>
              <input
                type="text"
                name="shippingPhone"
                value={formData.shippingPhone}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Alternate Phone</label>
              <input
                type="text"
                name="shippingAltPhone"
                value={formData.shippingAltPhone}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Fax</label>
              <input
                type="text"
                name="shippingFax"
                value={formData.shippingFax}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Email*</label>
              <input
                type="email"
                name="shippingEmail"
                value={formData.shippingEmail}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Alternate Email</label>
              <input
                type="email"
                name="shippingAltEmail"
                value={formData.shippingAltEmail}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Country*</label>
              <select
                name="shippingCountry"
                value={formData.shippingCountry}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="India">India</option>
                {/* Add more country options as needed */}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">State*</label>
              <select
                name="shippingState"
                value={formData.shippingState}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {states.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">City</label>
              <input
                type="text"
                name="shippingCity"
                value={formData.shippingCity}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Pincode</label>
              <input
                type="text"
                name="shippingPincode"
                value={formData.shippingPincode}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Geo Type</label>
              <select
                name="geoType"
                value={formData.geoType}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {states.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Geo Address</label>
              <input
                type="text"
                name="geoAddress"
                value={formData.geoAddress}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Geo Latitude</label>
              <input
                type="text"
                name="geoLatitude"
                value={formData.geoLatitude}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Geo Longitude</label>
              <input
                type="text"
                name="geoLongitude"
                value={formData.geoLongitude}
                onChange={handleChange}
                className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        {/* <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-600 text-white font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit
        </button> */}
      </form>
    </div>
  );
};

export default AddressForm;
