import React, { useState } from 'react';
import { sizeGroupHeaders } from '../../../../data/Headers';
import DataTableModal from '../../../common/DataTableModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { sizeGrouppoints } from '../../../../services/apis';
import { apiConnector } from '../../../../services/apiconnector';
import { useSelector } from 'react-redux';
import { createSizeGroup,updateSizeGroup } from '../../../../services/operations/sizeGroupApi';
import Loading from '../../../common/Loading';

// Fetch brands function
const fetchSizeGroup = async (token) => {
  const response = await apiConnector('GET', sizeGrouppoints.SHOW_ALL_SIZE_GROUPS, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data=response?.data?.data;
  return data.map(item => ({
    id: item.id,
    sizeGroupDescription: item.name
  }));
};

const SizeGroup = () => {
  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  const { data: sizeGroupData, error, isLoading } = useQuery({
    queryKey: ['sizeGroup', token],
    queryFn: () => fetchSizeGroup(token),
    enabled: !!token,
  });

  if (isLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading/></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddNew = async (newData) => {
    await createSizeGroup(newData, token);
    queryClient.invalidateQueries(['sizeGroup']); 
  };

  const handleUpdate = async (id, updatedData) => {
    await updateSizeGroup(id, updatedData, token);
    queryClient.invalidateQueries(['sizeGroup']);
  };

  return (
    <div className="rounded-lg p-4 mt-2">
      <DataTableModal id='4' isAddEnabled={true} headers={sizeGroupHeaders} data={sizeGroupData}  title="New" name="sizeGroup"
        onAddNew={handleAddNew} onUpdate={handleUpdate} />
    </div>
  );
};

export default SizeGroup;
