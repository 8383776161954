import React, { useState } from 'react';

const AttachedDocument = () => {
  const [documents, setDocuments] = useState([]);

  const handleFileChange = (e, index) => {
    const newDocuments = [...documents];
    newDocuments[index] = e.target.files[0];
    setDocuments(newDocuments);
  };

  const handleAddDocument = () => {
    setDocuments([...documents, null]);
  };

  const handleRemoveDocument = (index) => {
    const newDocuments = documents.filter((_, i) => i !== index);
    setDocuments(newDocuments);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    documents.forEach((doc, index) => {
      if (doc) {
        formData.append(`document${index}`, doc);
      }
    });

    fetch('https://api.example.com/submit-documents', {
      method: 'POST',
      body: formData,
    })
      .then(response => {
        if (response.ok) {
          console.log('Documents submitted successfully');
          setDocuments([]);
        } else {
          console.error('Failed to submit documents');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="container mx-auto mt-6">
      <div className="border border-slate-900  p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-6 text-gray-900">Attach Documents</h2>
        <form onSubmit={handleSubmit}>
          {documents.map((doc, index) => (
            <div key={index} className="flex items-center mb-4 border-b border-gray-200 pb-4">
              <input
                type="file"
                onChange={(e) => handleFileChange(e, index)}
                className="mr-4 px-2 py-1 bg-white border border-gray-300 rounded-md shadow-sm"
              />
              <button
                type="button"
                onClick={() => handleRemoveDocument(index)}
                className="bg-red-600 text-white py-1 px-2 rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddDocument}
            className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
          >
            Add More
          </button>
          <button
            type="submit"
            className="mt-4 bg-green-600 ml-2 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Attached Documents</h3>
          <ul className="list-disc list-inside">
            {documents.map((doc, index) => (
              doc ? (
                <li key={index} className="mb-2 text-gray-700">
                  {doc.name}
                </li>
              ) : null
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AttachedDocument;
