import { combineReducers } from "@reduxjs/toolkit";
import profileReducer from "../slice/profileSlice";
import authReducer from "../slice/authSlice";
import sukReducer from "../slice/skuSlice";
import purchaseOrderDetailSliceReducer from "../slice/purchaseOrderDetailSlice";
import wmsSliceReducer from "../slice/wmsSlice";
import orderEnquirySliceReducer from "../slice/orderEnquirySlice";
import orderEnquiriesSliceReducer from "../slice/orderEnquiresSlice";
import mainChannelsSliceReducer from "../slice/mainChannelsSlice";
import skuReducer from "../slice/skuSlice";
import merchReducer from "../slice/merchSlice";

const rootReducer = combineReducers({
  profile: profileReducer,
  auth: authReducer,
  sku: sukReducer,
  poDetails:purchaseOrderDetailSliceReducer,
  wms: wmsSliceReducer,
  orderEnquiry:orderEnquirySliceReducer,
  orderEnquiries:orderEnquiriesSliceReducer,
  mainChannels:mainChannelsSliceReducer,
  sku: skuReducer,
  merch:merchReducer,
});

export default rootReducer;
