import React from 'react'

const PurchaseOrderTags = ({maindata}) => {
  console.log(maindata.poTags)
    const header=["PO Tag","PO Status When Tagged","Tag Date","Tagged By"]

    return (
      <>
      <div>
        <div className='border w-full rounded'>
            <div className='flex mx-4 p-4'>
                <div className='px-3'>PO Tags</div>
                <div class="h-14 overflow-y-auto border custom-scrollbar" style={{minWidth:"10rem"}}>
                    <p>PO Tag1</p>
                    <p>PO Tag2</p>
                    <p>PO Tag3</p>
                    <p>PO Tag4</p>
                    <p>PO Tag5</p>
                    <p>PO Tag1</p>
                    <p>PO Tag2</p>
                    <p>PO Tag3</p>
                    <p>PO Tag4</p>
                    <p>PO Tag5</p>
                </div>
            </div>
        </div>
        <div>
        <div className=' w-full'>
      <div className="oferflow w-full mt-2">
      <table className="w-full">
          <thead className="bg-slate-600 text-white">        
              <tr>
              {header.map((item,index)=>(
                <th className="px-6 py-4 whitespace-nowrap text-sm" key={index}>{item}</th>
              ))}
              </tr> 
          </thead>
          <tbody className="divide-y divide-gray-200">      
                {/* {maindata.poTags.map((item,index)=>(  
                  <tr className="hover:bg-gray-100" key={index}>              
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.poTag}</td>   
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.poStatus}</td>  
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.tagData}</td>  
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900" >{item.taggedby}</td>  
                    </tr>             
                ))}  */}
                {maindata.poTags.map((item,index)=>(   
                    <tr className="hover:bg-gray-100" key={index}>             
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.poTag}</td>    
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.poStatus}</td> 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.tagData}</td> 
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.taggedby}</td> 
                    </tr>            
                ))}               
                </tbody>
            </table>
            </div>
        </div>
        </div>
      </div>
      
      </>
    )
  }

export default PurchaseOrderTags