import { createSlice } from '@reduxjs/toolkit';

const skuSlice = createSlice({
  name: 'sku',
  initialState: {
    skuType: 'normal',
    isMarketplaceAvailable: false,
  },
  reducers: {
    setSkuType(state, action) {
      state.skuType = action.payload;
    },
    setIsMarketplaceAvailable(state, action) {
      state.isMarketplaceAvailable = action.payload;
    },
  },
});

export const { setSkuType, setIsMarketplaceAvailable } = skuSlice.actions;
export default skuSlice.reducer;
