import React, {useEffect, useState } from 'react'
// import { OrderAllButton } from '../../OrderButton';
import DataTableTwo from '../../../../../common/DataTableTwo';
import { BuyersHeader } from '../../../../../../data/Headers';
import { ProcurementBuyersButtons } from '../ProcurementButtons';
import CreateEditBuyer from './CreateEditBuyer';
// import DataTableTwo from '../../../../../../common/DataTableTwo';
// import { orderEnquiryHeaders } from '../../../../../../../data/Headers';




const Buyers = () => {
  const [key, setKey] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    console.log("open edit window")
    setIsModalOpen(!isModalOpen);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.className === 'modal') {
      closeModal();
    }
  };

  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };

  const addNew=()=>{

  }

      const buyerData=[
        {
            buyerCode:11,
            buyerName:"gfg",
            buyerDesc:"lkjh",
            phone:9999999999,
            email:"jkh@gmail.com",
            status:"no",
            location:"jh",
            createdBy:"dfg",
            actions:"sdf",
    },
         {
            buyerCode:12,
            buyerName:"gfg",
            buyerDesc:"lkjh",
            phone:8888888888,
            email:"kjh@gmail.com",
            status:"yes",
            location:"jh",
            createdBy:"dfg",
            actions:"sdf",
    },
    ];
    
  return (
    <>
    <div className="container mx-auto p-4 bg-white">
    <ProcurementBuyersButtons resetHandler={resetTable} addNew={openModal}></ProcurementBuyersButtons>
      <div key={key}>
      <DataTableTwo id='5' headers={BuyersHeader} data={buyerData} name="Order Enquirey List" title="Order Enquirey" link={`/master/procurement/create-edit`} />
      </div>

      {isModalOpen && (
  <div className="modal" onClick={handleOutsideClick}>
    {/* Modal content */}
    <div className="modal-content">
      <span className="close" onClick={closeModal}>&times;</span>
      <CreateEditBuyer closeModal={closeModal}/>
    </div>
  </div>
)}
</div>
    </>
  )
}

export default Buyers