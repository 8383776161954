import React, { useState } from 'react'
import { CancelPickedButton } from '../OrderButton'
import DataTableTwo from '../../../../../common/DataTableTwo';
import { CancelledPickedOrderHeader } from '../../../../../../data/Headers';


const CancelledPickOrderTab = ({maindata}) => {
    const [key, setKey] = useState(0);

    const cancelOrderData=maindata.cancelOrderData

  const resetTable = () => {
    setKey(prevKey => prevKey + 1);
  };
  return (
    <>
        <div className="flex items-center space-x-4 ">
      <div className="flex items-center space-x-2">
        <label className="font-semibold text-gray-700">Order Date <span className="text-red-500">*</span></label>
        <input
          type="text"
          placeholder="put date range"
          className="border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex items-center space-x-2">
        <label className="font-semibold text-gray-700">
          PutAway Status
        </label>
        <select className="border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option >--Select--</option>
            <option >--Select--</option>
            <option >--Select--</option>
        </select>
      </div>
      </div>
      <CancelPickedButton resetHandler={resetTable}/>
      <div key={key}>
            <DataTableTwo id='3' headers={CancelledPickedOrderHeader} data={cancelOrderData} name="Canelled Picked Order List" title="Cancelled Picked Order"  />
      </div>
    </>
  )
}

export default CancelledPickOrderTab