import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import DetailSummary from '../../../../../../../common/DetailSummary';


const OrderDetailTab = () => {
  const orderEnquiry = useSelector((state) => state.orderEnquiry.data); 
  const data= orderEnquiry;
  const orderDetail=[
    {head:"order No", result:data.orderNo}, 
    {head:"order Date", result:data.orderDetail.orderDate},
    {head:"order Amount", result:data.orderDetail.orderAmount},
    {head:"Tax Amount", result:data.orderDetail.taxAmount},
    {head:"Other Tax Amount", result:data.orderDetail.otherTaxAmount},
    {head:"Discount Amount", result:data.orderDetail.discountAmount},
    {head:"Verification Status", result:data.orderDetail.verificationStatus},
    {head:"Actual Ship Date", result:data.orderDetail.actualShipDate},
    {head:"Base Currency", result:data.orderDetail.baseCurrency},
    {head:"Conv Rate", result:data.orderDetail.convRate},
    {head:"Discount Code", result:data.orderDetail.discountCode},
    {head:"Amount", result:data.orderDetail.amount},
    {head:"Delivery Date", result:data.orderDetail.deliveryDate},
  ];
  const othDetails=[
    {head:"Other Details", result:"other Detail"}, 
  ];

  const detailTab=[{"TabName":"Order Details","state":true,"data":orderDetail},
    {"TabName":"Other Details","state":false,"data":othDetails}
  ];

  return (
    <>
    <DetailSummary detailTab={detailTab}/>
    </>
  )
}

export default OrderDetailTab