import React from 'react';
import { colorsHeaders } from '../../../../data/Headers';
import DataTableModal from '../../../common/DataTableModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { colorPoints } from '../../../../services/apis';
import { createColors,updateColors } from '../../../../services/operations/colorApi';
import { useSelector } from 'react-redux';
import Loading from '../../../common/Loading';
import { apiConnector } from '../../../../services/apiconnector';

const fetchColor = async (token) => {
  const response = await apiConnector('GET', colorPoints.SHOW_ALL_COLORS, null, {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
  });
  const data = response.data.data;
  return data.map(item => ({
    id: item.id,
    colorName: item.name,

  }));
};

const Color = () => {

  const { token } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  const { data: colorData, error, isLoading } = useQuery({
    queryKey: ['colors', token],
    queryFn: () => fetchColor(token),
    enabled: !!token,
  });

  if (isLoading) {
    return <div className='flex items-center justify-center h-screen'><Loading/></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleAddNew = async (newData) => {
    await createColors(newData, token);
    queryClient.invalidateQueries(['colors']); 
  };

  const handleUpdate = async (id, updatedData) => {
    await updateColors(id, updatedData, token);
    queryClient.invalidateQueries(['colors']);
  };
      
  return (
    <div className="rounded-lg p-4 mt-2">
      <DataTableModal id='4' isAddEnabled={true} headers={colorsHeaders} data={colorData} onAddNew={handleAddNew} onUpdate={handleUpdate}   title="New" />
    </div>
  );
};

export default Color;
